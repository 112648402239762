import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { SocketIoService } from '../socketIo.service';
import { IpServiceService } from '../ip-service.service';
import { humidityData } from '../data/humidityData';
import { temperatureData } from '../data/temperatureData';
import { GlobalConstants } from '../common/GlobalConstants ';


@Component({
    selector: 'app-house',
    templateUrl: './house.component.html',
    styleUrls: ['./house.component.css']
})

export class HouseComponent implements OnInit {
    private requestUrlJarfallaTemperature: string = GlobalConstants.requestUrl + '3333/jarfalla/temperature';
    private requestUrlJarfallaHumidity: string = GlobalConstants.requestUrl + '3333/jarfalla/humidity';


    public ipAddressValid: boolean = false;

    public hallTemp: string;
    public kitchenTemp: string;
    public livingroomTemp: string;
    public bedroomTemp: string;
    public bedroom1Temp: string;
    public officeTemp: string;
    public labTemp: string;
    public livingroomHumidity: string;
    public bedroomHumidity: string;
    public officeHumidity: string;
    public labHumidity: string;
    public energyHouse: string;
    public energyHouseHour: string;
    public energyHouseCostPriviousHour: string;


    public kitchenDoorOpenClosedSensor: string;
    public kitchenDoorOpenClosedSensorImage: string = "assets/images/door/dooropen.png";
    public kitchenDoorBatteryLevelSensor: string;
    public kitchenDoorBatteryLevelSensorImage: string = "";
    public kitchenDoorOpenClosedSensorUpdated: boolean = false;
    public kitchenDoorBatteryLevelSensorUpdated: boolean = false;

    public kitchenWindowOpenClosedSensor: string;
    public kitchenWindowOpenClosedSensorImage: string = "assets/images/window/windowopen.png";
    public kitchenWindowBatteryLevelSensor: string;
    public kitchenWindowBatteryLevelSensorImage: string = "";
    public kitchenWindowOpenClosedSensorUpdated: boolean = false;
    public kitchenWindowBatteryLevelSensorUpdated: boolean = false;


    public livingroomDoorOpenClosedSensor: string;
    public livingroomDoorOpenClosedSensorImage: string = "assets/images/door/dooropen.png";
    public livingroomDoorBatteryLevelSensor: string;
    public livingroomDoorBatteryLevelSensorImage: string = "";
    public livingroomDoorOpenClosedSensorUpdated: boolean = false;
    public livingroomDoorBatteryLevelSensorUpdated: boolean = false;

    public bedroomWindowOpenClosedSensor: string;
    public bedroomWindowOpenClosedSensorImage: string = "assets/images/window/windowopen.png";
    public bedroomWindowBatteryLevelSensor: string;
    public bedroomWindowBatteryLevelSensorImage: string = "";
    public bedroomWindowOpenClosedSensorUpdated: boolean = false;
    public bedroomWindowBatteryLevelSensorUpdated: boolean = false;

    public bedroom1WindowOpenClosedSensor: string;
    public bedroom1WindowOpenClosedSensorImage: string = "assets/images/window/windowopen.png";
    public bedroom1WindowBatteryLevelSensor: string;
    public bedroom1WindowBatteryLevelSensorImage: string = "";
    public bedroom1WindowOpenClosedSensorUpdated: boolean = false;
    public bedroom1WindowBatteryLevelSensorUpdated: boolean = false;

    public bedroomBlinds: string;
    public bedroomBlindsImage: string = "assets/images/blinds/blindsopen.png";
    public bedroom1Blinds: string;
    public bedroom1BlindsImage: string = "assets/images/blinds/blindsopen.png";

    public hallTempUpdated: boolean = false;
    public kitchenTempUpdated: boolean = false;
    public livingroomTempUpdated: boolean = false;
    public bedroomTempUpdated: boolean = false;
    public bedroom1TempUpdated: boolean = false;
    public officeTempUpdated: boolean = false;
    public labTempUpdated: boolean = false;
    public livingroomHumidityUpdated: boolean = false;
    public bedroomHumidityUpdated: boolean = false;
    public officeHumidityUpdated: boolean = false;
    public labHumidityUpdated: boolean = false;
    public energyHouseUpdated: boolean = false;
    public energyHouseHourUpdated: boolean = false;
    public energyHouseCostPriviousHourUpdated: boolean = false;

    public bedroomBlindsUpdated: boolean = false;
    public bedroom1BlindsUpdated: boolean = false;

    private readonly flashBackgroundTime: number = 300;
    private readonly lowPower: number = 20;    

    constructor(private router: Router, private http: HttpClient, private socketIoService: SocketIoService, private ip: IpServiceService) {

    }

    ngOnInit(): void {
        this.getIP();

        this.getHallTemp();

        this.getKitchenTempUpdated();
        this.getKitchenDoorOpenClosedSensor();
        this.getKitchenDoorBatteryLevelSensor();
        this.getKitchenWindowOpenClosedSensor();
        this.getKitchenWindowBatteryLevelSensor();

        this.getLivingroomTemp();
        this.getLivingroomDoorOpenClosedSensor();
        this.getLivingroomDoorBatteryLevelSensor();

        this.getBedroomTemp();
        this.getBedroomWindowOpenClosedSensor();
        this.getBedroomWindowBatteryLevelSensor();
        this.getBedroomBlinds();
        
        this.getBedroom1Temp();
        this.getBedroom1WindowOpenClosedSensor();
        this.getBedroom1WindowBatteryLevelSensor();
        this.getBedroom1Blinds();

        this.getOfficeTemp();
        this.getLabTemp();
        this.getLivingroomHumidity();
        this.getBedroomHumidity();
        this.getOfficeHumidity();
        this.getLabHumidity();
        this.getEnergyHouse();
        this.getEnergyHouseHour();
        this.getEnergyCostPriviousHouseHour();

        this.socketIoService.requestStartData('HOUSE');
    }

    getIP() {
        this.ip.getIPAddress().subscribe((res: any) => {
            this.setIPValidStatus(res.ip);
            // console.log("ipAddress: " + res.ip);
        });
    }

    setIPValidStatus(ipAddress) {
        this.ipAddressValid = false;

        if (ipAddress === GlobalConstants.validIpAdress) {
            this.ipAddressValid = true;
        }
    }

    btnJarfallaClick() {
        this.router.navigate(['/jarfalla']);
    };

    btnHistoryClick() {
        this.router.navigate(['/history']);
    };

    btnRightClick() {
        this.router.navigate(['/history']);
    };

    btnHomeClick() {
        this.router.navigate(['/home']);
    }


    btnGraphTemperatureClick(zone: string, timespan: string, formTitle: string) {
        this.http
          .get<Array<temperatureData>>(this.requestUrlJarfallaTemperature, {
            params: { timespan: timespan, zone: zone }, observe: 'response'
          })
          .subscribe(resp => {
    
            let temperatureData = [];
    
            var i: number;
            for (i = 0; i < resp.body['temperatureDay'].length; i++) {
    
              let date = JSON.stringify(resp.body['temperatureDay'][i][0]).substr(1, 10) + " . " + JSON.stringify(resp.body['temperatureDay'][i][0]).substr(12, 5);
              let temp = resp.body['temperatureDay'][i][1];
    
              temperatureData.push([date, temp]);
            }
    
            this.router.navigate(['/graphtemperature'], { state: { graphData: temperatureData, formTitle: formTitle } });
          });
      }
    
    
    
      btnGraphHumidityClick(zone: string, timespan: string, formTitle: string) {  
        this.http
          .get<Array<humidityData>>(this.requestUrlJarfallaHumidity, {
            params: { timespan: timespan, zone: zone }, observe: 'response'
          })
          .subscribe(resp => {
    
            let humidityData = [];
    
            var i: number;
            for (i = 0; i < resp.body['humidityDay'].length; i++) {
    
              let date = JSON.stringify(resp.body['humidityDay'][i][0]).substr(1, 10) + " . " + JSON.stringify(resp.body['humidityDay'][i][0]).substr(12, 5);
              let temp = resp.body['humidityDay'][i][1];
    
              humidityData.push([date, temp]);
            }
    
            this.router.navigate(['/graphhumidity'], { state: { graphData: humidityData, formTitle: formTitle } });
          });
      }



    getHallTemp() {
        this.socketIoService.getJarfallaHallTemp().subscribe((message: string) => {
            this.hallTemp = message;

            // Update square background color when value change.
            this.hallTempUpdated = true;
            setTimeout(() => {
                this.hallTempUpdated = false;
            }, this.flashBackgroundTime);
        });
    }


    getKitchenTempUpdated() {
        this.socketIoService.getJarfallaKitchenTemp().subscribe((message: string) => {
            this.kitchenTemp = message;

            // Update square background color when value change.
            this.kitchenTempUpdated = true;
            setTimeout(() => {
                this.kitchenTempUpdated = false;
            }, this.flashBackgroundTime);
        });
    }



    getLivingroomTemp() {
        this.socketIoService.getJarfallaLivingroomTemp().subscribe((message: string) => {
            this.livingroomTemp = message;

            // Update square background color when value change.
            this.livingroomTempUpdated = true;
            setTimeout(() => {
                this.livingroomTempUpdated = false;
            }, this.flashBackgroundTime);
        });
    }



    getBedroomTemp() {
        this.socketIoService.getJarfallaBedroomTemp().subscribe((message: string) => {
            this.bedroomTemp = message;

            // Update square background color when value change.
            this.bedroomTempUpdated = true;
            setTimeout(() => {
                this.bedroomTempUpdated = false;
            }, this.flashBackgroundTime);
        });
    }

    getBedroom1Temp() {
        this.socketIoService.getJarfallaBedroom1Temp().subscribe((message: string) => {
            this.bedroom1Temp = message;

            // Update square background color when value change.
            this.bedroom1TempUpdated = true;
            setTimeout(() => {
                this.bedroom1TempUpdated = false;
            }, this.flashBackgroundTime);
        });
    }

    getOfficeTemp() {
        this.socketIoService.getJarfallaOfficeTemp().subscribe((message: string) => {
            this.officeTemp = message;

            // Update square background color when value change.
            this.officeTempUpdated = true;
            setTimeout(() => {
                this.officeTempUpdated = false;
            }, this.flashBackgroundTime);
        });
    }

    getLabTemp() {
        this.socketIoService.getJarfallaLabTemp().subscribe((message: string) => {
            this.labTemp = message;

            // Update square background color when value change.
            this.labTempUpdated = true;
            setTimeout(() => {
                this.labTempUpdated = false;
            }, this.flashBackgroundTime);
        });
    }


    getLivingroomHumidity() {
        this.socketIoService.getJarfallaLivingroomHumidity().subscribe((message: string) => {
            this.livingroomHumidity = message;

            // Update square background color when value change.
            this.livingroomHumidityUpdated = true;
            setTimeout(() => {
                this.livingroomHumidityUpdated = false;
            }, this.flashBackgroundTime);
        });
    }

    getBedroomHumidity() {
        this.socketIoService.getJarfallaBedroomHumidity().subscribe((message: string) => {
            this.bedroomHumidity = message;

            // Update square background color when value change.
            this.bedroomHumidityUpdated = true;
            setTimeout(() => {
                this.bedroomHumidityUpdated = false;
            }, this.flashBackgroundTime);
        });
    }

    getOfficeHumidity() {
        this.socketIoService.getJarfallaOfficeHumidity().subscribe((message: string) => {
            this.officeHumidity = message;

            // Update square background color when value change.
            this.officeHumidityUpdated = true;
            setTimeout(() => {
                this.officeHumidityUpdated = false;
            }, this.flashBackgroundTime);
        });
    }

    getLabHumidity() {
        this.socketIoService.getJarfallaLabHumidity().subscribe((message: string) => {
            this.labHumidity = message;

            // Update square background color when value change.
            this.labHumidityUpdated = true;
            setTimeout(() => {
                this.labHumidityUpdated = false;
            }, this.flashBackgroundTime);
        });
    }

    getEnergyHouse() {
        this.socketIoService.getJarfallaEnergyHouse().subscribe((message: string) => {
            this.energyHouse = message;

            // Update square background color when value change.
            this.energyHouseUpdated = true;
            setTimeout(() => {
                this.energyHouseUpdated = false;
            }, this.flashBackgroundTime);
        });
    }

    getEnergyHouseHour() {
        this.socketIoService.getJarfallaEnergyHouseHour().subscribe((message: string) => {
            this.energyHouseHour = message;

            // Update square background color when value change.
            this.energyHouseHourUpdated = true;
            setTimeout(() => {
                this.energyHouseHourUpdated = false;
            }, this.flashBackgroundTime);
        });
    }


    getEnergyCostPriviousHouseHour() {
        this.socketIoService.getJarfallaEnergyHouseCostPriviousHour().subscribe((message: string) => {
            this.energyHouseCostPriviousHour = message;

            // Update square background color when value change.
            this.energyHouseCostPriviousHourUpdated = true;
            setTimeout(() => {
                this.energyHouseCostPriviousHourUpdated = false;
            }, this.flashBackgroundTime);
        });
    }


    getKitchenDoorOpenClosedSensor() {
        this.socketIoService.getJarfallaKitchenDoorOpenClosedSensor().subscribe((message: string) => {
            this.kitchenDoorOpenClosedSensor = message;

            if (parseInt(this.kitchenDoorOpenClosedSensor) == 1) {
                this.kitchenDoorOpenClosedSensorImage = "assets/images/door/dooropen.png";
            }
            else {
                this.kitchenDoorOpenClosedSensorImage = "assets/images/door/doorclosed.png";
            }

            // Update square background color when value change.
            this.kitchenDoorOpenClosedSensorUpdated = true;
            setTimeout(() => {
                this.kitchenDoorOpenClosedSensorUpdated = false;
            }, this.flashBackgroundTime);
        });
    }


    getKitchenDoorBatteryLevelSensor() {
        this.socketIoService.getJarfallaKitchenDoorBatteryLevelSensor().subscribe((message: string) => {
            this.kitchenDoorBatteryLevelSensor = message;

            if (parseInt(this.kitchenDoorBatteryLevelSensor) < this.lowPower) {
                this.kitchenDoorBatteryLevelSensorImage = "assets/images/battery/lowpower.png";
            }
            else {
                this.kitchenDoorBatteryLevelSensorImage = "";
            }

            // Update square background color when value change.
            this.kitchenDoorBatteryLevelSensorUpdated = true;
            setTimeout(() => {
                this.kitchenDoorBatteryLevelSensorUpdated = false;
            }, this.flashBackgroundTime);
        });
    }



    getLivingroomDoorOpenClosedSensor() {
        this.socketIoService.getJarfallaLivingroomDoorOpenClosedSensor().subscribe((message: string) => {
            this.livingroomDoorOpenClosedSensor = message;

            if (parseInt(this.livingroomDoorOpenClosedSensor) == 1) {
                this.livingroomDoorOpenClosedSensorImage = "assets/images/door/dooropen.png";
            }
            else {
                this.livingroomDoorOpenClosedSensorImage = "assets/images/door/doorclosed.png";
            }

            // Update square background color when value change.
            this.livingroomDoorOpenClosedSensorUpdated = true;
            setTimeout(() => {
                this.livingroomDoorOpenClosedSensorUpdated = false;
            }, this.flashBackgroundTime);
        });
    }


    getLivingroomDoorBatteryLevelSensor() {
        this.socketIoService.getJarfallaLivingroomDoorBatteryLevelSensor().subscribe((message: string) => {
            this.livingroomDoorBatteryLevelSensor = message;

            if (parseInt(this.livingroomDoorBatteryLevelSensor) < this.lowPower) {
                this.livingroomDoorBatteryLevelSensorImage = "assets/images/battery/lowpower.png";
            }
            else {
                this.livingroomDoorBatteryLevelSensorImage = "";
            }

            // Update square background color when value change.
            this.livingroomDoorBatteryLevelSensorUpdated = true;
            setTimeout(() => {
                this.livingroomDoorBatteryLevelSensorUpdated = false;
            }, this.flashBackgroundTime);
        });
    }





    getKitchenWindowOpenClosedSensor() {
        this.socketIoService.getJarfallaKitchenWindowOpenClosedSensor().subscribe((message: string) => {
            this.kitchenWindowOpenClosedSensor = message;

            if (parseInt(this.kitchenWindowOpenClosedSensor) == 1) {
                this.kitchenWindowOpenClosedSensorImage = "assets/images/window/windowopen.png";
            }
            else {
                this.kitchenWindowOpenClosedSensorImage = "assets/images/window/windowclosed.png";
            }

            // Update square background color when value change.
            this.kitchenWindowOpenClosedSensorUpdated = true;
            setTimeout(() => {
                this.kitchenWindowOpenClosedSensorUpdated = false;
            }, this.flashBackgroundTime);
        });
    }


    getKitchenWindowBatteryLevelSensor() {
        this.socketIoService.getJarfallaKitchenWindowBatteryLevelSensor().subscribe((message: string) => {
            this.kitchenWindowBatteryLevelSensor = message;

            if (parseInt(this.kitchenWindowBatteryLevelSensor) < this.lowPower) {
                this.kitchenWindowBatteryLevelSensorImage = "assets/images/battery/lowpower.png";
            }
            else {
                this.kitchenWindowBatteryLevelSensorImage = "";
            }

            // Update square background color when value change.
            this.kitchenWindowBatteryLevelSensorUpdated = true;
            setTimeout(() => {
                this.kitchenWindowBatteryLevelSensorUpdated = false;
            }, this.flashBackgroundTime);
        });
    }




    getBedroomWindowOpenClosedSensor() {
        this.socketIoService.getJarfallaBedroomWindowOpenClosedSensor().subscribe((message: string) => {
            this.bedroomWindowOpenClosedSensor = message;

            if (parseInt(this.bedroomWindowOpenClosedSensor) == 1) {
                this.bedroomWindowOpenClosedSensorImage = "assets/images/window/windowopen.png";
            }
            else {
                this.bedroomWindowOpenClosedSensorImage = "assets/images/window/windowclosed.png";
            }

            // Update square background color when value change.
            this.bedroomWindowOpenClosedSensorUpdated = true;
            setTimeout(() => {
                this.bedroomWindowOpenClosedSensorUpdated = false;
            }, this.flashBackgroundTime);
        });
    }


    getBedroomWindowBatteryLevelSensor() {
        this.socketIoService.getJarfallaBedroomWindowBatteryLevelSensor().subscribe((message: string) => {
            this.bedroomWindowBatteryLevelSensor = message;

            if (parseInt(this.bedroomWindowBatteryLevelSensor) < this.lowPower) {
                this.bedroomWindowBatteryLevelSensorImage = "assets/images/battery/lowpower.png";
            }
            else {
                this.bedroomWindowBatteryLevelSensorImage = "";
            }

            // Update square background color when value change.
            this.bedroomWindowBatteryLevelSensorUpdated = true;
            setTimeout(() => {
                this.bedroomWindowBatteryLevelSensorUpdated = false;
            }, this.flashBackgroundTime);
        });
    }



    getBedroom1WindowOpenClosedSensor() {
        this.socketIoService.getJarfallaBedroom1WindowOpenClosedSensor().subscribe((message: string) => {
            this.bedroom1WindowOpenClosedSensor = message;

            if (parseInt(this.bedroom1WindowOpenClosedSensor) == 1) {
                this.bedroom1WindowOpenClosedSensorImage = "assets/images/window/windowopen.png";
            }
            else {
                this.bedroom1WindowOpenClosedSensorImage = "assets/images/window/windowclosed.png";
            }

            // Update square background color when value change.
            this.bedroom1WindowOpenClosedSensorUpdated = true;
            setTimeout(() => {
                this.bedroom1WindowOpenClosedSensorUpdated = false;
            }, this.flashBackgroundTime);
        });
    }

    getBedroom1WindowBatteryLevelSensor() {
        this.socketIoService.getJarfallaBedroom1WindowBatteryLevelSensor().subscribe((message: string) => {
            this.bedroomWindowBatteryLevelSensor = message;

            if (parseInt(this.bedroom1WindowBatteryLevelSensor) < this.lowPower) {
                this.bedroom1WindowBatteryLevelSensorImage = "assets/images/battery/lowpower.png";
            }
            else {
                this.bedroom1WindowBatteryLevelSensorImage = "";
            }

            // Update square background color when value change.
            this.bedroom1WindowBatteryLevelSensorUpdated = true;
            setTimeout(() => {
                this.bedroom1WindowBatteryLevelSensorUpdated = false;
            }, this.flashBackgroundTime);
        });
    }



    getBedroomBlinds() {
        this.socketIoService.getJarfallaBedroomBlinds().subscribe((message: string) => {
            this.bedroomBlinds = message;

            if (parseInt(this.bedroomBlinds) == 100) {
                this.bedroomBlindsImage = "assets/images/blinds/blindsopen.png";
            }
            else {
                this.bedroomBlindsImage = "assets/images/blinds/blindsclosed.png";
            }

            // Update square background color when value change.
            this.bedroomBlindsUpdated = true;
            setTimeout(() => {
                this.bedroomBlindsUpdated = false;
            }, this.flashBackgroundTime);
        });
    }

    getBedroom1Blinds() {
        this.socketIoService.getJarfallaBedroom1Blinds().subscribe((message: string) => {
            this.bedroom1Blinds = message;

            if (parseInt(this.bedroom1Blinds) == 100) {
                this.bedroom1BlindsImage = "assets/images/blinds/blindsopen.png";
            }
            else {
                this.bedroom1BlindsImage = "assets/images/blinds/blindsclosed.png";
            }

            // Update square background color when value change.
            this.bedroom1BlindsUpdated = true;
            setTimeout(() => {
                this.bedroom1BlindsUpdated = false;
            }, this.flashBackgroundTime);
        });
    }
}
