<div id="top" style="cursor: pointer;" (click)="btnHomeClick();">
    iPrincip.nu
</div>

<!-- -------------------------------------------------------------- -->
<!-- Row 1                                                          -->
<!-- -------------------------------------------------------------- -->
<div id="squarerow">

    <div class="square">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;" (click)="btnHouseClick();">
                    <!-- <div class="table-cell" style="cursor: pointer;" onclick="window.location='house';"> -->
                    <div *ngIf="ipAddressValid; else elseBlock1">
                        <img class="imagers" src="assets/images/house/housekeyopen.png">
                    </div>
                    <ng-template #elseBlock1>
                        <img class="imagers" src="assets/images/house/housekeyclosed.png">
                    </ng-template>

                </div>
            </div>
        </div>
    </div>

    <div class="square">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;" (click)="btnHistoryClick();">
                    <img class="imagers" src="assets/images/history/history.png">
                </div>
            </div>
        </div>
    </div>

    <div class="square">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;" (click)="btnSolarPowerSystemClick();">
                    <img class="imagers" src="assets/images/solarpowersystem/solarpowersystem.png">
                </div>
            </div>
        </div>
    </div>


    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>


    <div class="square">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;" (click)="btnStationRecordsClick();">
                    <img class="imagers" src="assets/images/stationrecords.png">
                </div>
            </div>
        </div>
    </div>


    <div [className]="moonPhaseVisibilityUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;" (click)="btnMoonPhaseClick();">
                    <img class="imagers" src="{{ moonPhaseImage }}">
                </div>
            </div>
        </div>
    </div>


    <div class="square">
        <div class="content">
            <div class="table" style="cursor: pointer;" (click)="btnSunriseSunsetClick();">
                <div *ngIf="sunriseSunset" class="table-cell texts12">
                    <font color="lightblue">
                        <br>Sunrise: {{ sunriseSunset.sunrise }}
                    </font>
                    <font color="lightblue">
                        <br>Sunset: {{ sunriseSunset.sunset }}
                    </font>
                    <font color="lightblue">
                        <br>Length: {{ sunriseSunset.dayLength }}
                    </font>
                </div>
            </div>
        </div>
    </div>



    <div class="square">
        <div class="content">
            <a href="https://www.google.se/maps/place/J%C3%A4rf%C3%A4lla/@59.4445294,17.7376074,12z/data=!3m1!4b1!4m5!3m4!1s0x465fa19236619271:0x288239ab1520271!8m2!3d59.4349407!4d17.8070801"
                target="_blank">
                <div class="table">
                    <img class="imagers" src="assets/images/position/position.png">
                    <br>
                    <div class="texts18">
                        <font color="lightgray">
                            Position
                        </font>
                    </div>
                </div>
            </a>
        </div>
    </div>
</div>


<!-- -------------------------------------------------------------- -->
<!-- Row 2                                                          -->
<!-- -------------------------------------------------------------- -->
<div id="squarerow">

    <div [className]="outdoorTempUpdated ? 'squarevaluechangeddouble' : 'squaredouble'">
        <div class="content">
            <div class="table">

                <div class="table-cell" style="cursor: pointer;"
                    (click)="btnGraphTemperatureClick('Outdoor', '1D', 'Outdoor temperature during the past day');">

                    <img class="imagers" src="{{ outdoorTempImage }}">
                    <br>
                    <div class="texts32">
                        <font color="yellow">
                            <div align="center">{{ outdoorTemp }} &deg;C</div>
                        </font>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div [className]="windCoolingTempUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell ">
                    <img class="imagers" src="{{ windCoolingTempImage }}">
                    <br>
                    <div class="texts18">
                        <font color="yellow">
                            <div align="center">{{ windCoolingTemp }} &deg;C</div>
                        </font>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div [className]="dewPointUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;"
                (click)="btnGraphDewPointClick('DewPoint', '1D', 'Dew point during the past day');">
                    <img class="imagers" src="{{ dewPointImage }}">
                    <br>
                    <div class="texts18">
                        <font color="yellow">
                            <div align="center">{{ dewPoint }} &deg;C</div>
                        </font>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div [className]="groundTempsUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;"
                    (click)="btnGraphTemperatureClick('Ground', '1Y', 'Ground temperature (-0.5 m) during the past year');">
                    <img class="imagers" src="{{ groundTempImage }}">
                    <br>
                    <div class="texts18">
                        <font color="yellow">
                            <div align="center">{{ groundTemp }} &deg;C</div>
                        </font>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div [className]="pressureUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;"
                    (click)="btnGraphPressureClick('Outdoor', '1D', 'Air pressure in the last 24 hours');">
                    <img class="imagers" src="{{ pressureImage }}">

                    <br>
                    <div class="texts18">
                        <font color="lightgreen">
                            <div align="center">{{ pressure }} hPa</div>
                        </font>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div [className]="outdoorHumidityUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;"
                    (click)="btnGraphHumidityClick('Outdoor', '1D', 'Humidity in the past 24 hours');">
                    <img class="imagers" src="assets/images/humidity/humidity.png">
                    <br>
                    <div class="texts18">
                        <font color="lightblue">
                            <div align="center">{{ outdoorHumidity }} %</div>
                        </font>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div [className]="snowDepthUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;"
                    (click)="btnGraphSnowdepthClick('Outdoor', '1W', 'Snowdepth over the past week');">
                    <img class="imagers" src="assets/images/snowdepth.png">
                    <br>
                    <div class="texts18">
                        <font color="lightblue">
                            <!-- <div align="center">{{ snowDepth }} cm</div> -->
                            <div align="center">Offline</div>
                        </font>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</div>


<!-- -------------------------------------------------------------- -->
<!-- Row 3                                                          -->
<!-- -------------------------------------------------------------- -->
<div id="squarerow">




    <div [className]="sunlightNowUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;"
                    (click)="btnGraphSunlightClick('Outdoor', '1D', 'Sunlight over the past day');">
                    <img class="imagers" src="{{ sunlightImage }}">
                    <br>
                    <div class="texts18">
                        <font color="yellow">
                            <div align="center">{{ sunlightNow }} %</div>
                        </font>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div [className]="uvradiationUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;"
                    (click)="btnGraphUVradiationClick('Outdoor', '1D', 'UVradiation over the past day');">
                    <div>
                        <br>
                    </div>
                    <img class="imagers" src="{{ uvIndexImage }}">
                    <div>
                        <br>
                    </div>
                    <div class="texts12">
                        <font color="lightgray">
                            <div align="center">UV-index</div>
                        </font>
                    </div>
                    <div class="texts18">
                        <!-- <font color="orange">
                            <div align="center">{{ uvradiation }}</div>
                        </font> -->
                        <font color="lightblue">
                            <div align="center">Offline</div>
                        </font>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div [className]="rainAmountLast24HrUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;"
                    (click)="btnGraphRainClick('Outdoor', '96', 'Rain over the past 24 hours');">
                    <img class="imagers" src="assets/images/rain/rain.png">
                    <br>
                    <div class="texts18">
                        <font color="lightblue">
                            <!-- <div align="center">{{ rainAmountLast24Hr }} mm</div> -->
                            <div align="center">Offline</div>
                        </font>
                    </div>
                    <!-- <div class="texts12">
                        <font color="lightgray">
                            <div align="center">(Last 24 hours)</div>                         
                        </font>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
    




    <!-- <div [className]="windDirectionDegreesUpdated ? 'squarevaluechanged' : 'square'"> -->
    <div class="square">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <img class="imagers" src="{{ windDirectionImage }}">
                </div>
            </div>
        </div>
    </div>



    <div [className]="windMetrePerSecondUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <img class="imagers" src="assets/images/wind/wind.png">
                    <br>
                    <div class="texts18">
                        <font color="yellow">
                            <div align="center">{{ windMetrePerSecond }} m/s</div>
                        </font>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div [className]="windMaxMetrePerSecondHrUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <img class="imagers" src="assets/images/windmax/windmax.png">
                    <br>
                    <div class="texts18">
                        <font color="yellow">
                            <div align="center">{{ windMaxMetrePerSecondhr }} m/s</div>
                        </font>
                    </div>
                    <div class="texts12">
                        <font color="lightgray">
                            <div align="center">(Last hour)</div>
                        </font>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>


<!-- -------------------------------------------------------------- -->
<!-- Row 4                                                          -->
<!-- -------------------------------------------------------------- -->
<div id="squarerow">

    <div [className]="greenhouseTempUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;"
                    (click)="btnGraphTemperatureClick('Greenhouse', '1D', 'Greenhouse temperature during the past day');">
                    <img class="imagers" src="assets/images/greenhouse.png">
                    <br>
                    <div class="texts18">
                        <font color="yellow">
                            <div align="center">{{ greenhouseTemp }} &deg;C</div>
                        </font>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>
    

    <div [className]="sunlightCurrentDayUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;"
                (click)="btnGraphSunlightdayClick('Outdoor', '1M', 'Sunlight part of the day, last 30 days');">
                    <img class="imagers" src="{{ sunlightImageCurrentDay }}">
                    <br>
                    <div class="texts18">
                        <font color="yellow">
                            <div align="center">{{ sunlightCurrentDay }} %</div>
                        </font>
                        <div class="texts12">
                            <font color="lightgray">
                                <div align="center">(Last 24 hours)</div>
                            </font>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div [className]="solarCanAirHeaterTempUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;"
                    (click)="btnGraphTemperatureClick('SolarCanAirHeater', '1D', 'Solar can air heater temperature during the past day');">

                    <img class="imagers" src="{{ solarCanAirHeaterImage }}">
                    <br>

                    <div *ngIf="solarCanAirHeaterStatusActive; else elseBlockSolarCanAirHeaterInactive">
                        <div class="texts18">
                            <font color="yellow">
                                <div align="center">{{ solarCanAirHeaterTemp }} &deg;C</div>
                            </font>
                        </div>
                    </div>
                    <ng-template #elseBlockSolarCanAirHeaterInactive>
                        <div class="texts18">
                            <font color="yellow">
                                <div align="center">Resting</div>
                            </font>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>



    <div [className]="daysSinceLastRainUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <div class="texts16">
                        <font color="lightgray">
                            <div align="center">Days since rain</div>
                        </font>
                    </div>
                    <div class="texts18">
                        <font color="lightblue">
                            <!-- <div align="center">{{ daysSinceLastRain }}</div> -->
                            <div align="center">Offline</div>                            
                        </font>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div [className]="windAvgMetrePerSecondHrUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <img class="imagers" src="assets/images/windaverage/windaverage.png">
                    <br>
                    <div class="texts18">
                        <font color="yellow">
                            <div align="center">{{ windAvgMetrePerSecondHr }} m/s</div>
                        </font>
                    </div>
                    <div class="texts12">
                        <font color="lightgray">
                            <div align="center">(Last hour)</div>
                        </font>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div [className]="windGustUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <img class="imagers" src="assets/images/windgust/windgust.png">
                    <br>
                    <div class="texts18">
                        <font color="yellow">
                            <div align="center">{{ windGust }} m/s</div>
                        </font>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <!-- -------------------------------------------------------------- -->
    <!-- Row 5                                                         -->
    <!-- -------------------------------------------------------------- -->



    <!-- echo '<div class="square">';
      echo '   <div class="content">';
      echo '        <div class="table">';
      echo '            <div class="table-cell"; style="cursor: pointer;" onclick="window.location=\'/php/design/design.php\';">';
      echo '                <img class="rs" src="/images/design/design.png"/>';
      echo '            </div>';
      echo '        </div>';
      echo '    </div>';
      echo '</div>'; -->

    <!-- <div class="squarenotready">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;" onclick="window.location='http://iprincip.nu/';">
                    <img class="imagers" src="assets/images/design.png">
                </div>
            </div>
        </div>
    </div> -->



    <!-- echo '<div class="square">';
      echo '   <div class="content">';
      echo '        <div class="table">';
      echo '            <div class="table-cell"; style="cursor: pointer;" onclick="window.location=\'/php/projects/projects.php\';">';
      echo '                <img class="rs" src="/images/projects.png"/>';
      echo '            </div>';
      echo '        </div>';
      echo '    </div>';
      echo '</div>'; -->

    <!-- <div class="squarenotready">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;" onclick="window.location='http://iprincip.nu/';">
                    <img class="imagers" src="assets/images/projects.png">
                </div>
            </div>
        </div>
    </div> -->



    <!-- echo '<div class="square">';
      echo '   <div class="content">';
      echo '        <div class="table">';
      echo '            <div class="table-cell"; style="cursor: pointer;" onclick="window.location=\'/php/otherprojects/otherprojects.php\';">';
      echo '                <img class="rs" src="/images/otherprojects.png"/>';
      echo '            </div>';
      echo '        </div>';
      echo '    </div>';
      echo '</div>'; -->

    <!-- <div class="squarenotready">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;" onclick="window.location='http://iprincip.nu/';">
                    <img class="imagers" src="assets/images/otherprojects.png">
                </div>
            </div>
        </div>
    </div> -->



    <div [className]="compost1TempUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;"
                    (click)="btnGraphTemperatureClick('Compost1', '6M', 'The temperature in the compost #1 last six months');">
                    <img class="imagers" src="assets/images/compost1.png">
                    <br>
                    <div class="texts18">
                        <font color="yellow">
                            <div align="center">{{ compost1Temp }} &deg;C</div>
                        </font>
                    </div>
                </div>
            </div>
        </div>
    </div>




    <div [className]="compost2TempUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;"
                    (click)="btnGraphTemperatureClick('Compost2', '6M', 'The temperature in the compost #2 last six months');">
                    <img class="imagers" src="assets/images/compost2.png">
                    <br>
                    <div class="texts18">
                        <font color="yellow">
                            <div align="center">{{ compost2Temp }} &deg;C</div>
                        </font>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>


    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>


    <div class="square">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;" (click)="btnHomeClick();">
                    <img class="imagers" src="assets/images/back.png">
                </div>
            </div>
        </div>
    </div>
</div>