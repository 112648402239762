import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SocketIoService } from '../socketIo.service';

@Component({
  selector: 'app-solarpowersystem',
  templateUrl: './solarpowersystem.component.html',
  styleUrls: ['./solarpowersystem.component.css']
})
export class SolarpowersystemComponent implements OnInit {

  public solarVoltage: string;
  public solarCurrent: string;
  public solarPowerL: string;
  public solarPowerH: string;
  public solarVoltageMaxToday: string;
  public solarVoltageMinToday: string;

  public batterySOC: string;
  public batteryVoltage: string;
  public batteryChargeCurrent: string;
  public batteryChargePowerL: string;
  public batteryChargePowerH: string
  public batteryTemperature: string;
  public batteryRemoteTemperature: string;
  public batteryStatus: string;
  public batteryVoltageMaxToday: string;
  public batteryVoltageMinToday: string;

  public dischargingOutputVoltage: string;
  public dischargingOutputCurrent: string;
  public dischargingOutputPowerL: string;
  public dischargingOutputPowerH: string;
  public temperatureInsideCase: string;
  public temperaturePowerComponents: string;
  public currentSystemRatedVoltage: string;

  private readonly flashBackgroundTime: number = 300;

  constructor(private router: Router, private http: HttpClient, private socketIoService: SocketIoService) { }

  ngOnInit(): void {
    this.getJarfallaSolarVoltageData();
    this.getJarfallaSolarCurrentData();
    this.getJarfallaSolarPowerLData();
    this.getJarfallaSolarPowerHData();
    this.getJarfallaSolarVoltageMaxTodayData();
    this.getJarfallaSolarVoltageMinTodayData();

    this.getJarfallaBatterySOCData();
    this.getJarfallaBatteryVoltageData();
    this.getJarfallaBatteryChargeCurrentData();
    this.getJarfallaBatteryTemperatureData();
    this.getJarfallaBatteryChargePowerLData();
    this.getJarfallaBatteryChargePowerHData();
    this.getJarfallaBatteryRemoteTemperatureData();
    this.getJarfallaBatteryStatusData();
    this.getJarfallaBatteryVoltageMaxTodayData();
    this.getJarfallaBatteryVoltageMinTodayData();

    this.getJarfallaSolarChargeControllerDischargingOutputVoltageData();
    this.getJarfallaSolarChargeControllerDischargingOutputCurrentData();
    this.getJarfallaSolarChargeControllerDischargingOutputPowerLData();
    this.getJarfallaSolarChargeControllerDischargingOutputPowerHData();
    this.getJarfallaSolarChargeControllerTemperatureInsideCaseData();
    this.getJarfallaSolarChargeControllerTemperaturePowerComponentsData();
    this.getJarfallaSolarChargeControllerCurrentSystemRatedVoltageData();

    this.socketIoService.requestStartData('SOLARPOWERSYSTEM');
  }

  btnHomeClick() {
    this.router.navigate(['/home']);
  }

  btnJarfallaClick() {
    this.router.navigate(['/jarfalla']);
  }

  public solarPowerSystemSolarVoltageUpdated: boolean = false;
  public solarPowerSystemSolarCurrentUpdated: boolean = false;
  public solarPowerSystemSolarPowerLUpdated: boolean = false;
  public solarPowerSystemSolarPowerHUpdated: boolean = false;
  public solarPowerSystemVoltageMaxTodayUpdated: boolean = false;
  public solarPowerSystemVoltageMinTodayUpdated: boolean = false;

  public solarPowerSystemBatterySOCUpdated: boolean = false;  
  public solarPowerSystemBatteryVoltageUpdated: boolean = false;
  public solarPowerSystemBatteryChargeCurrentUpdated: boolean = false;
  public solarPowerSystemBatteryTemperatureUpdated: boolean = false;  
  public solarPowerSystemBatteryChargePowerLUpdated: boolean = false;
  public solarPowerSystemBatteryChargePowerHUpdated: boolean = false;
  public solarPowerSystemBatteryRemoteTemperatureUpdated: boolean = false;
  public solarPowerSystemBatteryStatusUpdated: boolean = false;
  public solarPowerSystemBatteryVoltageMaxTodayUpdated: boolean = false;
  public solarPowerSystemBatteryVoltageMinTodayUpdated: boolean = false;
  
  public solarPowerSystemChargeControllerUpdated: boolean = false;

  public solarChargeControllerDischargingOutputVoltageUpdated: boolean = false;
  public solarChargeControllerDischargingOutputCurrentUpdated: boolean = false;
  public solarChargeControllerDischargingOutputPowerLUpdated: boolean = false;
  public solarChargeControllerDischargingOutputPowerHUpdated: boolean = false;
  public solarChargeControllerTemperatureInsideCaseUpdated: boolean = false;
  public solarChargeControllerTemperaturePowerComponentsUpdated: boolean = false;
  public solarChargeControllerCurrentSystemRatedVoltageUpdated: boolean = false;
  


  getJarfallaSolarVoltageData() {
    this.socketIoService.getJarfallaSolarVoltageData().subscribe((message: string) => {
        this.solarVoltage = message; //.split(":")[1]; // V

        console.log("solarVoltage: " + this.solarVoltage);
  
        // Update square background color when value change.
        this.solarPowerSystemSolarVoltageUpdated = true;

         setTimeout(() => {
           this.solarPowerSystemSolarVoltageUpdated = false;
         }, this.flashBackgroundTime);
      });
  }


  getJarfallaSolarCurrentData() {
    this.socketIoService.getJarfallaSolarCurrentData().subscribe((message: string) => {
        this.solarCurrent = message; //.split(":")[1]; // A

        console.log("solarCurrent: " + this.solarCurrent);
  
        // Update square background color when value change.
        this.solarPowerSystemSolarCurrentUpdated = true;

         setTimeout(() => {
           this.solarPowerSystemSolarCurrentUpdated = false;
         }, this.flashBackgroundTime);
      });
  }


  getJarfallaSolarPowerLData() {
    this.socketIoService.getJarfallaSolarPowerLData().subscribe((message: string) => {
        this.solarPowerL = message; //.split(":")[1]; // W

        // console.log("solarPowerL: " + this.solarPowerL);
  
        // Update square background color when value change.
        this.solarPowerSystemSolarPowerLUpdated = true;

         setTimeout(() => {
           this.solarPowerSystemSolarPowerLUpdated = false;
         }, this.flashBackgroundTime);
      });
  }


  getJarfallaSolarPowerHData() {
    this.socketIoService.getJarfallaSolarPowerHData().subscribe((message: string) => {
        this.solarPowerH = message; //.split(":")[1]; // W

        // console.log("solarPowerH: " + this.solarPowerH);
  
        // Update square background color when value change.
        this.solarPowerSystemSolarPowerHUpdated = true;

         setTimeout(() => {
           this.solarPowerSystemSolarPowerHUpdated = false;
         }, this.flashBackgroundTime);
      });
  }

  
  getJarfallaSolarVoltageMaxTodayData() {
    this.socketIoService.getJarfallaSolarVoltageMaxTodayData().subscribe((message: string) => {
        this.solarVoltageMaxToday = message; //.split(":")[1]; // V

        // console.log("solarVoltageMaxToday: " + this.solarVoltageMaxToday);
  
        // Update square background color when value change.
        this.solarPowerSystemVoltageMaxTodayUpdated = true;

         setTimeout(() => {
           this.solarPowerSystemVoltageMaxTodayUpdated = false;
         }, this.flashBackgroundTime);
      });
  }


  getJarfallaSolarVoltageMinTodayData() {
    this.socketIoService.getJarfallaSolarVoltageMinTodayData().subscribe((message: string) => {
        this.solarVoltageMinToday = message; //.split(":")[1]; // V

        // console.log("solarVoltageMinToday: " + this.solarVoltageMinToday);
  
        // Update square background color when value change.
        this.solarPowerSystemVoltageMinTodayUpdated = true;

         setTimeout(() => {
           this.solarPowerSystemVoltageMinTodayUpdated = false;
         }, this.flashBackgroundTime);
      });
  }
  


  getJarfallaBatterySOCData() {
    this.socketIoService.getJarfallaBatterySOCData().subscribe((message: string) => {
        this.batterySOC = message; //.split(":")[1]; // %

        // console.log("batterySOC: " + this.batterySOC);
  
        // Update square background color when value change.
        this.solarPowerSystemBatterySOCUpdated = true;

         setTimeout(() => {
           this.solarPowerSystemBatterySOCUpdated = false;
         }, this.flashBackgroundTime);
      });
  }


  getJarfallaBatteryVoltageData() {
    this.socketIoService.getJarfallaBatteryVoltageData().subscribe((message: string) => {
        this.batteryVoltage = message; //.split(":")[1]; // V

        // console.log("batteryVoltage: " + this.batteryVoltage);
  
        // Update square background color when value change.
        this.solarPowerSystemBatteryVoltageUpdated = true;

         setTimeout(() => {
           this.solarPowerSystemBatteryVoltageUpdated = false;
         }, this.flashBackgroundTime);
      });
  }


  getJarfallaBatteryChargeCurrentData() {
    this.socketIoService.getJarfallaBatteryChargeCurrentData().subscribe((message: string) => {
        this.batteryChargeCurrent = message; //.split(":")[1]; // A

        // console.log("batteryChargeCurrent: " + this.batteryChargeCurrent);
  
        // Update square background color when value change.
        this.solarPowerSystemBatteryChargeCurrentUpdated = true;

         setTimeout(() => {
           this.solarPowerSystemBatteryChargeCurrentUpdated = false;
         }, this.flashBackgroundTime);
      });
  }
  

  getJarfallaBatteryTemperatureData() {
    this.socketIoService.getJarfallaBatteryTemperatureData().subscribe((message: string) => {
        this.batteryTemperature = message; //.split(":")[1]; // C

        // console.log("batteryTemperature: " + this.batteryTemperature);
  
        // Update square background color when value change.
        this.solarPowerSystemBatteryTemperatureUpdated = true;

         setTimeout(() => {
           this.solarPowerSystemBatteryTemperatureUpdated = false;
         }, this.flashBackgroundTime);
      });
  }


  getJarfallaBatteryChargePowerLData() {
    this.socketIoService.getJarfallaBatteryChargePowerLData().subscribe((message: string) => {
        this.batteryChargePowerL = message; //.split(":")[1]; // C

        console.log("batteryChargePowerL: " + this.batteryChargePowerL);
  
        // Update square background color when value change.
        this.solarPowerSystemBatteryChargePowerLUpdated = true;

         setTimeout(() => {
           this.solarPowerSystemBatteryChargePowerLUpdated = false;
         }, this.flashBackgroundTime);
      });
  }


  getJarfallaBatteryChargePowerHData() {
    this.socketIoService.getJarfallaBatteryChargePowerHData().subscribe((message: string) => {
        this.batteryChargePowerH = message; //.split(":")[1]; // C

        console.log("batteryChargePowerH: " + this.batteryChargePowerH);
  
        // Update square background color when value change.
        this.solarPowerSystemBatteryChargePowerHUpdated = true;

         setTimeout(() => {
           this.solarPowerSystemBatteryChargePowerHUpdated = false;
         }, this.flashBackgroundTime);
      });
  }


  getJarfallaBatteryRemoteTemperatureData() {
    this.socketIoService.getJarfallaBatteryRemoteTemperatureData().subscribe((message: string) => {
        this.batteryRemoteTemperature = message; //.split(":")[1]; // C

        console.log("batteryRemoteTemperature: " + this.batteryRemoteTemperature);
  
        // Update square background color when value change.
        this.solarPowerSystemBatteryRemoteTemperatureUpdated = true;

         setTimeout(() => {
           this.solarPowerSystemBatteryRemoteTemperatureUpdated = false;
         }, this.flashBackgroundTime);
      });
  }


  getJarfallaBatteryStatusData() {
    this.socketIoService.getJarfallaBatteryStatusData().subscribe((message: string) => {
        this.batteryStatus = message; //.split(":")[1]; // C

        console.log("batteryStatus: " + this.batteryStatus);
  
        // Update square background color when value change.
        this.solarPowerSystemBatteryStatusUpdated = true;

         setTimeout(() => {
           this.solarPowerSystemBatteryStatusUpdated = false;
         }, this.flashBackgroundTime);
      });
  }


  getJarfallaBatteryVoltageMaxTodayData() {
    this.socketIoService.getJarfallaBatteryVoltageMaxTodayData().subscribe((message: string) => {
        this.batteryVoltageMaxToday = message; //.split(":")[1]; // C

        console.log("batteryVoltageMaxToday: " + this.batteryVoltageMaxToday);
  
        // Update square background color when value change.
        this.solarPowerSystemBatteryVoltageMaxTodayUpdated = true;

         setTimeout(() => {
           this.solarPowerSystemBatteryVoltageMaxTodayUpdated = false;
         }, this.flashBackgroundTime);
      });
  }


  getJarfallaBatteryVoltageMinTodayData() {
    this.socketIoService.getJarfallaBatteryVoltageMinTodayData().subscribe((message: string) => {
        this.batteryVoltageMinToday = message; //.split(":")[1]; // C

        console.log("batteryVoltageMinToday: " + this.batteryVoltageMinToday);
  
        // Update square background color when value change.
        this.solarPowerSystemBatteryVoltageMinTodayUpdated = true;

         setTimeout(() => {
           this.solarPowerSystemBatteryVoltageMinTodayUpdated = false;
         }, this.flashBackgroundTime);
      });
  }


  getJarfallaSolarChargeControllerDischargingOutputVoltageData() {
    this.socketIoService.getJarfallaSolarChargeControllerDischargingOutputVoltageData().subscribe((message: string) => {
        this.dischargingOutputVoltage = message; //.split(":")[1]; // C

        // console.log("dischargingOutputVoltage: " + this.dischargingOutputVoltage);
  
        // Update square background color when value change.
        this.solarChargeControllerDischargingOutputVoltageUpdated = true;

         setTimeout(() => {
           this.solarChargeControllerDischargingOutputVoltageUpdated = false;
         }, this.flashBackgroundTime);
      });
  }


  getJarfallaSolarChargeControllerDischargingOutputCurrentData() {
    this.socketIoService.getJarfallaSolarChargeControllerDischargingOutputCurrentData().subscribe((message: string) => {
        this.dischargingOutputCurrent = message; //.split(":")[1]; // C

        // console.log("dischargingOutputCurrent: " + this.dischargingOutputCurrent);
  
        // Update square background color when value change.
        this.solarChargeControllerDischargingOutputCurrentUpdated = true;

         setTimeout(() => {
           this.solarChargeControllerDischargingOutputCurrentUpdated = false;
         }, this.flashBackgroundTime);
      });
  }


  getJarfallaSolarChargeControllerDischargingOutputPowerLData() {
    this.socketIoService.getJarfallaSolarChargeControllerDischargingOutputPowerLData().subscribe((message: string) => {
        this.dischargingOutputPowerL = message; //.split(":")[1]; // C

        // console.log("dischargingOutputPowerL: " + this.dischargingOutputPowerL);
        // Update square background color when value change.
        this.solarChargeControllerDischargingOutputPowerLUpdated = true;

         setTimeout(() => {
           this.solarChargeControllerDischargingOutputPowerLUpdated = false;
         }, this.flashBackgroundTime);
      });
  }


  getJarfallaSolarChargeControllerDischargingOutputPowerHData() {
    this.socketIoService.getJarfallaSolarChargeControllerDischargingOutputPowerHData().subscribe((message: string) => {
        this.dischargingOutputPowerH = message; //.split(":")[1]; // C

        // console.log("dischargingOutputPowerH: " + this.dischargingOutputPowerH);
  
        // Update square background color when value change.
        this.solarChargeControllerDischargingOutputPowerHUpdated = true;

         setTimeout(() => {
           this.solarChargeControllerDischargingOutputPowerHUpdated = false;
         }, this.flashBackgroundTime);
      });
  }


  getJarfallaSolarChargeControllerTemperatureInsideCaseData() {
    this.socketIoService.getJarfallaSolarChargeControllerTemperatureInsideCaseData().subscribe((message: string) => {
        this.temperatureInsideCase = message; //.split(":")[1]; // C

        // console.log("temperatureInsideCase: " + this.temperatureInsideCase);
  
        // Update square background color when value change.
        this.solarChargeControllerTemperatureInsideCaseUpdated = true;

         setTimeout(() => {
           this.solarChargeControllerTemperatureInsideCaseUpdated = false;
         }, this.flashBackgroundTime);
      });
  }


  getJarfallaSolarChargeControllerTemperaturePowerComponentsData() {
    this.socketIoService.getJarfallaSolarChargeControllerTemperaturePowerComponentsData().subscribe((message: string) => {
        this.temperaturePowerComponents = message; //.split(":")[1]; // C

        // console.log("temperaturePowerComponents: " + this.temperaturePowerComponents);
  
        // Update square background color when value change.
        this.solarChargeControllerTemperaturePowerComponentsUpdated = true;

         setTimeout(() => {
           this.solarChargeControllerTemperaturePowerComponentsUpdated = false;
         }, this.flashBackgroundTime);
      });
  }


  getJarfallaSolarChargeControllerCurrentSystemRatedVoltageData() {
    this.socketIoService.getJarfallaSolarChargeControllerCurrentSystemRatedVoltageData().subscribe((message: string) => {
        this.currentSystemRatedVoltage = message; //.split(":")[1]; // C

        // console.log("currentSystemRatedVoltage: " + this.currentSystemRatedVoltage);
  
        // Update square background color when value change.
        this.solarChargeControllerCurrentSystemRatedVoltageUpdated = true;

         setTimeout(() => {
           this.solarChargeControllerCurrentSystemRatedVoltageUpdated = false;
         }, this.flashBackgroundTime);
      });
  }


  // getSolarchargeController() {
  //   this.socketIoService.getJarfallaSolarChargeControllerData().subscribe((message: string) => {
  //       let solarChargeControllerData = message;        
  //       let splitted = solarChargeControllerData.split(";"); 
        
  //       this.dischargingOutputPower = splitted[2].split(":")[1]; // W  
  //       //this.dischargingOutputPowerH = splitted[3].split(":")[1]; // W. Kommer att användas längre fram. Måste undersöka hur den ska hanteras.       
  //       this.temperatureInsideCase = splitted[4].split(":")[1]; // C
  //       this.temperaturePowerComponents = splitted[5].split(":")[1]; // C
  //       this.currentSystemRatedVoltage = splitted[6].split(":")[1]; // V
        
  //       console.log("dischargingOutputVoltage: " + this.dischargingOutputVoltage);
  //       console.log("dischargingOutputCurrent: " + this.dischargingOutputCurrent);
  //       console.log("dischargingOutputPower: " + this.dischargingOutputPower);        
  //       console.log("temperatureInsideCase: " + this.temperatureInsideCase);
  //       console.log("temperaturePowerComponents: " + this.temperaturePowerComponents);
  //       console.log("currentSystemRatedVoltage: " + this.currentSystemRatedVoltage);
  
  //       // Update square background color when value change.
  //       this.solarPowerSystemChargeControllerUpdated = true;

  //        setTimeout(() => {
  //          this.solarPowerSystemChargeControllerUpdated = false;
  //        }, this.flashBackgroundTime);
  //     });
  }