
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-graph-temperature',
  templateUrl: './graph-temperature.component.html',
  styleUrls: ['./graph-temperature.component.css']
})
export class GraphTemperatureComponent implements OnInit {

  constructor(private router: Router) {
    this.myData = this.router.getCurrentNavigation().extras.state.graphData;
    this.myTitle = this.router.getCurrentNavigation().extras.state.formTitle;
  }

  ngOnInit(): void {
  }

  myTitle = "";
  width = "1200";
  height = "800";
  myType = 'LineChart';
  myData = [];

  options = {
    curveType: 'function',
    hAxis: {
      title: 'Date/Time',
      direction: 1,
      slantedText: true,
      slantedTextAngle: 90,
    },
    vAxis: {
      title: 'Degrees',
      textStyle: {
        color: '#01579b',
        fontSize: 16,
        fontName: 'Arial',
        bold: false,
        italic: true
      }
    },
    gridlines: {
      count: 10,
    },
    chartArea: {
      top: 55,
      height: '60%'
    }
  };
}
