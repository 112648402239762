<div id="top" style="cursor: pointer;" (click)="btnHomeClick();">
  iPrincip.nu
</div>

<!-- -------------------------------------------------------------- -->
<!-- Row 1                                                          -->
<!-- -------------------------------------------------------------- -->
<div id="squarerow">


  <div [className]="hallTempUpdated ? 'squarevaluechanged' : 'square'">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
          (click)="btnGraphTemperatureClick('HallLowerFloor', '1D', 'Hall Lower floor temperature during the past day');">
          <div class="texts16">
            <font color="lightgray">
              <div align="center">Hall</div>
            </font>
          </div>
          <br>
          <div class="texts18">
            <font color="yellow">
              <div align="center" *ngIf="ipAddressValid; else elseBlock">{{ hallTemp }} &deg;C</div>
              <ng-template #elseBlock>
                <img class="imagers" src="assets/images/lock/lock.png">
              </ng-template>
            </font>
          </div>

        </div>
      </div>
    </div>
  </div>



  <!-- <div [className]="kitchenTempUpdated ? 'squarevaluechanged' : 'square'">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
          (click)="btnGraphTemperatureClick('Kitchen', '1D', 'Kitchen temperature during the past day');">
          <div class="texts16">
            <font color="lightgray">
              <div align="center">Kitchen</div>
            </font>
          </div>
          <br>
          <div class="texts18">
            <font color="yellow">
              <div align="center" *ngIf="ipAddressValid; else elseBlock">{{ kitchenTemp }} &deg;C</div>
              <ng-template #elseBlock>
                <img class="imagers" src="assets/images/lock/lock.png">
              </ng-template>
            </font>
          </div>

        </div>
      </div>
    </div>
  </div> -->

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>


  <div [className]="livingroomTempUpdated ? 'squarevaluechanged' : 'square'">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
          (click)="btnGraphTemperatureClick('Livingroom', '1D', 'Living room temperature during the past day');">
          <div class="texts16">
            <font color="lightgray">
              <div align="center">Living room</div>
            </font>
          </div>
          <br>
          <div class="texts18">
            <font color="yellow">
              <div align="center" *ngIf="ipAddressValid; else elseBlock">{{ livingroomTemp }} &deg;C</div>
              <ng-template #elseBlock>
                <img class="imagers" src="assets/images/lock/lock.png">
              </ng-template>
            </font>
          </div>

        </div>
      </div>
    </div>
  </div>


  <div [className]="bedroomTempUpdated ? 'squarevaluechanged' : 'square'">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
          (click)="btnGraphTemperatureClick('Bedroom', '1D', 'Bedroom temperature during the past day');">
          <div class="texts16">
            <font color="lightgray">
              <div align="center">Bedroom</div>
            </font>
          </div>
          <br>
          <div class="texts18">
            <font color="yellow">
              <div align="center" *ngIf="ipAddressValid; else elseBlock">{{ bedroomTemp }} &deg;C</div>
              <ng-template #elseBlock>
                <img class="imagers" src="assets/images/lock/lock.png">
              </ng-template>
            </font>
          </div>

        </div>
      </div>
    </div>
  </div>



  <div [className]="bedroom1TempUpdated ? 'squarevaluechanged' : 'square'">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
          (click)="btnGraphTemperatureClick('Bedroom1', '1D', 'Bedroom1 temperature during the past day');">
          <div class="texts16">
            <font color="lightgray">
              <div align="center">Bedroom1</div>
            </font>
          </div>
          <br>
          <div class="texts18">
            <font color="yellow">
              <div align="center" *ngIf="ipAddressValid; else elseBlock">{{ bedroom1Temp }} &deg;C</div>
              <ng-template #elseBlock>
                <img class="imagers" src="assets/images/lock/lock.png">
              </ng-template>
            </font>
          </div>

        </div>
      </div>
    </div>
  </div>


  <div [className]="officeTempUpdated ? 'squarevaluechanged' : 'square'">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
          (click)="btnGraphTemperatureClick('Office', '1D', 'Office temperature during the past day');">
          <div class="texts16">
            <font color="lightgray">
              <div align="center">Office</div>
            </font>
          </div>
          <br>
          <div class="texts18">
            <font color="yellow">
              <div align="center" *ngIf="ipAddressValid; else elseBlock">{{ officeTemp }} &deg;C</div>
              <ng-template #elseBlock>
                <img class="imagers" src="assets/images/lock/lock.png">
              </ng-template>
            </font>
          </div>

        </div>
      </div>
    </div>
  </div>


  <div [className]="labTempUpdated ? 'squarevaluechanged' : 'square'">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
          (click)="btnGraphTemperatureClick('Outbuilding', '1D', 'Lab. temperature during the past day');">
          <div class="texts16">
            <font color="lightgray">
              <div align="center">Lab.</div>
            </font>
          </div>
          <br>
          <div class="texts18">
            <font color="yellow">
              <div align="center" *ngIf="ipAddressValid; else elseBlock">{{ labTemp }} &deg;C</div>
              <ng-template #elseBlock>
                <img class="imagers" src="assets/images/lock/lock.png">
              </ng-template>
            </font>
          </div>

        </div>
      </div>
    </div>
  </div>



  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

</div>


<!-- -------------------------------------------------------------- -->
<!-- Row 2                                                          -->
<!-- -------------------------------------------------------------- -->
<div id="squarerow">

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>


  <div [className]="livingroomHumidityUpdated ? 'squarevaluechanged' : 'square'">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
          (click)="btnGraphHumidityClick('Livingroom', '1D', 'Livingroom humidity during the past day');">
          <div class="texts16">
            <font color="lightgray">
              <div align="center">Living room<br>Humidity</div>
            </font>
          </div>
          <br>
          <div class="texts18">
            <font color="orange">
              <div align="center" *ngIf="ipAddressValid; else elseBlock">{{ livingroomHumidity }} %</div>
              <ng-template #elseBlock>
                <img class="imagers" src="assets/images/lock/lock.png">
              </ng-template>
            </font>
          </div>

        </div>
      </div>
    </div>
  </div>



  <div [className]="bedroomHumidityUpdated ? 'squarevaluechanged' : 'square'">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
          (click)="btnGraphHumidityClick('Bedroom', '1D', 'Bedroom humidity during the past day');">
          <div class="texts16">
            <font color="lightgray">
              <div align="center">Bedroom<br>Humidity</div>
            </font>
          </div>
          <br>
          <div class="texts18">
            <font color="orange">
              <div align="center" *ngIf="ipAddressValid; else elseBlock">{{ bedroomHumidity }} %</div>
              <ng-template #elseBlock>
                <img class="imagers" src="assets/images/lock/lock.png">
              </ng-template>
            </font>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

  <div [className]="officeHumidityUpdated ? 'squarevaluechanged' : 'square'">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
          (click)="btnGraphHumidityClick('Office', '1D', 'Office humidity during the past day');">
          <div class="texts16">
            <font color="lightgray">
              <div align="center">Office<br>Humidity</div>
            </font>
          </div>
          <br>
          <div class="texts18">
            <font color="orange">
              <div align="center" *ngIf="ipAddressValid; else elseBlock">{{ officeHumidity }} %</div>
              <ng-template #elseBlock>
                <img class="imagers" src="assets/images/lock/lock.png">
              </ng-template>
            </font>
          </div>
        </div>
      </div>
    </div>
  </div>



  <div [className]="labHumidityUpdated ? 'squarevaluechanged' : 'square'">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
          (click)="btnGraphHumidityClick('Outbuilding', '1D', 'Lab. humidity during the past day');">
          <div class="texts16">
            <font color="lightgray">
              <div align="center">Lab.<br>Humidity</div>
            </font>
          </div>
          <br>
          <div class="texts18">
            <font color="orange">
              <div align="center" *ngIf="ipAddressValid; else elseBlock">{{ labHumidity }} %</div>
              <ng-template #elseBlock>
                <img class="imagers" src="assets/images/lock/lock.png">
              </ng-template>
            </font>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

</div>


<!-- -------------------------------------------------------------- -->
<!-- Row 3                                                          -->
<!-- -------------------------------------------------------------- -->
<div id="squarerow">

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>


  <div [className]="kitchenDoorOpenClosedSensorUpdated ? 'squarevaluechanged' : 'square'">
    <div class="content">
      <div class="table">
        <div class="table-cell">
          <br>
          <div *ngIf="ipAddressValid; else elseBlockKitchenDoor">
            <img class="imagers" src="{{ kitchenDoorOpenClosedSensorImage }}">
          </div>
          <ng-template #elseBlockKitchenDoor>
            <!-- <img class="imagers" src="assets/images/door/doorclosed.png"> -->
            <img class="imagers" src="assets/images/lock/lock.png">
          </ng-template>
          <br>
          <div class="texts16">
            <font color="lightblue">
              <div align="center">Kitchen</div>
            </font>
          </div>
          <img class="imagers" src="{{ kitchenDoorBatteryLevelSensorImage }}">
        </div>
      </div>
    </div>
  </div>


  <div [className]="livingroomDoorOpenClosedSensorUpdated ? 'squarevaluechanged' : 'square'">
    <div class="content">
      <div class="table">
        <div class="table-cell">
          <br>
          <div *ngIf="ipAddressValid; else elseBlockLivingroomDoor">
            <img class="imagers" src="{{ livingroomDoorOpenClosedSensorImage }}">
          </div>
          <ng-template #elseBlockLivingroomDoor>
            <!-- <img class="imagers" src="assets/images/door/doorclosed.png"> -->
            <img class="imagers" src="assets/images/lock/lock.png">
          </ng-template>
          <br>
          <div class="texts16">
            <font color="lightblue">
              <div align="center">Balcony</div>
            </font>
          </div>
          <img class="imagers" src="{{ livingroomDoorBatteryLevelSensorImage }}">
        </div>
      </div>
    </div>
  </div>


  <div [className]="bedroomWindowOpenClosedSensorUpdated ? 'squarevaluechanged' : 'square'">
    <div class="content">
      <div class="table">
        <div class="table-cell">
          <br>
          <div *ngIf="ipAddressValid; else elseBlockBedroomWindowOpen">
            <img class="imagers" src="{{ bedroomWindowOpenClosedSensorImage }}">
          </div>
          <ng-template #elseBlockBedroomWindowOpen>
            <img class="imagers" src="assets/images/lock/lock.png">
          </ng-template>
          <br>
          <div class="texts16">
            <font color="lightblue">
              <div align="center">Bedroom</div>
            </font>
          </div>
          <img class="imagers" src="{{ bedroomWindowBatteryLevelSensorImage }}">
        </div>
      </div>
    </div>
  </div>


  <div [className]="bedroom1WindowOpenClosedSensorUpdated ? 'squarevaluechanged' : 'square'">
    <div class="content">
      <div class="table">
        <div class="table-cell">
          <br>
          <div *ngIf="ipAddressValid; else elseBlockBedroom1WindowOpen">
            <img class="imagers" src="{{ bedroom1WindowOpenClosedSensorImage }}">
          </div>
          <ng-template #elseBlockBedroom1WindowOpen>
            <img class="imagers" src="assets/images/lock/lock.png">
          </ng-template>
          <br>
          <div class="texts16">
            <font color="lightblue">
              <div align="center">Bedroom1</div>
            </font>
          </div>
          <img class="imagers" src="{{ bedroom1WindowBatteryLevelSensorImage }}">
        </div>
      </div>
    </div>
  </div>


  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>


  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>


  <!-- echo '<div class="square">';
        echo '   <div class="content">';
        echo '        <div class="table">';
        echo '            <div class="table-cell"; style="cursor: pointer;" onclick="window.location=\'/log/php/logipvisits.php\';">';
        echo '                <img class="rs" src="/log/images/log.png"/>';
        echo '            </div>';
        echo '        </div>';
        echo '    </div>';
        echo '</div>'; -->


  <!-- <div class="squarenotready">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;" (click)="btnHistoryClick();">
          <img class="imagers" src="assets/images/log.png">
        </div>
      </div>
    </div>
  </div> -->



  <!-- echo '<div class="square">';
        echo '   <div class="content">';
        echo '        <div class="table">';
        echo '            <div class="table-cell"; style="cursor: pointer;" onclick="window.location=\'/log/php/log.php\';">';
        echo '                <img class="rs" src="/log/images/log.png"/>';
        echo '            </div>';
        echo '        </div>';
        echo '    </div>';
        echo '</div>'; -->


  <!-- <div class="squarenotready">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;" (click)="btnHistoryClick();">
          <img class="imagers" src="assets/images/log.png">
        </div>
      </div>
    </div>
  </div> -->


</div>


<!-- -------------------------------------------------------------- -->
<!-- Row 4                                                          -->
<!-- -------------------------------------------------------------- -->
<div id="squarerow">

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

  <div [className]="kitchenWindowOpenClosedSensorUpdated ? 'squarevaluechanged' : 'square'">
    <div class="content">
      <div class="table">
        <div class="table-cell">
          <br>
          <div *ngIf="ipAddressValid; else elseBlockKitchenWindowOpen">
            <img class="imagers" src="{{ kitchenWindowOpenClosedSensorImage }}">
          </div>
          <ng-template #elseBlockKitchenWindowOpen>
            <img class="imagers" src="assets/images/lock/lock.png">
          </ng-template>
          <br>
          <div class="texts16">
            <font color="lightblue">
              <div align="center">Kitchen</div>
            </font>
          </div>
          <img class="imagers" src="{{ kitchenWindowBatteryLevelSensorImage }}">
        </div>
      </div>
    </div>
  </div>

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

  <div [className]="bedroomBlindsUpdated ? 'squarevaluechanged' : 'square'">
    <div class="content">
      <div class="table">
        <div class="table-cell">
          <br>
          <div *ngIf="ipAddressValid; else elseBlock4">
            <img class="imagers" src="{{ bedroomBlindsImage }}">
          </div>
          <ng-template #elseBlock4>
            <img class="imagers" src="assets/images/lock/lock.png">
          </ng-template>
          <br>
          <div class="texts16">
            <font color="lightblue">
              <div align="center">Bedroom</div>
            </font>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div [className]="bedroom1BlindsUpdated ? 'squarevaluechanged' : 'square'">
    <div class="content">
      <div class="table">
        <div class="table-cell">
          <br>
          <div *ngIf="ipAddressValid; else elseBlock5">
            <img class="imagers" src="{{ bedroom1BlindsImage }}">
          </div>
          <ng-template #elseBlock5>
            <img class="imagers" src="assets/images/lock/lock.png">
          </ng-template>
          <br>
          <div class="texts16">
            <font color="lightblue">
              <div align="center">Bedroom1</div>
            </font>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

</div>

<!-- -------------------------------------------------------------- -->
<!-- Row 5                                                          -->
<!-- -------------------------------------------------------------- -->
<div id="squarerow">

  <div *ngIf="ipAddressValid; else elseBlock6">
    <div class="squarenotready">
      <div class="content">
        <div class="table">
          <div class="table-cell" style="cursor: pointer;" onclick="window.location='showpressureday.php';">
            <img class="imagers" src="assets/images/dogbarkingsystem.png">
            <br>
            <div class="texts15">
              <font color="red">
                <div align="center">Dog Barking system</div>
              </font>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #elseBlock6>
    <div class="hiddensquare">
      <div class="content">
        <div class="table">
          <div class="table-cell">
          </div>
        </div>
      </div>
    </div>
  </ng-template>


  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>


  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>


  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>


  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>


  <div [className]="energyHouseUpdated ? 'squarevaluechanged' : 'square'">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
          onclick="window.location='showtemperaturehalllowerfloorday.php';">
          <div class="texts16">
            <font color="lightgray">
              <div align="center">Energy</div>
            </font>
          </div>
          <div class="texts12">
            <font color="lightgray">
              <div align="center">now</div>
            </font>
          </div>
          <br>
          <div class="texts18">
            <font color="violet">

              <div *ngIf="ipAddressValid; else elseBlock7">
                <div align="center">{{ energyHouse }} W</div>
              </div>
              <ng-template #elseBlock7>
                <img class="imagers" src="assets/images/lock/lock.png">
              </ng-template>

            </font>
          </div>
        </div>
      </div>
    </div>
  </div>



  <div [className]="energyHouseHourUpdated ? 'squarevaluechanged' : 'square'">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
          onclick="window.location='showtemperaturehalllowerfloorday.php';">
          <div class="texts16">
            <font color="lightgray">
              <div align="center">Energy</div>
            </font>
          </div>
          <div class="texts12">
            <font color="lightgray">
              <div align="center">privious hour</div>
            </font>
          </div>
          <br>
          <div class="texts18">
            <font color="violet">
              <div *ngIf="ipAddressValid; else elseBlock8">
                <div align="center">{{ energyHouseHour }} kWh</div>
              </div>
              <ng-template #elseBlock8>
                <img class="imagers" src="assets/images/lock/lock.png">
              </ng-template>
            </font>
          </div>
        </div>
      </div>
    </div>
  </div>



  <div [className]="energyHouseCostPriviousHourUpdated ? 'squarevaluechanged' : 'square'">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
          onclick="window.location='showtemperaturehalllowerfloorday.php';">
          <div class="texts16">
            <font color="lightgray">
              <div align="center">Energy Cost</div>
            </font>
          </div>
          <div class="texts12">
            <font color="lightgray">
              <div align="center">privious hour</div>
            </font>
          </div>
          <br>
          <div class="texts18">
            <font color="violet">
              <div *ngIf="ipAddressValid; else elseBlock9">
                <div align="center">{{ energyHouseCostPriviousHour }} SKR</div>
              </div>
              <ng-template #elseBlock9>
                <img class="imagers" src="assets/images/lock/lock.png">
              </ng-template>

            </font>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>



<!-- -------------------------------------------------------------- -->
<!-- Row 6                                                          -->
<!-- -------------------------------------------------------------- -->
<div id="squarerow">

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>


  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>


  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>


  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>


  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>


  <div class="square">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;" (click)="btnJarfallaClick();">
          <img class="imagers" src="assets/images/back.png">
        </div>
      </div>
    </div>
  </div>

</div>