<div id="top" style="cursor: pointer;" (click)="btnHomeClick();">
    iPrincip.nu
  </div>
  
<hr>

<table id="users">
    <tr>
        <th *ngFor="let column of headers">
            {{column}}
        </th>
    </tr>

    <tr *ngFor="let row of rows">
        <td *ngFor="let column of headers">
            {{row[column]}}
        </td>
    </tr>

</table>