import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SocketIoService } from '../socketIo.service';
import { sunriseSunsetData } from '../data/sunriseSunsetData';
import { SunriseSunset } from '../model/sunrisesunset.model';
import { Router } from '@angular/router';
import { temperatureData } from '../data/temperatureData';
import { GlobalConstants } from '../common/GlobalConstants ';

@Component({
    selector: 'app-torrevieja',
    templateUrl: './torrevieja.component.html',
    // styleUrls: ['./torrevieja.component.css']
})
export class TorreviejaComponent implements OnInit {
    private requestUrlJarfallaTemperature: string = GlobalConstants.requestUrl + '3333/jarfalla/temperature';
    
    // private requestUrlSunriseSunset: string = 'http://192.168.1.222:3333/sunrisesunset';
    private requestUrlSunriseSunset: string = GlobalConstants.requestUrl + '3333/sunrisesunset';
    // private requestUrlSunriseSunset: string = 'https://iprincipnu.mynetgear.com:3333/sunrisesunset';
    // private requestUrlSunriseSunset: string = 'http://iprincip.nu:3333/sunrisesunset';

    public sunriseSunset: SunriseSunset;
    public indoorLivingroomTemp: string;
    public indoorHallTemp: string

    public livingroomTempUpdated: boolean = false;
    public hallTempUpdated: boolean = false;

    private readonly flashBackgroundTime: number = 300;


    constructor(private router: Router, private http: HttpClient, private socketIoService: SocketIoService) {

    }

    ngOnInit(): void {        
        this.getIndoorLivingroomTemp();
        this.getIndoorHallTemp();
        this.getSunset();
        this.socketIoService.requestStartData('TORREVIEJA');
    }

    btnHomeClick() {
        this.router.navigate(['/home']);
    }


    btnSunriseSunsetClick() {
        this.router.navigate(['/sunrisesunset']);
    }


    getIndoorLivingroomTemp() {
        this.socketIoService.getTorreviejaLivingroomTemp().subscribe((message: string) => {
            this.indoorLivingroomTemp = message;

            // Update square background color when value change.
            this.livingroomTempUpdated = true;
            setTimeout(() => {
                this.livingroomTempUpdated = false;
            }, this.flashBackgroundTime);
        });
    }


    getIndoorHallTemp() {
      this.socketIoService.getTorreviejaHallTemp().subscribe((message: string) => {
          this.indoorHallTemp = message;

          // Update square background color when value change.
          this.hallTempUpdated = true;
          setTimeout(() => {
              this.hallTempUpdated = false;
          }, this.flashBackgroundTime);
      });
  }


    btnGraphTemperatureClick(zone: string, timespan: string, formTitle: string) {
        this.http
          .get<Array<temperatureData>>(this.requestUrlJarfallaTemperature, {
            params: { timespan: timespan, zone: zone }, observe: 'response'
          })
          .subscribe(resp => {
    
            let temperatureData = [];
    
            var i: number;
            for (i = 0; i < resp.body['temperatureDay'].length; i++) {
    
              let date = JSON.stringify(resp.body['temperatureDay'][i][0]).substr(1, 10) + " . " + JSON.stringify(resp.body['temperatureDay'][i][0]).substr(12, 5);
              let temp = resp.body['temperatureDay'][i][1];
    
              temperatureData.push([date, temp]);
            }
    
            this.router.navigate(['/graphtemperature'], { state: { graphData: temperatureData, formTitle: formTitle } });
          });
      }
    


    getSunset() {
        let now = new Date();
        let yyyymmdd = now.getFullYear().toString() + ((((now.getMonth() + 1)) < 10 ? "0" : "") + (now.getMonth() + 1)).toString() + ((now.getDate() < 10 ? "0" : "") + now.getDate()).toString();

        // console.log("yyyymmdd: " + yyyymmdd);

        let currentTimeZoneOffsetInHours = now.getTimezoneOffset() / 60;
    
        this.http
          .get<sunriseSunsetData>(this.requestUrlSunriseSunset, {
            params: { lat: "37.97872", lng: "-0.68222", yyyymmdd: yyyymmdd.toString(), currentTimeZoneOffsetInHours: currentTimeZoneOffsetInHours.toString() }, observe: 'response'
          })
          .subscribe(resp => {
            // console.log("resp.body.sunrise): " + resp.body.sunrise);
            // console.log("resp.body.sunset: " + resp.body.sunset);
            // console.log("resp.body.length: " + resp.body.length);

            this.sunriseSunset = new SunriseSunset(resp.body.sunrise, resp.body.sunset, resp.body.length);
          });
      }
}
