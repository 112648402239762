import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SocketIoService } from '../socketIo.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-system',
  templateUrl: './system.component.html',
  // styleUrls: ['./system.component.css']
})
export class SystemComponent implements OnInit {

    private readonly flashBackgroundTime: number = 300;

    public cpuRapi2Temp: string;
    public cpuRapi5Temp: string;
    public cpuRapi7Temp: string;
    public cpuRapi15Temp: string;
    public cpuRapi16Temp: string;
    public cpuRapi17Temp: string;
    public cpuRapi18Temp: string;
    public cpuRapi21Temp: string;
    public cpuRapi22Temp: string;

    public eventLineA1: string;
    public eventLineA2: string;
    public eventLineA3: string;
    public eventLineA4: string;
    public eventLineA5: string;
    public eventLineA6: string;
    public eventLineA7: string;
    public eventLineA8: string;
    public eventLineA9: string;
    public eventLineA10: string;
    public eventLineA11: string;
    public eventLineA12: string;
    public eventLineA13: string;
    public eventLineA14: string;
    public eventLineA15: string;
    public eventLineA16: string;
    public eventLineA17: string;

    public cpuRapi2TempUpdated: boolean = false;
    public cpuRapi5TempUpdated: boolean = false;
    public cpuRapi7TempUpdated: boolean = false;
    public cpuRapi15TempUpdated: boolean = false;
    public cpuRapi16TempUpdated: boolean = false;
    public cpuRapi17TempUpdated: boolean = false;
    public cpuRapi18TempUpdated: boolean = false;
    public cpuRapi21TempUpdated: boolean = false;
    public cpuRapi22TempUpdated: boolean = false;

    constructor(private router: Router, private http: HttpClient, private socketIoService: SocketIoService) {

    }

    ngOnInit(): void {
        this.getCPUrapi2Temp();
        this.getCPUrapi5Temp();
        this.getCPUrapi7Temp();
        this.getCPUrapi15Temp();
        this.getCPUrapi16Temp();
        this.getCPUrapi17Temp();
        this.getCPUrapi18Temp();
        this.getCPUrapi21Temp();
        this.getCPUrapi22Temp();

        this.getEvent();

        this.socketIoService.requestStartData('SYSTEM');
    }

    btnHomeClick() {
        this.router.navigate(['/home']);
    }


    getCPUrapi2Temp() {
        this.socketIoService.getJarfallaCPURapi2Temp().subscribe((message: string) => {
            this.cpuRapi2Temp = message;

            // Update square background color when value change.
            //this.cpuRapi5TempUpdated = true;
            setTimeout(() => {
                this.cpuRapi2TempUpdated = false;
            }, this.flashBackgroundTime);
        });
    }

    getCPUrapi5Temp() {
        this.socketIoService.getJarfallaCPURapi5Temp().subscribe((message: string) => {
            this.cpuRapi5Temp = message;

            // Update square background color when value change.
            //this.cpuRapi5TempUpdated = true;
            setTimeout(() => {
                this.cpuRapi5TempUpdated = false;
            }, this.flashBackgroundTime);
        });
    }

    getCPUrapi7Temp() {
        this.socketIoService.getJarfallaCPURapi7Temp().subscribe((message: string) => {
            this.cpuRapi7Temp = message;

            // Update square background color when value change.
            //this.cpuRapi5TempUpdated = true;
            setTimeout(() => {
                this.cpuRapi7TempUpdated = false;
            }, this.flashBackgroundTime);
        });
    }

    getCPUrapi15Temp() {
        this.socketIoService.getJarfallaCPURapi15Temp().subscribe((message: string) => {
            this.cpuRapi15Temp = message;

            // Update square background color when value change.
            //this.cpuRapi15TempUpdated = true;
            setTimeout(() => {
                this.cpuRapi15TempUpdated = false;
            }, this.flashBackgroundTime);
        });
    }

    getCPUrapi16Temp() {
        this.socketIoService.getJarfallaCPURapi16Temp().subscribe((message: string) => {
            this.cpuRapi16Temp = message;

            // Update square background color when value change.
            //this.cpuRapi16TempUpdated = true;
            setTimeout(() => {
                this.cpuRapi16TempUpdated = false;
            }, this.flashBackgroundTime);
        });
    }    

    getCPUrapi17Temp() {
        this.socketIoService.getJarfallaCPURapi17Temp().subscribe((message: string) => {
            this.cpuRapi17Temp = message;

            // Update square background color when value change.
            //this.cpuRapi17TempUpdated = true;
            setTimeout(() => {
                this.cpuRapi17TempUpdated = false;
            }, this.flashBackgroundTime);
        });
    }

    getCPUrapi18Temp() {
        this.socketIoService.getJarfallaCPURapi18Temp().subscribe((message: string) => {
            this.cpuRapi18Temp = message;

            // Update square background color when value change.
            //this.cpuRapi18TempUpdated = true;
            setTimeout(() => {
                this.cpuRapi18TempUpdated = false;
            }, this.flashBackgroundTime);
        });
    }

    getCPUrapi21Temp() {
        this.socketIoService.getJarfallaCPURapi21Temp().subscribe((message: string) => {
            this.cpuRapi21Temp = message;

            // Update square background color when value change.
            //this.cpuRapi21TempUpdated = true;
            setTimeout(() => {
                this.cpuRapi21TempUpdated = false;
            }, this.flashBackgroundTime);
        });
    }

    getCPUrapi22Temp() {
        this.socketIoService.getJarfallaCPURapi22Temp().subscribe((message: string) => {
            this.cpuRapi22Temp = message;

            // Update square background color when value change.
            //this.cpuRapi22TempUpdated = true;
            setTimeout(() => {
                this.cpuRapi22TempUpdated = false;
            }, this.flashBackgroundTime);
        });
    }


    getEvent() {
        this.socketIoService.getEvent().subscribe((message: string) => {

            this.eventLineA17 = this.eventLineA16
            this.eventLineA16 = this.eventLineA15
            this.eventLineA15 = this.eventLineA14
            this.eventLineA14 = this.eventLineA13
            this.eventLineA13 = this.eventLineA12
            this.eventLineA12 = this.eventLineA11
            this.eventLineA11 = this.eventLineA10
            this.eventLineA10 = this.eventLineA9
            this.eventLineA9 = this.eventLineA8
            this.eventLineA8 = this.eventLineA7
            this.eventLineA7 = this.eventLineA6
            this.eventLineA6 = this.eventLineA5
            this.eventLineA5 = this.eventLineA4
            this.eventLineA4 = this.eventLineA3
            this.eventLineA3 = this.eventLineA2
            this.eventLineA2 = this.eventLineA1

            this.eventLineA1 = message;

            // Update square background color when value change.
            //this.cpuRapi22TempUpdated = true;
            // setTimeout(() => {
            //     this.cpuRapi22TempUpdated = false;
            // }, this.flashBackgroundTime);
        });
    }    
}
