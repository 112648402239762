export class SunriseSunset {
    public sunrise: string;
    public sunset: string;
    public dayLength: string;

    constructor(sunrise: string, sunset: string, dayLength: string) { 
        this.sunrise = sunrise;
        this.sunset = sunset;
        this.dayLength = dayLength;
    } 
}