import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sunrise-sunset',
  templateUrl: './sunrise-sunset.component.html',
  styleUrls: ['./sunrise-sunset.component.css']
})
export class SunriseSunsetComponent implements OnInit {

  constructor(private router: Router ) { }

  headers = ["", "Sunrise", "Sunset", "Length of the day"];
  

  rows = [
    {
      "" : "Today",
      "Sunrise" : "08:43",
      "Sunset" : "15:04",
      "Length of the day" : "6:21"
    },
    {
      "" : "Today",
      "Sunrise" : "08:43",
      "Sunset" : "15:04",
      "Length of the day" : "6:21"
    }
  ]

  ngOnInit(): void {
  }

  btnHomeClick() {
    this.router.navigate(['/home']);
  }


}
