<div id="top" style="cursor: pointer;" (click)="btnHomeClick();">
  iPrincip.nu
</div>

<!-- -------------------------------------------------------------- -->
<!-- Row 1                                                          -->
<!-- -------------------------------------------------------------- -->
<div id="squarerow">

  <div class="square">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;" (click)="btnJarfallaClick();">
          <img class="imagers" src="assets/images/jarfalla.png">
        </div>
      </div>
    </div>
  </div>

  <div class="square">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;" (click)="btnTorreviejaClick();">
          <img class="imagers" src="assets/images/torrevieja.png">
        </div>
      </div>
    </div>
  </div>

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

  <div class="square">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;" (click)="btnSystemClick();">
          <img class="imagers" src="assets/images/system/system.png">
        </div>
      </div>
    </div>
  </div>

  <div class="square">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;" (click)="btnForecastClick();">
          <img class="imagers" src="assets/images/forecast/forecast.png">
        </div>
      </div>
    </div>
  </div>

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

  <div class="square">
    <div class="content">
      <div class="table">
        <div class="table-cell">
          <div class="texts16">
            <font color="lightgray">
              <div align="center">{{date | date: "yyyy-MM-dd"}}</div>
            </font>
          </div>
          <br>
          <div class="texts16">
            <font color="lightgray">
              <div align="center">{{date | date: "HH:mm:ss"}}</div>
            </font>
          </div>

        </div>
      </div>
    </div>
  </div>



  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>
</div>

<!-- -------------------------------------------------------------- -->
<!-- Row 2                                                          -->
<!-- -------------------------------------------------------------- -->
<div id="squarerow">
  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

  <div class="square">
    <!-- <div class="application" (click)='openNewTab()'>
      <img class="imgChart " src="assets/images/design/design.png" alt="image 1">
      <div class="title-app">{{'STORE.APPSTAT' | translate}}</div>
      <p>{{'STORE.DESCRIPTIONSTATISTIQUE' | translate}}</p> -->

    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;" (click)="openNewTab()">
          <img class="imagers" src="assets/images/design/design.png">
        </div>
      </div>
    </div>
  </div>
</div>