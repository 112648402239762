import * as io from 'socket.io-client';
import { Observable } from 'rxjs';
import { GlobalConstants } from './common/GlobalConstants ';
//import { * } from 'Globals';
// import * as myGlobals from 'global';


// curl http://localhost:3000/socket.io/?transport=polling
// https://medium.com/@emccul13/socket-io-9bceb00dbf46

export class SocketIoService {

  private url = GlobalConstants.requestUrl + '3000';
  // private url = 'http://192.168.1.222:3000';

  private socket;
  // public tempTest : string;

  constructor() {
    console.log('constructor');
    this.setupSocketConnection();
  }

  setupSocketConnection() {
    console.log('setupSocketConnection');
    this.socket = io(this.url);

    this.socket.on('connect',()=> {
      GlobalConstants.socketId = this.socket.id;
      
      console.log("GlobalConstants.socketId: " + GlobalConstants.socketId);
      });
  }

  requestStartData(category: string) {
    if (category == 'HISTORY') {
      this.socket.emit('clientRequestHistoryStartData');
    }
    else if (category == 'HOUSE') {
      this.socket.emit('clientRequestHouseStartData');
    }
    else if (category == 'TORREVIEJA') {
      this.socket.emit('clientRequestTorreviejaStartData');
    }
    else if (category == 'JARFALLA') {
      this.socket.emit('clientRequestJarfallaStartData');
    }
    else if (category == 'SOLARPOWERSYSTEM') {
      this.socket.emit('clientRequestSolarPowerSystemStartData');
    }   
    else if (category == 'STATIONRECORDS') {
      this.socket.emit('clientRequestStationRecordsStartData');
    }
    else if (category == 'SYSTEM') {
      this.socket.emit('clientRequestSystemStartData');
    }
    
    console.log("emitToServer");
  }

  
  //-----------------------------------------------------------------------------------------------

  public getTorreviejaLivingroomTemp = () => {
    return Observable.create((observer) => {
      this.socket.on('torrevieja/indoor/livingroom/temperature', (data: string) => {
        observer.next(data);
      });
    })
  }

  public getTorreviejaHallTemp = () => {
    return Observable.create((observer) => {
      this.socket.on('torrevieja/indoor/hall/temperature', (data: string) => {
        observer.next(data);
      });
    })
  }


  //-----------------------------------------------------------------------------------------------

  public getJarfallaOutdoorTemp = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/outdoor/air/temperature', (data: string) => {
        observer.next(data);
      });
    })
  }

  public getJarfallaOutdoorWindDirection = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/outdoor/wind/direction', (data: string) => {
        observer.next(data);
      });
    })
  }

  public getJarfallaOutdoorWindAvgSpeed = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/outdoor/wind/windavgspeed', (data: string) => {
        observer.next(data);
      });
    })
  }


  public getJarfallaOutdoorWindMaxSpeedHr = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/outdoor/wind/windmaxspeedhr', (data: string) => {
        observer.next(data);
      });
    })
  }


  public getJarfallaOutdoorWindAvgSpeedHr = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/outdoor/wind/windavgspeedhr', (data: string) => {
        observer.next(data);
      });
    })
  }


  public getJarfallaOutdoorWindGust = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/outdoor/wind/windgustspeed', (data: string) => {
        observer.next(data);
      });
    })
  }




  public getJarfallaOutdoorWindCoolingTemp = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/outdoor/wind/windcoolingtemp', (data: string) => {
        observer.next(data);
      });
    })
  }


  public getJarfallaDewPoint = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/temperature/dewpoint', (data: string) => {
        observer.next(data);
      });
    })
  }

  

  public getJarfallaOutdoorGroundTemp = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/outdoor/ground/temperature', (data: string) => {
        observer.next(data);
      });
    })
  }

  public getJarfallaOutdoorPressure = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/outdoor/pressure', (data) => {
        observer.next(data);
      });
    })
  }


  // Sunlight now.
  public getJarfallaOutdoorSunlightNow = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/outdoor/sunlight/percent/now', (data: string) => {
        observer.next(data);
      });
    })
  }

  // Sunlight current day.
  public getJarfallaOutdoorSunlightCurrentDay = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/outdoor/sunlight/percent/currentday', (data: string) => {
        observer.next(data);
      });
    })
  }


  public getJarfallaOutdoorGreenhouseTemp = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/outdoor/greenhouse/temperature', (data: string) => {
        observer.next(data);
      });
    })
  }

  public getJarfallaOutdoorCompost1Temp = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/outdoor/compost1/temperature', (data: string) => {
        observer.next(data);
      });
    })
  }

  public getJarfallaOutdoorCompost2Temp = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/outdoor/compost2/temperature', (data: string) => {
        observer.next(data);
      });
    })
  }

  public getJarfallaOutdoorHumidity = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/outdoor/humidity', (data: string) => {
        observer.next(data);
      });
    })
  }

  public getJarfallaOutdoorRainAmountLast24Hr = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/outdoor/rainamountlast24hr', (data: string) => {
        observer.next(data);
      });
    })
  }


  public getJarfallOutdoorDaysSinceLastRain = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/outdoor/dayssincelastrain', (data: string) => {
        observer.next(data);
      });
    })
  }


  public getJarfallaOutdoorSnowDepth = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/outdoor/snowdepth', (data: string) => {
        observer.next(data);
      });
    })
  }

  public getJarfallaOutdoorUVradiation = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/outdoor/uvmeater/uvradiation', (data: string) => {
        observer.next(data);
      });
    })
  }

  public getJarfallaOutdoorDaysSinceRain = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/outdoor/dayssincerain', (data: string) => {
        observer.next(data);
      });
    })
  }

  public getJarfallaOutdoorCanAirHeaterData = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/outdoor/solarcanairheater', (data) => {
        // console.log("Data: " + data);
        observer.next(data);
      });
    })
  }

  
  //===============================================================================================
  // Panels Data.
  //===============================================================================================

  public getJarfallaSolarVoltageData = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/solarpowersystem/solarpanel/solarvoltage', (data: string) => {
        console.log("Data: " + data);
        observer.next(data);
      });
    })
  }

  public getJarfallaSolarCurrentData = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/solarpowersystem/solarpanel/solarcurrent', (data: string) => {
        console.log("Data: " + data);
        observer.next(data);
      });
    })
  }

  public getJarfallaSolarPowerLData = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/solarpowersystem/solarpanel/solarpowerl', (data: string) => {
        // console.log("Data: " + data);
        observer.next(data);
      });
    })
  }

  public getJarfallaSolarPowerHData = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/solarpowersystem/solarpanel/solarpowerh', (data: string) => {
        // console.log("Data: " + data);
        observer.next(data);
      });
    })
  }

  public getJarfallaSolarVoltageMaxTodayData = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/solarpowersystem/solarpanel/solarvoltagemaxtoday', (data: string) => {
        // console.log("Data: " + data);
        observer.next(data);
      });
    })
  }

  public getJarfallaSolarVoltageMinTodayData = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/solarpowersystem/solarpanel/solarvoltagemintoday', (data: string) => {
        // console.log("Data: " + data);
        observer.next(data);
      });
    })
  }


  //===============================================================================================
  // Battery Data.
  //===============================================================================================

  public getJarfallaBatterySOCData = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/solarpowersystem/battery/soc', (data: string) => {
        // console.log("Data: " + data);
        observer.next(data);
      });
    })
  }

  
  public getJarfallaBatteryVoltageData = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/solarpowersystem/battery/voltage', (data: string) => {
        // console.log("Data: " + data);
        observer.next(data);
      });
    })
  }

  
  public getJarfallaBatteryChargeCurrentData = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/solarpowersystem/battery/chargecurrent', (data: string) => {
        // console.log("Data: " + data);
        observer.next(data);
      });
    })
  }

  public getJarfallaBatteryTemperatureData = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/solarpowersystem/battery/temperature', (data: string) => {
        // console.log("Data: " + data);
        observer.next(data);
      });
    })
  }


  public getJarfallaBatteryChargePowerLData = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/solarpowersystem/battery/chargepowerl', (data: string) => {
        // console.log("Data: " + data);
        observer.next(data);
      });
    })
  }


  public getJarfallaBatteryChargePowerHData = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/solarpowersystem/battery/chargepowerh', (data: string) => {
        // console.log("Data: " + data);
        observer.next(data);
      });
    })
  }


  public getJarfallaBatteryRemoteTemperatureData = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/solarpowersystem/battery/remotetemperaturel', (data: string) => {
        // console.log("Data: " + data);
        observer.next(data);
      });
    })
  }


  public getJarfallaBatteryStatusData = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/solarpowersystem/battery/status', (data: string) => {
        // console.log("Data: " + data);
        observer.next(data);
      });
    })
  }


  public getJarfallaBatteryVoltageMaxTodayData = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/solarpowersystem/battery/voltagemaxtoday', (data: string) => {
        // console.log("Data: " + data);
        observer.next(data);
      });
    })
  }


  public getJarfallaBatteryVoltageMinTodayData = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/solarpowersystem/battery/voltagemintoday', (data: string) => {
        // console.log("Data: " + data);
        observer.next(data);
      });
    })
  }


  //===============================================================================================
  // Solar ChargeController Data.
  //===============================================================================================

  public getJarfallaSolarChargeControllerDischargingOutputVoltageData = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/solarpowersystem/solarchargecontroller/dischargingoutputvoltage', (data: string) => {
        // console.log("Data: " + data);
        observer.next(data);
      });
    })
  }

  public getJarfallaSolarChargeControllerDischargingOutputCurrentData = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/solarpowersystem/solarchargecontroller/dischargingoutputcurrent', (data: string) => {
        // console.log("Data: " + data);
        observer.next(data);
      });
    })
  }

  public getJarfallaSolarChargeControllerDischargingOutputPowerLData = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/solarpowersystem/solarchargecontroller/dischargingoutputpowerl', (data: string) => {
        // console.log("Data: " + data);
        observer.next(data);
      });
    })
  }

  public getJarfallaSolarChargeControllerDischargingOutputPowerHData = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/solarpowersystem/solarchargecontroller/dischargingoutputpowerh', (data: string) => {
        // console.log("Data: " + data);
        observer.next(data);
      });
    })
  }

  public getJarfallaSolarChargeControllerTemperatureInsideCaseData = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/solarpowersystem/solarchargecontroller/temperatureinsidecase', (data: string) => {
        // console.log("Data: " + data);
        observer.next(data);
      });
    })
  }


  public getJarfallaSolarChargeControllerTemperaturePowerComponentsData = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/solarpowersystem/solarchargecontroller/temperaturepowercomponents', (data: string) => {
        // console.log("Data: " + data);
        observer.next(data);
      });
    })
  }


  public getJarfallaSolarChargeControllerCurrentSystemRatedVoltageData = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/solarpowersystem/solarchargecontroller/currentsystemratedvoltage', (data: string) => {
        //console.log("Data: " + data);
        observer.next(data);
      });
    })
  }


  //-----------------------------------------------------------------------------------------------

  public getJarfallaHallTemp = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/indoor/hall/temperature', (data: string) => {
        observer.next(data);
      });
    })
  }


  public getJarfallaKitchenTemp = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/indoor/kitchen/temperature', (data: string) => {
        observer.next(data);
      });
    })
  }
  

  public getJarfallaLivingroomTemp = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/indoor/livingroom/temperature', (data: string) => {
        observer.next(data);
      });
    })
  }

  public getJarfallaBedroomTemp = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/indoor/bedroom/temperature', (data: string) => {
        observer.next(data);
      });
    })
  }

  public getJarfallaBedroom1Temp = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/indoor/bedroom1/temperature', (data: string) => {
        observer.next(data);
      });
    })
  }

  public getJarfallaOfficeTemp = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/indoor/office/temperature', (data: string) => {
        observer.next(data);
      });
    })
  }

  public getJarfallaLabTemp = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/indoor/lab/temperature', (data: string) => {
        observer.next(data);
      });
    })
  }


  public getJarfallaLivingroomHumidity = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/indoor/livingroom/humidity', (data: string) => {
        observer.next(data);
      });
    })
  }


  public getJarfallaBedroomHumidity = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/indoor/bedroom/humidity', (data: string) => {
        observer.next(data);
      });
    })
  }


  public getJarfallaOfficeHumidity = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/indoor/office/humidity', (data: string) => {
        observer.next(data);
      });
    })
  }

  
  public getJarfallaLabHumidity = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/indoor/lab/humidity', (data: string) => {
        observer.next(data);
      });
    })
  }

  public getJarfallaEnergyHouse = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/energy/house', (data: string) => {
        observer.next(data);
      });
    })
  }

  public getJarfallaEnergyHouseHour = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/energy/househour', (data: string) => {
        observer.next(data);
      });
    })
  }

  public getJarfallaEnergyHouseCostPriviousHour = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/energy/housecostprivioushour', (data: string) => {
        observer.next(data);
      });
    })
  }

  

  public getMoonPhaseVisibility = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/moonphase/visibility', (data: string) => {
        observer.next(data);
      });
    })
  }



  public getJarfallaKitchenDoorOpenClosedSensor = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/indoor/kitchen/doorsensor/openclosed', (data: string) => {
        observer.next(data);
      });
    })
  }
  
  public getJarfallaKitchenDoorBatteryLevelSensor = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/indoor/kitchen/doorsensor/batterylevel', (data: string) => {
        observer.next(data);
      });
    })
  }



  public getJarfallaLivingroomDoorOpenClosedSensor = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/indoor/livingroom/doorsensor/openclosed', (data: string) => {
        observer.next(data);
      });
    })
  }
  
  public getJarfallaLivingroomDoorBatteryLevelSensor = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/indoor/livingroom/doorsensor/batterylevel', (data: string) => {
        observer.next(data);
      });
    })
  }





  public getJarfallaKitchenWindowOpenClosedSensor = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/indoor/kitchen/windowsensor/openclosed', (data: string) => {
        observer.next(data);
      });
    })
  }


  public getJarfallaKitchenWindowBatteryLevelSensor = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/indoor/kitchen/windowsensor/batterylevel', (data: string) => {
        observer.next(data);
      });
    })
  }





  public getJarfallaBedroomWindowOpenClosedSensor = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/indoor/bedroom/windowsensor/openclosed', (data: string) => {
        observer.next(data);
      });
    })
  }


  public getJarfallaBedroomWindowBatteryLevelSensor = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/indoor/bedroom/windowsensor/batterylevel', (data: string) => {
        observer.next(data);
      });
    })
  }




  public getJarfallaBedroom1WindowOpenClosedSensor = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/indoor/bedroom1/windowsensor/openclosed', (data: string) => {
        observer.next(data);
      });
    })
  }



  public getJarfallaBedroom1WindowBatteryLevelSensor = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/indoor/bedroom1/windowsensor/batterylevel', (data: string) => {
        observer.next(data);
      });
    })
  }



  public getJarfallaBedroomBlinds = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/indoor/bedroom/blinds', (data: string) => {
        observer.next(data);
      });
    })
  }

  public getJarfallaBedroom1Blinds = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/indoor/bedroom1/blinds', (data: string) => {
        observer.next(data);
      });
    })
  }

  public getJarfallaCPURapi2Temp = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/temperature/cpu/rapi2', (data: string) => {
        observer.next(data);
      });
    })
  }

  public getJarfallaCPURapi5Temp = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/temperature/cpu/rapi5', (data: string) => {
        observer.next(data);
      });
    })
  }

  public getJarfallaCPURapi7Temp = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/temperature/cpu/rapi7', (data: string) => {
        observer.next(data);
      });
    })
  }

  public getJarfallaCPURapi15Temp = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/temperature/cpu/rapi15', (data: string) => {
        observer.next(data);
      });
    })
  }

  public getJarfallaCPURapi16Temp = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/temperature/cpu/rapi16', (data: string) => {
        observer.next(data);
      });
    })
  }

  public getJarfallaCPURapi17Temp = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/temperature/cpu/rapi17', (data: string) => {
        observer.next(data);
      });
    })
  }

  public getJarfallaCPURapi18Temp = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/temperature/cpu/rapi18', (data: string) => {
        observer.next(data);
      });
    })
  }

  public getJarfallaCPURapi21Temp = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/temperature/cpu/rapi21', (data: string) => {
        observer.next(data);
      });
    })
  }

  public getJarfallaCPURapi22Temp = () => {
    return Observable.create((observer) => {
      this.socket.on('jarfalla/temperature/cpu/rapi22', (data: string) => {
        observer.next(data);
      });
    })
  }

  public getEvent = () => {
    return Observable.create((observer) => {

      // Fungerar?
      this.socket.on('sensor/data/motiondetector/outdoor', (data: string) => {
        observer.next(new Date().toLocaleTimeString() + " - Motiondetector: " + data);
      });

      //OK
      this.socket.on('jarfalla/indoor/hall/temperature', (data: string) => {
        observer.next(new Date().toLocaleTimeString() + " - Hall temp: " + data + " \xB0C");
      });
      
      //OK
      this.socket.on('jarfalla/indoor/livingroom/temperature', (data: string) => {
        observer.next(new Date().toLocaleTimeString() + " - Livingroom temp: " + data + " \xB0C");
      });

      //OK
      this.socket.on('jarfalla/indoor/bedroom/temperature', (data: string) => {
        observer.next(new Date().toLocaleTimeString() + " - Bedroom temp: " + data + " \xB0C");
      });
  
      //OK
      this.socket.on('jarfalla/indoor/bedroom1/temperature', (data: string) => {
        observer.next(new Date().toLocaleTimeString() + " - Bedroom1 temp: " + data + " \xB0C");
      });
      
      //OK
      // this.socket.on('jarfalla/indoor/office/temperature', (data: string) => {
      //   observer.next(new Date().toLocaleTimeString() + " - Office temp: " + data + " \xB0C");
      // });
      
   
      // this.socket.on('jarfalla/indoor/lab/temperature', (data: string) => {
      //   observer.next(new Date().toLocaleTimeString() + " - Lab temp: " + data + " \xB0C");
      // });

 
      // this.socket.on('jarfalla/indoor/livingroom/humidity', (data: string) => {
      //   observer.next(new Date().toLocaleTimeString() + " - Livingroom humidity: " + data + "%");
      // });
      
  
      // this.socket.on('jarfalla/indoor/bedroom/humidity', (data: string) => {
      //   observer.next(new Date().toLocaleTimeString() + " - Bedroom humidity: " + data + "%");
      // });
    

      // this.socket.on('jarfalla/indoor/office/humidity', (data: string) => {
      //   observer.next(new Date().toLocaleTimeString() + " - Office humidity: " + data + "%");
      // });
      

      // this.socket.on('jarfalla/indoor/lab/humidity', (data: string) => {
      //   observer.next(new Date().toLocaleTimeString() + " - Lab humidity: " + data + "%");
      // });

      // Fungerar?
      // this.socket.on('torrevieja/indoor/livingroom/temperature/event', (data: string) => {
      this.socket.on('torrevieja/indoor/livingroom/temperature', (data: string) => {
        observer.next(new Date().toLocaleTimeString() + " - Spain livingroom temp: " + data + " \xB0C");
      });

      // Fungerar?
      // this.socket.on('torrevieja/indoor/hall/temperature/event', (data: string) => {
      this.socket.on('torrevieja/indoor/hall/temperature', (data: string) => {
        observer.next(new Date().toLocaleTimeString() + " - Spain hall temp: " + data + " \xB0C");
      });

      //NOK
      // this.socket.on('jarfalla/outdoor/airsensor1/temperature', (data: string) => {
      //   observer.next(new Date().toLocaleTimeString() + " - Outdoor1 temp: " + data + " \xB0C");
      // });
      
      //NOK
      // this.socket.on('jarfalla/outdoor/airsensor2/temperature', (data: string) => {
      //   observer.next(new Date().toLocaleTimeString() + " - Outdoor2 temp: " + data + " \xB0C");
      // });

      //OK
      this.socket.on('jarfalla/outdoor/greenhouse/temperature', (data: string) => {
        observer.next(new Date().toLocaleTimeString() + " - Greenhouse temp: " + data + " \xB0C");
      });

      //NOK
      // this.socket.on('jarfalla/outdoor/ground/temperature/event', (data: string) => {
      this.socket.on('jarfalla/outdoor/ground/temperature', (data: string) => {
        observer.next(new Date().toLocaleTimeString() + " - Ground temp: " + data + " \xB0C");
      });

      //NOK
      // this.socket.on('jarfalla/outdoor/compost1/temperature/event', (data: string) => {
      this.socket.on('jarfalla/outdoor/compost1/temperature', (data: string) => {
        observer.next(new Date().toLocaleTimeString() + " - Compost1 temp: " + data + " \xB0C");
      });

      //NOK
      // this.socket.on('jarfalla/outdoor/compost2/temperature/event', (data: string) => {
      this.socket.on('jarfalla/outdoor/compost2/temperature', (data: string) => {
        observer.next(new Date().toLocaleTimeString() + " - Compost2 temp: " + data + " \xB0C");
      });

      //NOK
      // this.socket.on('jarfalla/outdoor/uvmeater/uvradiation/event', (data: string) => {
      //   observer.next(new Date().toLocaleTimeString() + " - UV Radiation: " + data);
      // });

      //NOK
      // this.socket.on('jarfalla/outdoor/snowdepth', (data: string) => {
      //   observer.next(new Date().toLocaleTimeString() + " - Snowdepth: " + data + " cm");
      // });


      // OK Nedan.

      // this.socket.on('jarfalla/indoor/lab/temperature', (data: string) => {
      //   observer.next(new Date().toLocaleTimeString() + " - Lab temp: " + data + " \xB0C");
      // });

      // this.socket.on('jarfalla/outdoor/air/temperature', (data: string) => {
      //   observer.next(new Date().toLocaleTimeString() + " - Outdoor temp: " + data + " \xB0C");
      // });

      // this.socket.on('jarfalla/temperature/dewpoint', (data: string) => {
      //   observer.next(new Date().toLocaleTimeString() + " - Dew Point: " + data + " \xB0C");
      // });

      // this.socket.on('jarfalla/outdoor/solarcanairheater', (data: any) => {
      //   observer.next(new Date().toLocaleTimeString() + " - Solarcanairheater temp: " + data.temperature + " \xB0C");
      // });

      // this.socket.on('jarfalla/outdoor/solarcanairheater', (data: any) => {
      //   observer.next(new Date().toLocaleTimeString() + " - Solarcanairheater status: " + data.status);
      // });

      // this.socket.on('jarfalla/outdoor/wind/direction', (data: string) => {
      //   observer.next(new Date().toLocaleTimeString() + " - Wind direction: " + data + " Degree");
      // });

      // this.socket.on('jarfalla/outdoor/wind/windavgspeed', (data: string) => {
      //   observer.next(new Date().toLocaleTimeString() + " - Wind Avg Speed: " + data + " m/s");
      // });

      // this.socket.on('jarfalla/outdoor/wind/windcoolingtemp', (data: string) => {
      //   observer.next(new Date().toLocaleTimeString() + " - Wind cooling temp: " + data + " m/s");
      // });

      // this.socket.on('jarfalla/outdoor/wind/windmaxspeedhr', (data: string) => {
      //   observer.next(new Date().toLocaleTimeString() + " - Wind speed max, last hr: " + data + " m/s");
      // });

      // this.socket.on('jarfalla/outdoor/wind/windavgspeedhr', (data: string) => {
      //   observer.next(new Date().toLocaleTimeString() + " - Wind speed avg, last hr: " + data + " m/s");
      // });

      // this.socket.on('jarfalla/outdoor/pressure', (data : any) => {  
      //   observer.next(new Date().toLocaleTimeString() + " - Pressure: " + data.pressure + " hPa");
      // });

      // this.socket.on('jarfalla/outdoor/humidity', (data: string) => {
      //   observer.next(new Date().toLocaleTimeString() + " - Humidity outdoor: " + data + "%");
      // });

      // this.socket.on('jarfalla/indoor/lab/humidity', (data: string) => {
      //   observer.next(new Date().toLocaleTimeString() + " - Humidity Lab: " + data + "%");
      // });

      // this.socket.on('jarfalla/outdoor/sunlight/percent/now', (data: string) => {
      //   observer.next(new Date().toLocaleTimeString() + " - Sunlight now: " + data + "%");
      // });

      // this.socket.on('jarfalla/outdoor/sunlight/percent/currentday', (data: string) => {
      //   observer.next(new Date().toLocaleTimeString() + " - Sunlight currentday: " + data + "%");
      // });

      // this.socket.on('jarfalla/outdoor/dayssincelastrain', (data: string) => {
      //   observer.next(new Date().toLocaleTimeString() + " - Days since last rain: " + data);
      // });

      // this.socket.on('jarfalla/outdoor/rainamountlast24hr', (data: string) => {
      //   observer.next(new Date().toLocaleTimeString() + " - Rain amount last 24hr: " + data + "mm");
      // });

      // this.socket.on('jarfalla/temperature/cpu/rapi2', (data: string) => {
      //   observer.next(new Date().toLocaleTimeString() + " - rapi 2 temperature: " + data + " \xB0C");
      // });

      // this.socket.on('jarfalla/temperature/cpu/rapi5', (data: string) => {
      //   observer.next(new Date().toLocaleTimeString() + " - rapi 5 temperature: " + data + " \xB0C");
      // });

      // this.socket.on('jarfalla/temperature/cpu/rapi7', (data: string) => {
      //   observer.next(new Date().toLocaleTimeString() + " - rapi 7 temperature: " + data + " \xB0C");
      // });
 
      // this.socket.on('jarfalla/temperature/cpu/rapi15', (data: string) => {
      //   observer.next(new Date().toLocaleTimeString() + " - rapi 15 temperature: " + data + " \xB0C");
      // });

      // this.socket.on('jarfalla/temperature/cpu/rapi16', (data: string) => {
      //   observer.next(new Date().toLocaleTimeString() + " - rapi 16 temperature: " + data + " \xB0C");
      // });

      // this.socket.on('jarfalla/temperature/cpu/rapi17', (data: string) => {
      //   observer.next(new Date().toLocaleTimeString() + " - rapi 17 temperature: " + data + " \xB0C");
      // });

      // this.socket.on('jarfalla/temperature/cpu/rapi18', (data: string) => {
      //   observer.next(new Date().toLocaleTimeString() + " - rapi 18 temperature: " + data + " \xB0C");
      // });

      // this.socket.on('jarfalla/temperature/cpu/rapi21', (data: string) => {
      //   observer.next(new Date().toLocaleTimeString() + " - rapi 21 temperature: " + data + " \xB0C");
      // });

      // this.socket.on('jarfalla/temperature/cpu/rapi22', (data: string) => {
      //   observer.next(new Date().toLocaleTimeString() + " - rapi 22 temperature: " + data + " \xB0C");
      // });
    })
  }
}