import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SocketIoService } from '../socketIo.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-moonphase',
  templateUrl: './moonphase.component.html',
  styleUrls: ['./moonphase.component.css']
})
export class MoonphaseComponent implements OnInit {

  constructor(private router: Router, private http: HttpClient, private socketIoService: SocketIoService) { }

  ngOnInit(): void {
  }


  btnHomeClick() {
    this.router.navigate(['/home']);
  }

  btnJarfallaClick() {
    this.router.navigate(['/jarfalla']);
  }
}

