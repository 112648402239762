<div id="top" style="cursor: pointer;" (click)="btnHomeClick();">
    iPrincip.nu
</div>

<!-- -------------------------------------------------------------- -->
<!-- Row 1                                                          -->
<!-- -------------------------------------------------------------- -->
<div id="squarerow">

    <div [className]="livingroomTempUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;"
                    (click)="btnGraphTemperatureClick('SpainLivingroom', '1D', 'Living room temperature during the past day');">

                    <div class="texts16">
                        <font color="lightgray">
                            <div align="center">Living room</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts18">
                        <font color="yellow">
                            <div align="center">{{ indoorLivingroomTemp }} &deg;C</div>
                        </font>
                    </div>

                </div>
            </div>
        </div>
    </div>


    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>


    <!-- http://api.sunrise-sunset.org/json?lat=37.97872&lng=-0.68222&date=20201117&formatted=0 -->


    <div class="square">
        <div class="content">
            <div class="table" style="cursor: pointer;" (click)="btnSunriseSunsetClick();">
                <div *ngIf="sunriseSunset" class="table-cell texts12">
                    <font color="lightblue">
                        <br>Sunrise: {{ sunriseSunset.sunrise }}
                    </font>
                    <font color="lightblue">
                        <br>Sunset: {{ sunriseSunset.sunset }}
                    </font>
                    <font color="lightblue">
                        <br>Length: {{ sunriseSunset.dayLength }}
                    </font>
                </div>
            </div>
        </div>
    </div>


    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>


    <div class="square">
        <div class="content">
            <a href="https://www.google.se/maps/place/Torrevieja,+Alicante,+Spanien/@37.9923198,-0.697434,13.75z/data=!4m5!3m4!1s0xd63aa38720ffc79:0xd220b907f2229dc6!8m2!3d37.984697!4d-0.680809"
                target="_blank">
                <div class="table">
                    <img class="imagers" src="assets/images/position/position.png">
                    <br>
                    <div class="texts18">
                        <font color="lightgray">
                            Position
                        </font>
                    </div>
                </div>
            </a>
        </div>
    </div>



    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>
</div>

<!-- -------------------------------------------------------------- -->
<!-- Row 2                                                          -->
<!-- -------------------------------------------------------------- -->
<div id="squarerow">
    <div [className]="hallTempUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;"
                    (click)="btnGraphTemperatureClick('SpainHall', '1D', 'Hall temperature during the past day');">

                    <div class="texts16">
                        <font color="lightgray">
                            <div align="center">Hall</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts18">
                        <font color="yellow">
                            <div align="center">{{ indoorHallTemp }} &deg;C</div>
                        </font>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="square">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;" (click)="btnHomeClick();">
                    <img class="imagers" src="assets/images/back.png">
                </div>
            </div>
        </div>
    </div>
</div>