import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { SocketIoService } from '../socketIo.service';
import { temperatureData } from '../data/temperatureData';
import { humidityData } from '../data/humidityData';
import { pressureData } from '../data/pressureData';
import { sunlightData } from '../data/sunlightData';
import { rainData } from '../data/rainData';
import { GlobalConstants } from '../common/GlobalConstants ';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  // styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {
  // private requestUrlJarfallaOutdoorTemperatureDay: string = 'http://192.168.1.222:3333/jarfalla/temperature';
  // private requestUrlJarfallaOutdoorHumidityDay: string = 'http://192.168.1.222:3333/jarfalla/humidity';
  // private requestUrlJarfallaPressure: string = 'http://192.168.1.222:3333/jarfalla/pressure';
  // private requestUrlJarfallaSunlight: string = 'http://192.168.1.222:3333/jarfalla/sunlight';
  // private requestUrlJarfallaRain: string = 'http://192.168.1.222:3333/jarfalla/rain';

  private requestUrlJarfallaOutdoorTemperatureDay: string = GlobalConstants.requestUrl + '3333/jarfalla/temperature';
  private requestUrlJarfallaOutdoorHumidityDay: string = GlobalConstants.requestUrl + '3333/jarfalla/humidity';
  private requestUrlJarfallaPressure: string = GlobalConstants.requestUrl + '3333/jarfalla/pressure';
  private requestUrlJarfallaSunlight: string = GlobalConstants.requestUrl + '3333/jarfalla/sunlight';
  private requestUrlJarfallaRain: string = GlobalConstants.requestUrl + '3333/jarfalla/rain';


  public outdoorTemp: string;
  public groundTemp: string;
  public outdoorHumidity: string;
  public rainAmountLast24Hr: string;
  public pressure: string;
  public pressureImage: string;

  public sunlightNow: string;
  public sunlightImage: string = "assets/images/sun/sun_0.png";
  public sunlightNowUpdated: boolean = false;

  public uvradiation: string;
  public risk: string;
  public mediaGraphicColor: string;
  public uvIndexImage: string;

  public outdoorTempUpdated: boolean = false;
  public groundTempUpdated: boolean = false;
  public outdoorHumidityUpdated: boolean = false;
  public rainAmountLast24HrUpdated: boolean = false;
  public pressureUpdated: boolean = false;

  public uvradiationUpdated: boolean = false;

  private readonly flashBackgroundTime: number = 300;


  constructor(private router: Router, private http: HttpClient, private socketIoService: SocketIoService) {
  }

  ngOnInit(): void {
    this.getOutdoorTemp();
    this.getGroundTemp();
    this.getHumidity();
    this.getRainAmountLast24Hr();
    this.getPressure();
    this.getSunlightNow();
    this.getUVradiation();

    // this.getJarfallaOutdoorTemperatureDay();

    this.socketIoService.requestStartData('HISTORY');
  }



  btnHomeClick() {
    this.router.navigate(['/home']);
  }

  btnJarfallaClick() {
    this.router.navigate(['/jarfalla']);
  };


  btnGraphTemperatureClick(zone: string, timespan: string, formTitle: string) {
    let temperatureData = [];

    this.http
      .get<Array<temperatureData>>(this.requestUrlJarfallaOutdoorTemperatureDay, {
        params: { timespan: timespan, zone: zone }, observe: 'response'
      })
      .subscribe(resp => {

        var i: number;
        for (i = 0; i < resp.body['temperatureDay'].length; i++) {

          let date = JSON.stringify(resp.body['temperatureDay'][i][0]).substr(1, 10) + " . " + JSON.stringify(resp.body['temperatureDay'][i][0]).substr(12, 5);
          let temp = resp.body['temperatureDay'][i][1];

          temperatureData.push([date, temp]);
        }

        this.router.navigate(['/graphtemperature'], { state: { graphData: temperatureData, formTitle: formTitle } });
      });
  }


  btnGraphHumidityClick(zone: string, timespan: string, formTitle: string) {
    this.http
      .get<Array<humidityData>>(this.requestUrlJarfallaOutdoorHumidityDay, {
        params: { timespan: timespan, zone: zone }, observe: 'response'
      })
      .subscribe(resp => {

        let humidityData = [];

        var i: number;
        for (i = 0; i < resp.body['humidityDay'].length; i++) {

          let date = JSON.stringify(resp.body['humidityDay'][i][0]).substr(1, 10) + " . " + JSON.stringify(resp.body['humidityDay'][i][0]).substr(12, 5);
          let humidity = resp.body['humidityDay'][i][1];

          humidityData.push([date, humidity]);
        }

        this.router.navigate(['/graphhumidity'], { state: { graphData: humidityData, formTitle: formTitle } });
      });
  }


  btnGraphRainClick(zone: string, numberOfDays: string, formTitle: string) {
    this.http
      .get<Array<rainData>>(this.requestUrlJarfallaRain, {
        params: { numberofdays: numberOfDays, zone: zone }, observe: 'response'
      })
      .subscribe(resp => {

        let rainData = [];

        var i: number;
        for (i = 0; i < resp.body['rainDay'].length; i++) {

          let date = JSON.stringify(resp.body['rainDay'][i][0]).substr(1, 10) + " . " + JSON.stringify(resp.body['rainDay'][i][0]).substr(12, 5);
          let rain = resp.body['rainDay'][i][1];

          rainData.push([date, rain]);
        }

        this.router.navigate(['/graphrain'], { state: { graphData: rainData, formTitle: formTitle } });
      });
  }


  btnGraphPressureClick(zone: string, timespan: string, formTitle: string) {  
    this.http
      .get<Array<pressureData>>(this.requestUrlJarfallaPressure, {
        params: { timespan: timespan, zone: zone }, observe: 'response'
      })
      .subscribe(resp => {

        let pressureData = [];

        var i: number;
        for (i = 0; i < resp.body['pressureDay'].length; i++) {

          let date = JSON.stringify(resp.body['pressureDay'][i][0]).substr(1, 10) + " . " + JSON.stringify(resp.body['pressureDay'][i][0]).substr(12, 5);
          let pressure = resp.body['pressureDay'][i][1];

          pressureData.push([date, pressure]);
        }

        this.router.navigate(['/graphpressure'], { state: { graphData: pressureData, formTitle: formTitle } });
      });
  }


  btnGraphSunlightClick(zone: string, timespan: string, formTitle: string) {  
    this.http
      .get<Array<sunlightData>>(this.requestUrlJarfallaSunlight, {
        params: { timespan: timespan, zone: zone }, observe: 'response'
      })
      .subscribe(resp => {

        let sunlightData = [];

        var i: number;
        for (i = 0; i < resp.body['sunlightDay'].length; i++) {

          let date = JSON.stringify(resp.body['sunlightDay'][i][0]).substr(1, 10) + " . " + JSON.stringify(resp.body['sunlightDay'][i][0]).substr(12, 5);
          let sunlight = resp.body['sunlightDay'][i][1];

          sunlightData.push([date, sunlight]);
        }

        this.router.navigate(['/graphsunlight'], { state: { graphData: sunlightData, formTitle: formTitle } });
      });
  }


  getOutdoorTemp() {
    this.socketIoService.getJarfallaOutdoorTemp().subscribe((message: string) => {
      this.outdoorTemp = message;

      // Update square background color when value change.
      this.outdoorTempUpdated = true;
      setTimeout(() => {
        this.outdoorTempUpdated = false;
      }, this.flashBackgroundTime);
    });
  }


  getGroundTemp() {
    this.socketIoService.getJarfallaOutdoorGroundTemp().subscribe((message: string) => {
      this.groundTemp = message;

      // Update square background color when value change.
      this.groundTempUpdated = true;
      setTimeout(() => {
        this.groundTempUpdated = false;
      }, this.flashBackgroundTime);
    });
  }


  getHumidity() {
    this.socketIoService.getJarfallaOutdoorHumidity().subscribe((message: string) => {
      this.outdoorHumidity = message;

      // Update square background color when value change.
      this.outdoorHumidityUpdated = true;
      setTimeout(() => {
        this.outdoorHumidityUpdated = false;
      }, this.flashBackgroundTime);
    });
  }


  getRainAmountLast24Hr() {
    this.socketIoService.getJarfallaOutdoorRainAmountLast24Hr().subscribe((message: string) => {
      this.rainAmountLast24Hr = message;

      // Update square background color when value change.
      this.rainAmountLast24HrUpdated = true;
      setTimeout(() => {
        this.rainAmountLast24HrUpdated = false;
      }, this.flashBackgroundTime);
    });
  }


  getPressure() {
    this.socketIoService.getJarfallaOutdoorPressure().subscribe((data: any) => {
      this.pressure = data.pressure;

      if (data.highlow == 'high') { // 1016.9 : 1013 + 3,9 (höjden 31 meter).
        this.pressureImage = "assets/images/pressure/pressure_h.png";
      }
      else {
        this.pressureImage = "assets/images/pressure/pressure_l.png";
      }

      // Update square background color when value change.
      this.pressureUpdated = true;
      setTimeout(() => {
        this.pressureUpdated = false;
      }, this.flashBackgroundTime);
    });
  }


  getSunlightNow() {
    this.socketIoService.getJarfallaOutdoorSunlightNow().subscribe((message: string) => {
      this.sunlightNow = message;

      let sunliteInt = parseInt(this.sunlightNow);

      if (sunliteInt >= 90) {
        this.sunlightImage = "assets/images/sun/sun_100.png";
      }
      else if (sunliteInt >= 75) {
        this.sunlightImage = "assets/images/sun/sun_80.png";
      }
      else if (sunliteInt >= 45) {
        this.sunlightImage = "assets/images/sun/sun_50.png";
      }
      else if (sunliteInt >= 20) {
        this.sunlightImage = "assets/images/sun/sun_20.png";
      }
      else if (sunliteInt >= 10) {
        this.sunlightImage = "assets/images/sun/sun_10.png";
      }
      else {
        this.sunlightImage = "assets/images/sun/sun_0.png";
      }

      // Update square background color when value change.
      this.sunlightNowUpdated = true;
      setTimeout(() => {
        this.sunlightNowUpdated = false;
      }, this.flashBackgroundTime);
    });
  }

  getUVradiation() {
    this.socketIoService.getJarfallaOutdoorUVradiation().subscribe((data: any) => {
      this.uvradiation = data.uvradiation;
      this.mediaGraphicColor = data.mediaGraphicColor;
      this.risk = data.risk;

      if (parseInt(this.uvradiation) >= 11.0) {
        this.uvIndexImage = "assets/images/uvindex/11uvindex.png";
      }
      else if (parseInt(this.uvradiation) >= 10.0) {
        this.uvIndexImage = "assets/images/uvindex/10uvindex.png";
      }
      else if (parseInt(this.uvradiation) >= 9.0) {
        this.uvIndexImage = "assets/images/uvindex/9uvindex.png";
      }
      else if (parseInt(this.uvradiation) >= 8.0) {
        this.uvIndexImage = "assets/images/uvindex/8uvindex.png";
      }
      else if (parseInt(this.uvradiation) >= 7.0) {
        this.uvIndexImage = "assets/images/uvindex/7uvindex.png";
      }
      else if (parseInt(this.uvradiation) >= 6.0) {
        this.uvIndexImage = "assets/images/uvindex/6uvindex.png";
      }
      else if (parseInt(this.uvradiation) >= 5.0) {
        this.uvIndexImage = "assets/images/uvindex/5uvindex.png";
      }
      else if (parseInt(this.uvradiation) >= 4.0) {
        this.uvIndexImage = "assets/images/uvindex/4uvindex.png";
      }
      else if (parseInt(this.uvradiation) >= 3.0) {
        this.uvIndexImage = "assets/images/uvindex/3uvindex.png";
      }
      else if (parseInt(this.uvradiation) >= 2.0) {
        this.uvIndexImage = "assets/images/uvindex/2uvindex.png";
      }
      else if (parseInt(this.uvradiation) >= 1.0) {
        this.uvIndexImage = "assets/images/uvindex/1uvindex.png";
      }
      else if (parseInt(this.uvradiation) >= 0.0) {
        this.uvIndexImage = "assets/images/uvindex/0uvindex.png";
      }
      else {
        this.uvIndexImage = "assets/images/uvindex/0uvindex.png";
      }

      // Update square background color when value change.
      this.uvradiationUpdated = true;
      setTimeout(() => {
        this.uvradiationUpdated = false;
      }, this.flashBackgroundTime);

    });
  }
}

