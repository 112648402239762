import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SocketIoService } from '../socketIo.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-forecast',
  templateUrl: './forecast.component.html',
  styleUrls: ['./forecast.component.css']
})
export class ForecastComponent implements OnInit {

  constructor(private router: Router, private http: HttpClient, private socketIoService: SocketIoService) { }

  ngOnInit(): void {
  }


  btnHomeClick() {
    this.router.navigate(['/home']);
  }
}
