import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalConstants } from '../common/GlobalConstants ';
import { temperatureData } from '../data/temperatureData';
import { SocketIoService } from '../socketIo.service';

@Component({
  selector: 'app-stationrecords',
  templateUrl: './stationrecords.component.html',
  styleUrls: ['./stationrecords.component.css']
})
export class StationrecordsComponent implements OnInit {

  private requestUrlJarfallaOutdoorTemperatureMaxYear: string = GlobalConstants.requestUrl + '3333/jarfalla/temperature/max';
  private requestUrlJarfallaOutdoorTemperatureMinYear: string = GlobalConstants.requestUrl + '3333/jarfalla/temperature/min';

  public outdoorTempMax2016: string;
  public outdoorTempMax2017: string;
  public outdoorTempMax2018: string;
  public outdoorTempMax2019: string;
  public outdoorTempMax2020: string;
  public outdoorTempMax2021: string;
  public outdoorTempMax2022: string;
  public outdoorTempMax2023: string;

  public outdoorTempMaxDate2016: string;
  public outdoorTempMaxDate2017: string;
  public outdoorTempMaxDate2018: string;
  public outdoorTempMaxDate2019: string;
  public outdoorTempMaxDate2020: string;
  public outdoorTempMaxDate2021: string;
  public outdoorTempMaxDate2022: string;
  public outdoorTempMaxDate2023: string;

  public outdoorTempMaxTime2016: string;
  public outdoorTempMaxTime2017: string;
  public outdoorTempMaxTime2018: string;
  public outdoorTempMaxTime2019: string;
  public outdoorTempMaxTime2020: string;
  public outdoorTempMaxTime2021: string;
  public outdoorTempMaxTime2022: string;
  public outdoorTempMaxTime2023: string;


  public outdoorTempMin2016: string;
  public outdoorTempMin2017: string;
  public outdoorTempMin2018: string;
  public outdoorTempMin2019: string;
  public outdoorTempMin2020: string;
  public outdoorTempMin2021: string;
  public outdoorTempMin2022: string;
  public outdoorTempMin2023: string;

  public outdoorTempMinDate2016: string;
  public outdoorTempMinDate2017: string;
  public outdoorTempMinDate2018: string;
  public outdoorTempMinDate2019: string;
  public outdoorTempMinDate2020: string;
  public outdoorTempMinDate2021: string;
  public outdoorTempMinDate2022: string;
  public outdoorTempMinDate2023: string;

  public outdoorTempMinTime2016: string;
  public outdoorTempMinTime2017: string;
  public outdoorTempMinTime2018: string;
  public outdoorTempMinTime2019: string;
  public outdoorTempMinTime2020: string;
  public outdoorTempMinTime2021: string;
  public outdoorTempMinTime2022: string;
  public outdoorTempMinTime2023: string;


  public outdoorDewPointMax2021: string;
  public outdoorDewPointMaxDate2021: string;
  public outdoorDewPointMaxTime2021: string;
  public outdoorDewPointMax2022: string;
  public outdoorDewPointMaxDate2022: string;
  public outdoorDewPointMaxTime2022: string;
  public outdoorDewPointMax2023: string;
  public outdoorDewPointMaxDate2023: string;
  public outdoorDewPointMaxTime2023: string;



  public outdoorDewPointMin2021: string;
  public outdoorDewPointMinDate2021: string;
  public outdoorDewPointMinTime2021: string;
  public outdoorDewPointMin2022: string;
  public outdoorDewPointMinDate2022: string;
  public outdoorDewPointMinTime2022: string;
  public outdoorDewPointMin2023: string;
  public outdoorDewPointMinDate2023: string;
  public outdoorDewPointMinTime2023: string;

  constructor(private router: Router, private http: HttpClient, private socketIoService: SocketIoService) {
  }

  ngOnInit(): void {
    this.socketIoService.requestStartData('STATIONRECORDS');

    this.getMaxTemperature('Outdoor', '2016');
    this.getMaxTemperature('Outdoor', '2017');
    this.getMaxTemperature('Outdoor', '2018');
    this.getMaxTemperature('Outdoor', '2019');
    this.getMaxTemperature('Outdoor', '2020');
    this.getMaxTemperature('Outdoor', '2021');
    this.getMaxTemperature('Outdoor', '2022');
    this.getMaxTemperature('Outdoor', '2023');

    this.getMinTemperature('Outdoor', '2016');
    this.getMinTemperature('Outdoor', '2017');
    this.getMinTemperature('Outdoor', '2018');
    this.getMinTemperature('Outdoor', '2019');
    this.getMinTemperature('Outdoor', '2020');
    this.getMinTemperature('Outdoor', '2021');
    this.getMinTemperature('Outdoor', '2022');
    this.getMinTemperature('Outdoor', '2023');

    this.getMaxDewPoint('DewPoint', '2021');
    this.getMinDewPoit('DewPoint', '2021')
    this.getMaxDewPoint('DewPoint', '2022');
    this.getMinDewPoit('DewPoint', '2022');
    this.getMaxDewPoint('DewPoint', '2023');
    this.getMinDewPoit('DewPoint', '2023')
  }

  btnHomeClick() {
    this.router.navigate(['/home']);
  }

  btnJarfallaClick() {
    this.router.navigate(['/jarfalla']);
  };



  getMaxTemperature(zone: string, year: string) {
    this.http
      .get<Array<temperatureData>>(this.requestUrlJarfallaOutdoorTemperatureMaxYear, {
        params: { year: year, zone: zone }, observe: 'response'
      })
      .subscribe(resp => {

        let temp = resp.body['temperatureDay'][0][1];
        let date = JSON.stringify(resp.body['temperatureDay'][0][0]).substr(1, 10);
        let time = JSON.stringify(resp.body['temperatureDay'][0][0]).substr(11, 6);

        if (year == '2016') {
          this.outdoorTempMax2016 = temp;
          this.outdoorTempMaxDate2016 = date;
          this.outdoorTempMaxTime2016 = time;
        }
        else if (year == '2017') {
          this.outdoorTempMax2017 = temp;
          this.outdoorTempMaxDate2017 = date;
          this.outdoorTempMaxTime2017 = time;
        }
        else if (year == '2018') {
          this.outdoorTempMax2018 = temp;
          this.outdoorTempMaxDate2018 = date;
          this.outdoorTempMaxTime2018 = time;
        }
        else if (year == '2019') {
          this.outdoorTempMax2019 = temp;
          this.outdoorTempMaxDate2019 = date;
          this.outdoorTempMaxTime2019 = time;
        }
        else if (year == '2020') {
          this.outdoorTempMax2020 = temp;
          this.outdoorTempMaxDate2020 = date;
          this.outdoorTempMaxTime2020 = time;
        }
        else if (year == '2021') {
          this.outdoorTempMax2021 = temp;
          this.outdoorTempMaxDate2021 = date;
          this.outdoorTempMaxTime2021 = time
        }
        else if (year == '2022') {
          this.outdoorTempMax2022 = temp;
          this.outdoorTempMaxDate2022 = date;
          this.outdoorTempMaxTime2022 = time
        }
        else if (year == '2023') {
          this.outdoorTempMax2023 = temp;
          this.outdoorTempMaxDate2023 = date;
          this.outdoorTempMaxTime2023 = time
        }        
      });
  }

  getMinTemperature(zone: string, year: string) {
    this.http
      .get<Array<temperatureData>>(this.requestUrlJarfallaOutdoorTemperatureMinYear, {
        params: { year: year, zone: zone }, observe: 'response'
      })
      .subscribe(resp => {

        let temp = resp.body['temperatureDay'][0][1];
        let date = JSON.stringify(resp.body['temperatureDay'][0][0]).substr(1, 10);
        let time = JSON.stringify(resp.body['temperatureDay'][0][0]).substr(11, 6);

        if (year == '2016') {
          this.outdoorTempMin2016 = temp;
          this.outdoorTempMinDate2016 = date;
          this.outdoorTempMinTime2016 = time;
        }
        else if (year == '2017') {
          this.outdoorTempMin2017 = temp;
          this.outdoorTempMinDate2017 = date;
          this.outdoorTempMinTime2017 = time;
        }
        else if (year == '2018') {
          this.outdoorTempMin2018 = temp;
          this.outdoorTempMinDate2018 = date;
          this.outdoorTempMinTime2018 = time;
        }
        else if (year == '2019') {
          this.outdoorTempMin2019 = temp;
          this.outdoorTempMinDate2019 = date;
          this.outdoorTempMinTime2019 = time;
        }
        else if (year == '2020') {
          this.outdoorTempMin2020 = temp;
          this.outdoorTempMinDate2020 = date;
          this.outdoorTempMinTime2020 = time;
        }
        else if (year == '2021') {
          this.outdoorTempMin2021 = temp;
          this.outdoorTempMinDate2021 = date;
          this.outdoorTempMinTime2021 = time;
        }
        else if (year == '2022') {
          this.outdoorTempMin2022 = temp;
          this.outdoorTempMinDate2022 = date;
          this.outdoorTempMinTime2022 = time;
        }
        else if (year == '2023') {
          this.outdoorTempMin2023 = temp;
          this.outdoorTempMinDate2023 = date;
          this.outdoorTempMinTime2023 = time;
        }
      });
  }


  getMaxDewPoint(zone: string, year: string) {
    this.http
      .get<Array<temperatureData>>(this.requestUrlJarfallaOutdoorTemperatureMaxYear, {
        params: { year: year, zone: zone }, observe: 'response'
      })
      .subscribe(resp => {

        let temp = resp.body['temperatureDay'][0][1];
        let date = JSON.stringify(resp.body['temperatureDay'][0][0]).substr(1, 10);
        let time = JSON.stringify(resp.body['temperatureDay'][0][0]).substr(11, 6);

        if (year == '2021') {
          this.outdoorDewPointMax2021 = temp;
          this.outdoorDewPointMaxDate2021 = date;
          this.outdoorDewPointMaxTime2021 = time;
        }
        else if (year == '2022') {
          this.outdoorDewPointMax2022 = temp;
          this.outdoorDewPointMaxDate2022 = date;
          this.outdoorDewPointMaxTime2022 = time;
        }
        else if (year == '2023') {
          this.outdoorDewPointMax2023 = temp;
          this.outdoorDewPointMaxDate2023 = date;
          this.outdoorDewPointMaxTime2023 = time;
        }
      });
  }

  getMinDewPoit(zone: string, year: string) {
    this.http
      .get<Array<temperatureData>>(this.requestUrlJarfallaOutdoorTemperatureMinYear, {
        params: { year: year, zone: zone }, observe: 'response'
      })
      .subscribe(resp => {

        let temp = resp.body['temperatureDay'][0][1];
        let date = JSON.stringify(resp.body['temperatureDay'][0][0]).substr(1, 10);
        let time = JSON.stringify(resp.body['temperatureDay'][0][0]).substr(11, 6);

        if (year == '2021') {
          this.outdoorDewPointMin2021 = temp;
          this.outdoorDewPointMinDate2021 = date;
          this.outdoorDewPointMinTime2021 = time
        }
        else if (year == '2022') {
          this.outdoorDewPointMin2022 = temp;
          this.outdoorDewPointMinDate2022 = date;
          this.outdoorDewPointMinTime2022 = time
        }
        else if (year == '2023') {
          this.outdoorDewPointMin2023 = temp;
          this.outdoorDewPointMinDate2023 = date;
          this.outdoorDewPointMinTime2023 = time
        }                  
      });
  }
}