import { Component } from '@angular/core';
import { Router } from '@angular/router';
// import { SocketIoService } from '../socketIo.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})

export class HomeComponent {
  public date: Date;

  constructor(private router: Router)
  {  
    // Get current Date and time.
    setInterval(() => {
      this.date = new Date()
    }, 1000)
  }

  ngOnInit(): void {
  }


  btnHomeClick() {
    this.router.navigate(['/home']);
  }

  btnJarfallaClick() {
    this.router.navigate(['/jarfalla']);
  };

  btnTorreviejaClick() {
    this.router.navigate(['/torrevieja']);
  };

  btnSystemClick() {
    this.router.navigate(['/system']);
  };

  btnForecastClick() {
    this.router.navigate(['/forecast']);
  };

  openNewTab() {
    window.open('assets/images/design/Configuration.png', '_blank');
  }
}

