import { Component } from '@angular/core';
import { SocketIoService } from './socketIo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  // styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'iPrincip.nu';
  
  // constructor(socketIoService: SocketIoService) { }
}
