<div id="top" style="cursor: pointer;" (click)="btnHomeClick();">
    iPrincip.nu
</div>

<!-- -------------------------------------------------------------- -->
<!-- Row 1                                                          -->
<!-- -------------------------------------------------------------- -->
<div id="squarerow">

    <div [className]="cpuRapi2TempUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <img class="imagers" src="assets/images/cpu/cpurapi2.png">
                    <br>
                    <div class="texts18">
                        <font color="yellow">
                            <!-- <div align="center">{{ cpuRapi2Temp }} &deg;C</div> -->
                            <div align="center">Resting</div>
                        </font>
                    </div>
                    <div class="texts10">
                        <font color="lightblue">
                            <div align="center">Test</div>
                        </font>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div [className]="cpuRapi5TempUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <img class="imagers" src="assets/images/cpu/cpurapi5.png">
                    <br>
                    <div class="texts18">
                        <font color="yellow">
                            <div align="center">{{ cpuRapi5Temp }} &deg;C</div>
                        </font>
                    </div>
                    <div class="texts10">
                        <font color="lightblue">
                            <div align="center">I2C Network</div>
                        </font>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div [className]="cpuRapi7TempUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <img class="imagers" src="assets/images/cpu/cpurapi7.png">
                    <br>
                    <div class="texts18">
                        <font color="yellow">
                            <div align="center">{{ cpuRapi7Temp }} &deg;C</div>
                        </font>
                    </div>
                    <div class="texts10">
                        <font color="lightblue">
                            <div align="center">DBS</div>
                        </font>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div [className]="cpuRapi15TempUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <img class="imagers" src="assets/images/cpu/cpurapi15.png">
                    <br>
                    <div class="texts18">
                        <font color="yellow">
                            <!-- <div align="center">{{ cpuRapi15Temp }} &deg;C</div> -->
                            <div align="center">Resting</div>
                        </font>
                    </div>
                    <div class="texts10">
                        <font color="lightblue">
                            <div align="center">Solar Power Control System</div>
                        </font>
                    </div>
                </div>
            </div>
        </div>
    </div>
    

    <div [className]="cpuRapi16TempUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <img class="imagers" src="assets/images/cpu/cpurapi16.png">
                    <br>
                    <div class="texts18">
                        <font color="yellow">
                            <!-- <div align="center">{{ cpuRapi16Temp }} &deg;C</div> -->
                            <div align="center">Resting</div>
                        </font>
                    </div>
                    <div class="texts10">
                        <font color="lightblue">
                            <div align="center">Agriculture System</div>
                        </font>
                    </div>
                </div>
            </div>
        </div>
    </div>    


    <div [className]="cpuRapi17TempUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <img class="imagers" src="assets/images/cpu/cpurapi17.png">
                    <br>
                    <div class="texts18">
                        <font color="yellow">
                            <div align="center">{{ cpuRapi17Temp }} &deg;C</div>
                        </font>
                    </div>
                    <div class="texts10">
                        <font color="lightblue">
                            <div align="center">Temperature teller</div>
                        </font>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div [className]="cpuRapi18TempUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <img class="imagers" src="assets/images/cpu/cpurapi18.png">
                    <br>
                    <div class="texts18">
                        <font color="yellow">
                            <div align="center">{{ cpuRapi18Temp }} &deg;C</div>
                        </font>
                    </div>
                    <div class="texts10">
                        <font color="lightblue">
                            <div align="center">OWFS Segment B</div>
                        </font>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div [className]="cpuRapi21TempUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <img class="imagers" src="assets/images/cpu/cpurapi21.png">
                    <br>
                    <div class="texts18">
                        <font color="yellow">
                            <!-- <div align="center">{{ cpuRapi21Temp }} &deg;C</div> -->
                            <div align="center">Resting</div>
                        </font>
                    </div>
                    <div class="texts10">
                        <font color="lightblue">
                            <div align="center">Test</div>
                        </font>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- -------------------------------------------------------------- -->
<!-- Row 2                                                          -->
<!-- -------------------------------------------------------------- -->
<div id="squarerow">
    <div [className]="cpuRapi22TempUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <img class="imagers" src="assets/images/cpu/cpurapi22.png">
                    <br>
                    <div class="texts18">
                        <font color="yellow">
                            <div align="center">{{ cpuRapi22Temp }} &deg;C</div>
                        </font>
                    </div>
                    <div class="texts10">
                        <font color="lightblue">
                            <div align="center">Basesystem</div><div>OWFS Segment A</div>
                        </font>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>


    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>
</div>


<!-- -------------------------------------------------------------- -->
<!-- Row 3                                                          -->
<!-- -------------------------------------------------------------- -->
<div id="squarerow">

    <div [className]="cpuRapi22TempUpdated ? 'squarevaluechanged' : 'squaretripple'">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <div class="texts11">
                        <font color="yellow">
                            <div align="left">{{ eventLineA1 }}</div>
                        </font>
                    </div>
                    <div class="texts11">
                        <font color="yellow">
                            <div align="left">{{ eventLineA2 }}</div>
                        </font>
                    </div>
                    <div class="texts11">
                        <font color="yellow">
                            <div align="left">{{ eventLineA3 }}</div>
                        </font>
                    </div>
                    <div class="texts11">
                        <font color="yellow">
                            <div align="left">{{ eventLineA4 }}</div>
                        </font>
                    </div>
                    <div class="texts11">
                        <font color="yellow">
                            <div align="left">{{ eventLineA5 }}</div>
                        </font>
                    </div>
                    <div class="texts11">
                        <font color="yellow">
                            <div align="left">{{ eventLineA6 }}</div>
                        </font>
                    </div>
                    <div class="texts11">
                        <font color="yellow">
                            <div align="left">{{ eventLineA7 }}</div>
                        </font>
                    </div>
                    <div class="texts11">
                        <font color="yellow">
                            <div align="left">{{ eventLineA8 }}</div>
                        </font>
                    </div>  
                    <div class="texts11">
                        <font color="yellow">
                            <div align="left">{{ eventLineA9 }}</div>
                        </font>
                    </div>  
                    <div class="texts11">
                        <font color="yellow">
                            <div align="left">{{ eventLineA10 }}</div>
                        </font>
                    </div>  
                    <div class="texts11">
                        <font color="yellow">
                            <div align="left">{{ eventLineA11 }}</div>
                        </font>
                    </div>  
                    <div class="texts11">
                        <font color="yellow">
                            <div align="left">{{ eventLineA12 }}</div>
                        </font>
                    </div>  
                    <div class="texts11">
                        <font color="yellow">
                            <div align="left">{{ eventLineA13 }}</div>
                        </font>
                    </div>  
                    <div class="texts11">
                        <font color="yellow">
                            <div align="left">{{ eventLineA14 }}</div>
                        </font>
                    </div>
                    <div class="texts11">
                        <font color="yellow">
                            <div align="left">{{ eventLineA15 }}</div>
                        </font>
                    </div> 
                    <div class="texts11">
                        <font color="yellow">
                            <div align="left">{{ eventLineA16 }}</div>
                        </font>
                    </div>
                    <div class="texts11">
                        <font color="yellow">
                            <div align="left">{{ eventLineA17 }}</div>
                        </font>
                    </div>                       
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>
</div>


<!-- -------------------------------------------------------------- -->
<!-- Row 4                                                          -->
<!-- -------------------------------------------------------------- -->
<div id="squarerow">

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="square">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;" (click)="btnHomeClick();">
                    <img class="imagers" src="assets/images/back.png">
                </div>
            </div>
        </div>
    </div>
</div>