<div id="top" style="cursor: pointer;" (click)="btnHomeClick();">
    iPrincip.nu
</div>

<!-- -------------------------------------------------------------- -->
<!-- Row 1                                                          -->
<!-- -------------------------------------------------------------- -->
<div id="squarerow">

    <div class="square">
        <div class="content">
            <div class="table">
                <div class="table-cell ">                   
                    <img class="imagers" src="assets/images/solarpowersystem/solarmodule.png">
                </div>
            </div>
        </div>
    </div>

    
    <div [className]="solarPowerSystemBatterySOCUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell ">
                    <img class="imagers" src="assets/images/solarpowersystem/battery.png">
                    <div class="centerdtop"> SoC</div>  
                    <div class="centered"> {{ batterySOC }}%</div>                    
                </div>
            </div>
        </div>
    </div>


    <div class="square">
        <div class="content">
            <div class="table">
                <div class="table-cell ">
                    <img class="imagers" src="assets/images/solarpowersystem/chargecontroller.png">
                </div>
            </div>
        </div>
    </div>


    <div class="square">
        <div class="content">
            <div class="table">
                <div class="table-cell ">
                    <img class="imagers" src="assets/images/solarpowersystem/discharging.png">
                </div>
            </div>
        </div>
    </div>


    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>


    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>
</div>

<!-- -------------------------------------------------------------- -->
<!-- Row 2                                                          -->
<!-- -------------------------------------------------------------- -->
<div id="squarerow">
    <div [className]="solarPowerSystemSolarVoltageUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;">
                    <div class="texts16">
                        <font color="lightgray">
                            <div align="center">Voltage</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts18">
                        <font color="yellow">
                            <div align="center"> {{ solarVoltage }} V</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts12">
                        <font color="lightblue">
                            <div align="center"> Min: {{ solarVoltageMinToday }} V</div>
                        </font>
                    </div>
                    <div class="texts12">
                        <font color="lightblue">
                            <div align="center"> Max: {{ solarVoltageMaxToday }} V</div>
                        </font>
                    </div>
                </div>
            </div>
        </div>
    </div>
    

    <div [className]="solarPowerSystemBatteryVoltageUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;">
                    <div class="texts16">
                        <font color="lightgray">
                            <div align="center">Voltage</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts18">
                        <font color="yellow">
                            <div align="center"> {{ batteryVoltage }} V</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts12">
                        <font color="lightblue">
                            <div align="center"> Min: {{ batteryVoltageMinToday }} V</div>
                        </font>
                    </div>
                    <div class="texts12">
                        <font color="lightblue">
                            <div align="center"> Max: {{ batteryVoltageMaxToday }} V</div>
                        </font>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div [className]="solarChargeControllerCurrentSystemRatedVoltageUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;">
                    <div class="texts16">
                        <font color="lightgray">
                            <div align="center">Voltage</div>
                        </font>
                    </div>
                    <div class="texts12">
                        <font color="lightgray">
                            <div align="center">nominal</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts18">
                        <font color="yellow">
                            <div align="center"> {{ currentSystemRatedVoltage }} V</div>
                        </font>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div [className]="solarChargeControllerDischargingOutputVoltageUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;">
                    <div class="texts16">
                        <font color="lightgray">
                            <div align="center">Voltage</div>
                        </font>
                    </div>
                    <div class="texts18">
                        <font color="yellow">
                            <div align="center"> {{ dischargingOutputVoltage }} V</div>
                        </font>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>
</div>

<!-- -------------------------------------------------------------- -->
<!-- Row 3                                                          -->
<!-- -------------------------------------------------------------- -->
<div id="squarerow">
    <div [className]="solarPowerSystemSolarCurrentUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;">
                    <div class="texts16">
                        <font color="lightgray">
                            <div align="center">Current</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts18">
                        <font color="yellow">
                            <div align="center"> {{ solarCurrent }} A</div>
                        </font>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div [className]="solarPowerSystemBatteryChargeCurrentUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;">
                    <div class="texts16">
                        <font color="lightgray">
                            <div align="center">Current</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts18">
                        <font color="yellow">
                            <div align="center"> {{ batteryChargeCurrent }} A</div>
                        </font>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div [className]="solarChargeControllerTemperatureInsideCaseUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;">
                    <div class="texts16">
                        <font color="lightgray">
                            <div align="center">Temperature</div>
                        </font>
                    </div>
                    <div class="texts12">
                        <font color="lightgray">
                            <div align="center">inside case</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts18">
                        <font color="yellow">
                            <div align="center"> {{ temperatureInsideCase }} &deg;C</div>
                        </font>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div [className]="solarChargeControllerDischargingOutputCurrentUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;">
                    <div class="texts16">
                        <font color="lightgray">
                            <div align="center">Current</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts18">
                        <font color="yellow">
                            <div align="center"> {{ dischargingOutputCurrent }} A</div>
                        </font>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>
</div>

<!-- -------------------------------------------------------------- -->
<!-- Row 4                                                          -->
<!-- -------------------------------------------------------------- -->
<div id="squarerow">

    <div [className]="solarPowerSystemSolarPowerLUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;">
                    <div class="texts16">
                        <font color="lightgray">
                            <div align="center">Power</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts18">
                        <font color="yellow">
                            <div align="center"> {{ solarPowerL }} W</div>
                        </font>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div [className]="solarPowerSystemBatteryChargePowerLUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;">
                    <div class="texts16">
                        <font color="lightgray">
                            <div align="center">Power</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts18">
                        <font color="yellow">
                            <div align="center"> {{ batteryChargePowerL }} W</div>
                        </font>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div [className]="solarChargeControllerTemperaturePowerComponentsUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;">
                    <div class="texts16">
                        <font color="lightgray">
                            <div align="center">Temperature</div>
                        </font>
                    </div>
                    <div class="texts12">
                        <font color="lightgray">
                            <div align="center">power components</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts18">
                        <font color="yellow">
                            <div align="center"> {{ temperaturePowerComponents }} &deg;C</div>
                        </font>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div [className]="solarChargeControllerDischargingOutputPowerLUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;">
                    <div class="texts16">
                        <font color="lightgray">
                            <div align="center">Power</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts18">
                        <font color="yellow">
                            <div align="center"> {{ dischargingOutputPowerL }} W</div>
                        </font>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>
</div>

<!-- -------------------------------------------------------------- -->
<!-- Row 5                                                          -->
<!-- -------------------------------------------------------------- -->
<div id="squarerow">

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div [className]="solarPowerSystemBatteryTemperatureUpdated ? 'squarevaluechanged' : 'square'">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;">
                    <div class="texts16">
                        <font color="lightgray">
                            <div align="center">Temperature</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts18">
                        <font color="yellow">
                            <div align="center"> {{ batteryTemperature }} &deg;C</div>
                        </font>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>
</div>

<!-- -------------------------------------------------------------- -->
<!-- Row 6                                                          -->
<!-- -------------------------------------------------------------- -->
<div id="squarerow">

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="square">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;" (click)="btnJarfallaClick();">
                    <img class="imagers" src="assets/images/back.png">
                </div>
            </div>
        </div>
    </div>
</div>