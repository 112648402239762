import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common'; 
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { SocketIoService } from './socketIo.service';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { JarfallaComponent } from './jarfalla/jarfalla.component';
import { TorreviejaComponent } from './torrevieja/torrevieja.component';
import { HistoryComponent } from './history/history.component';
import { HouseComponent } from './house/house.component';
import { TemperatureComponent } from './temperature/temperature.component';
import { StationrecordsComponent } from './stationrecords/stationrecords.component';
import { GraphTemperatureComponent } from './graph-temperature/graph-temperature.component';
import { GraphHumidityComponent } from './graph-humidity/graph-humidity.component';
import { GraphPressureComponent } from './graph-pressure/graph-pressure.component';
import { GraphSunlightComponent } from './graph-sunlight/graph-sunlight.component';
import { GraphSunlightdayComponent } from './graph-sunlightday/graph-sunlightday.component';
import { GraphUvradiationComponent } from './graph-uvradiation/graph-uvradiation.component';
import { SystemComponent } from './system/system.component';
import { GraphRainComponent } from './graph-rain/graph-rain.component';
import { GraphSnowdepthComponent } from './graph-snowdepth/graph-snowdepth.component';
import { SunriseSunsetComponent } from './sunrise-sunset/sunrise-sunset.component';
import { ForecastComponent } from './forecast/forecast.component';
import { MoonphaseComponent } from './moonphase/moonphase.component';
import { SolarpowersystemComponent } from './solarpowersystem/solarpowersystem.component';


@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    SystemComponent,
    JarfallaComponent,
    TorreviejaComponent,
    HistoryComponent,
    HouseComponent,
    TemperatureComponent,
    StationrecordsComponent,
    GraphTemperatureComponent,
    GraphHumidityComponent,
    GraphPressureComponent,
    GraphSunlightComponent,
    GraphSunlightdayComponent,
    GraphRainComponent,
    GraphSnowdepthComponent,
    GraphUvradiationComponent,
    SunriseSunsetComponent,
    ForecastComponent,
    MoonphaseComponent,
    SolarpowersystemComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    // BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    GoogleChartsModule.forRoot(),
    RouterModule.forRoot([
    { path: '', component: HomeComponent, pathMatch: 'full' },
    // { path: 'SunriseSunset', component: SunriseSunsetComponent },
    { path: 'home', component: HomeComponent },
    { path: 'history', component: HistoryComponent },
    { path: 'solarpowersystem', component: SolarpowersystemComponent },
    { path: 'system', component: SystemComponent },
    { path: 'graphtemperature', component: GraphTemperatureComponent },
    { path: 'graphhumidity', component: GraphHumidityComponent },
    { path: 'graphpressure', component: GraphPressureComponent },
    { path: 'graphsunlight', component: GraphSunlightComponent },
    { path: 'graphsunlightday', component: GraphSunlightdayComponent },
    { path: 'graphrain', component: GraphRainComponent },
    { path: 'graphuvradiation', component: GraphUvradiationComponent },
    { path: 'house', component: HouseComponent },
    { path: 'jarfalla', component: JarfallaComponent },
    { path: 'torrevieja', component: TorreviejaComponent },
    { path: 'temperature', component: TemperatureComponent },
    { path: 'stationrecords', component: StationrecordsComponent },
    { path: 'sunrisesunset', component: SunriseSunsetComponent },
    { path: 'forecast', component: ForecastComponent },
    { path: 'moonphase', component: MoonphaseComponent }
    
], { relativeLinkResolution: 'legacy' })
  ],
  providers: [SocketIoService],
  bootstrap: [AppComponent]
})
export class AppModule { }
