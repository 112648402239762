<div id="top" style="cursor: pointer;" (click)="btnHomeClick();">
    iPrincip.nu
</div>


<!-- -------------------------------------------------------------- -->
<!--                                                                -->
<!-- Row 1                                                          -->
<!-- -------------------------------------------------------------- -->
<div id="squarerow">

    <div class="square">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <div class="texts18">
                        <font color="ec6972">
                            <div align="center">Max &deg;C</div>
                        </font>
                    </div>
                    <br>
                    <img class="imagers" src="assets/images/temperature/temperature.png">
                </div>
            </div>
        </div>
    </div>

    <div class="square">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <div class="texts16">
                        <font color="lightblue">
                            <div align="center">2016</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts18">
                        <font color="ec6972">
                            <div align="center">{{ outdoorTempMax2016 }} &deg;C</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts11">
                        <font color="lightblue">
                            <div align="center">{{ outdoorTempMaxDate2016 }}</div>
                            <div align="center">{{ outdoorTempMaxTime2016 }}</div>
                        </font>
                    </div>                    
                </div>
            </div>
        </div>
    </div>

    <div class="square">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <div class="texts16">
                        <font color="lightblue">
                            <div align="center">2017</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts18">
                        <font color="ec6972">
                            <div align="center">{{ outdoorTempMax2017 }} &deg;C</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts11">
                        <font color="lightblue">
                            <div align="center">{{ outdoorTempMaxDate2017 }}</div>
                            <div align="center">{{ outdoorTempMaxTime2017 }}</div>
                        </font>
                    </div>  
                </div>
            </div>
        </div>
    </div>

    <div class="square">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <div class="texts16">
                        <font color="lightblue">
                            <div align="center">2018</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts18">
                        <font color="ec6972">
                            <div align="center">{{ outdoorTempMax2018 }} &deg;C</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts11">
                        <font color="lightblue">
                            <div align="center">{{ outdoorTempMaxDate2018 }}</div>
                            <div align="center">{{ outdoorTempMaxTime2018 }}</div>
                        </font>
                    </div>  
                </div>
            </div>
        </div>
    </div>



    <div class="square">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <div class="texts16">
                        <font color="lightblue">
                            <div align="center">2019</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts18">
                        <font color="ec6972">
                            <div align="center">{{ outdoorTempMax2019 }} &deg;C</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts11">
                        <font color="lightblue">
                            <div align="center">{{ outdoorTempMaxDate2019 }}</div>
                            <div align="center">{{ outdoorTempMaxTime2019 }}</div>
                        </font>
                    </div>  
                </div>
            </div>
        </div>
    </div>

    <div class="square">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <div class="texts16">
                        <font color="lightblue">
                            <div align="center">2020</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts18">
                        <font color="ec6972">
                            <div align="center">{{ outdoorTempMax2020 }} &deg;C</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts11">
                        <font color="lightblue">
                            <div align="center">{{ outdoorTempMaxDate2020 }}</div>
                            <div align="center">{{ outdoorTempMaxTime2020 }}</div>
                        </font>
                    </div>  
                </div>
            </div>
        </div>
    </div>

    <div class="square">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <div class="texts16">
                        <font color="lightblue">
                            <div align="center">2021</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts18">
                        <font color="ec6972">
                            <div align="center">{{ outdoorTempMax2021 }} &deg;C</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts11">
                        <font color="lightblue">
                            <div align="center">{{ outdoorTempMaxDate2021 }}</div>
                            <div align="center">{{ outdoorTempMaxTime2021 }}</div>
                        </font>
                    </div>  
                </div>
            </div>
        </div>
    </div>


    <div class="square">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <div class="texts16">
                        <font color="lightblue">
                            <div align="center">2022</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts18">
                        <!-- <font color="ec6972">
                            <div align="center">{{ outdoorTempMax2022 }} &deg;C</div>
                        </font> -->
                        <font color="ec6972">
                            <div align="center">No data</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts11">
                        <!-- <font color="lightblue">
                            <div align="center">{{ outdoorTempMaxDate2022 }}</div>
                            <div align="center">{{ outdoorTempMaxTime2022 }}</div>
                        </font> -->
                        <font color="lightblue">
                            <div align="center">&nbsp;</div>
                            <div align="center">&nbsp;</div>
                        </font>                      
                    </div>  
                </div>
            </div>
        </div>
    </div>

</div>

<!-- -------------------------------------------------------------- -->
<!--                                                                -->
<!-- Row 2                                                          -->
<!-- -------------------------------------------------------------- -->

<div id="squarerow">
    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="square">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <div class="texts16">
                        <font color="lightblue">
                            <div align="center">2023</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts18">
                        <font color="ec6972">
                            <div align="center">{{ outdoorTempMax2023 }} &deg;C</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts11">
                        <font color="lightblue">
                            <div align="center">{{ outdoorTempMaxDate2023 }}</div>
                            <div align="center">{{ outdoorTempMaxTime2023 }}</div>
                        </font>
                    </div>  
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>
</div>

<!-- -------------------------------------------------------------- -->
<!--                                                                -->
<!-- Row 3                                                          -->
<!-- -------------------------------------------------------------- -->
<div id="squarerow">

    <div class="square">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <div class="texts18">
                        <font color="7992e8">
                            <div align="center">Min &deg;C</div>
                        </font>
                    </div>
                    <br>
                    <img class="imagers" src="assets/images/temperature/temperature.png">
                </div>
            </div>
        </div>
    </div>


    <div class="square">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <div class="texts16">
                        <font color="lightblue">
                            <div align="center">2016</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts18">
                        <font color="7992e8">
                            <div align="center">{{ outdoorTempMin2016 }} &deg;C</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts11">
                        <font color="lightblue">
                            <div align="center">{{ outdoorTempMinDate2016 }}</div>
                            <div align="center">{{ outdoorTempMinTime2016 }}</div>
                        </font>
                    </div>  
                </div>
            </div>
        </div>
    </div>

    <div class="square">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <div class="texts16">
                        <font color="lightblue">
                            <div align="center">2017</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts18">
                        <font color="7992e8">
                            <div align="center">{{ outdoorTempMin2017 }} &deg;C</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts11">
                        <font color="lightblue">
                            <div align="center">{{ outdoorTempMinDate2017 }}</div>
                            <div align="center">{{ outdoorTempMinTime2017 }}</div>
                        </font>
                    </div> 
                </div>
            </div>
        </div>
    </div>

    <div class="square">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <div class="texts16">
                        <font color="lightblue">
                            <div align="center">2018</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts18">
                        <font color="7992e8">
                            <div align="center">{{ outdoorTempMin2018 }} &deg;C</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts11">
                        <font color="lightblue">
                            <div align="center">{{ outdoorTempMinDate2018 }}</div>
                            <div align="center">{{ outdoorTempMinTime2018 }}</div>
                        </font>
                    </div> 
                </div>
            </div>
        </div>
    </div>

    <div class="square">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <div class="texts16">
                        <font color="lightblue">
                            <div align="center">2019</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts18">
                        <font color="7992e8">
                            <div align="center">{{ outdoorTempMin2019 }} &deg;C</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts11">
                        <font color="lightblue">
                            <div align="center">{{ outdoorTempMinDate2019}}</div>
                            <div align="center">{{ outdoorTempMinTime2019 }}</div>
                        </font>
                    </div> 
                </div>
            </div>
        </div>
    </div>


    <div class="square">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <div class="texts16">
                        <font color="lightblue">
                            <div align="center">2020</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts18">
                        <font color="7992e8">
                            <div align="center">{{ outdoorTempMin2020 }} &deg;C</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts11">
                        <font color="lightblue">
                            <div align="center">{{ outdoorTempMinDate2020 }}</div>
                            <div align="center">{{ outdoorTempMinTime2020 }}</div>
                        </font>
                    </div> 
                </div>
            </div>
        </div>
    </div>


    <div class="square">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <div class="texts16">
                        <font color="lightblue">
                            <div align="center">2021</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts18">
                        <font color="7992e8">
                            <div align="center">{{ outdoorTempMin2021 }} &deg;C</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts11">
                        <font color="lightblue">
                            <div align="center">{{ outdoorTempMinDate2021 }}</div>
                            <div align="center">{{ outdoorTempMinTime2021 }}</div>
                        </font>
                    </div> 
                </div>
            </div>
        </div>
    </div>


    <div class="square">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <div class="texts16">
                        <font color="lightblue">
                            <div align="center">2022</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts18">
                        <font color="7992e8">
                            <div align="center">{{ outdoorTempMin2022 }} &deg;C</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts11">
                        <font color="lightblue">
                            <div align="center">{{ outdoorTempMinDate2022 }}</div>
                            <div align="center">{{ outdoorTempMinTime2022 }}</div>
                        </font>
                    </div> 
                </div>
            </div>
        </div>
    </div>

</div>

<!-- -------------------------------------------------------------- -->
<!--                                                                -->
<!-- Row 4                                                          -->
<!-- -------------------------------------------------------------- -->

<div id="squarerow">
    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="square">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <div class="texts16">
                        <font color="lightblue">
                            <div align="center">2023</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts18">
                        <font color="7992e8">
                            <div align="center">{{ outdoorTempMin2023 }} &deg;C</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts11">
                        <font color="lightblue">
                            <div align="center">{{ outdoorTempMinDate2023 }}</div>
                            <div align="center">{{ outdoorTempMinTime2023 }}</div>
                        </font>
                    </div> 
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>
</div>

<!-- -------------------------------------------------------------- -->
<!--                                                                -->
<!-- Row 5                                                          -->
<!-- -------------------------------------------------------------- -->
<div id="squarerow">

    <div class="square">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <div class="texts18">
                        <font color="ec6972">
                            <div align="center">Max &deg;C</div>
                        </font>
                    </div>
                    <br>
                    <img class="imagers" src="assets/images/dewpoint/dewpoint.png">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>



    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="square">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <div class="texts16">
                        <font color="lightblue">
                            <div align="center">2021</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts18">
                        <font color="ec6972">
                            <div align="center">{{ outdoorDewPointMax2021 }} &deg;C</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts11">
                        <font color="lightblue">
                            <div align="center">{{ outdoorDewPointMaxDate2021 }}</div>
                            <div align="center">{{ outdoorDewPointMaxTime2021 }}</div>
                        </font>
                    </div> 
                </div>
            </div>
        </div>
    </div>


    <div class="square">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <div class="texts16">
                        <font color="lightblue">
                            <div align="center">2022</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts18">
                        <font color="ec6972">
                            <div align="center">{{ outdoorDewPointMax2022 }} &deg;C</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts11">
                        <font color="lightblue">
                            <div align="center">{{ outdoorDewPointMaxDate2022 }}</div>
                            <div align="center">{{ outdoorDewPointMaxTime2022 }}</div>
                        </font>
                    </div> 
                </div>
            </div>
        </div>
    </div>

</div>

<!-- -------------------------------------------------------------- -->
<!--                                                                -->
<!-- Row 6                                                          -->
<!-- -------------------------------------------------------------- -->

<div id="squarerow">
    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="square">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <div class="texts16">
                        <font color="lightblue">
                            <div align="center">2023</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts18">
                        <font color="ec6972">
                            <div align="center">{{ outdoorDewPointMax2023 }} &deg;C</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts11">
                        <font color="lightblue">
                            <div align="center">{{ outdoorDewPointMaxDate2023 }}</div>
                            <div align="center">{{ outdoorDewPointMaxTime2023 }}</div>
                        </font>
                    </div> 
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>
</div>

<!-- -------------------------------------------------------------- -->
<!--                                                                -->
<!-- Row 7                                                          -->
<!-- -------------------------------------------------------------- -->
<div id="squarerow">

    <div class="square">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <div class="texts18">
                        <font color="7992e8">
                            <div align="center">Min &deg;C</div>
                        </font>
                    </div>
                    <br>
                    <img class="imagers" src="assets/images/dewpoint/dewpoint.png">
                </div>
            </div>
        </div>
    </div>


    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>


    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>


    <div class="square">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <div class="texts16">
                        <font color="lightblue">
                            <div align="center">2021</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts18">
                        <font color="7992e8">
                            <div align="center">{{ outdoorDewPointMin2021 }} &deg;C</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts11">
                        <font color="lightblue">
                            <div align="center">{{ outdoorDewPointMinDate2021 }}</div>
                            <div align="center">{{ outdoorDewPointMinTime2021 }}</div>
                        </font>
                    </div> 
                </div>
            </div>
        </div>
    </div>


    <div class="square">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <div class="texts16">
                        <font color="lightblue">
                            <div align="center">2022</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts18">
                        <font color="7992e8">
                            <div align="center">{{ outdoorDewPointMin2022 }} &deg;C</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts11">
                        <font color="lightblue">
                            <div align="center">{{ outdoorDewPointMinDate2022 }}</div>
                            <div align="center">{{ outdoorDewPointMinTime2022 }}</div>
                        </font>
                    </div> 
                </div>
            </div>
        </div>
    </div>

</div>

<!-- -------------------------------------------------------------- -->
<!--                                                                -->
<!-- Row 8                                                          -->
<!-- -------------------------------------------------------------- -->

<div id="squarerow">
    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="square">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                    <div class="texts16">
                        <font color="lightblue">
                            <div align="center">2023</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts18">
                        <font color="7992e8">
                            <div align="center">{{ outdoorDewPointMin2023 }} &deg;C</div>
                        </font>
                    </div>
                    <br>
                    <div class="texts11">
                        <font color="lightblue">
                            <div align="center">{{ outdoorDewPointMinDate2023 }}</div>
                            <div align="center">{{ outdoorDewPointMinTime2023 }}</div>
                        </font>
                    </div> 
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>
</div>


<!-- -------------------------------------------------------------- -->
<!--                                                                -->
<!-- Row 9                                                          -->
<!-- -------------------------------------------------------------- -->
<div id="squarerow">

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>

    <div class="hiddensquare">
        <div class="content">
            <div class="table">
                <div class="table-cell">
                </div>
            </div>
        </div>
    </div>


    <div class="square">
        <div class="content">
            <div class="table">
                <div class="table-cell" style="cursor: pointer;" (click)="btnJarfallaClick();">
                    <img class="imagers" src="assets/images/back.png">
                </div>
            </div>
        </div>
    </div>

</div>