import { Component, OnInit } from '@angular/core';
import { SocketIoService } from '../socketIo.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { sunriseSunsetData } from '../data/sunriseSunsetData';
import { SunriseSunset } from '../model/sunrisesunset.model';
import { IpServiceService } from '../ip-service.service';
import { temperatureData } from '../data/temperatureData';
import { humidityData } from '../data/humidityData';
import { pressureData } from '../data/pressureData';
import { sunlightData } from '../data/sunlightData';
import { rainData } from '../data/rainData';
import { snowDepthData } from '../data/snowDepthData';
import { uvradiationData } from '../data/uvradiationData';
import { GlobalConstants } from '../common/GlobalConstants ';


@Component({
  selector: 'app-jarfalla',
  templateUrl: './jarfalla.component.html',
  // styleUrls: ['./jarfalla.component.css']
})
export class JarfallaComponent implements OnInit {
  public ipAddressValid: boolean = false;

  private requestUrlSunriseSunset: string = GlobalConstants.requestUrl + '3333/sunrisesunset';
  private requestUrlJarfallaTemperature: string = GlobalConstants.requestUrl + '3333/jarfalla/temperature';
  private requestUrlJarfallaHumidity: string = GlobalConstants.requestUrl + '3333/jarfalla/humidity';
  private requestUrlJarfallaPressure: string = GlobalConstants.requestUrl + '3333/jarfalla/pressure';
  private requestUrlJarfallaSunlight: string = GlobalConstants.requestUrl + '3333/jarfalla/sunlight';
  private requestUrlJarfallaSunlightday: string = GlobalConstants.requestUrl + '3333/jarfalla/sunlightday';
  private requestUrlJarfallaUVradiation: string = GlobalConstants.requestUrl + '3333/jarfalla/uvradiation';
  private requestUrlJarfallaRain: string = GlobalConstants.requestUrl + '3333/jarfalla/rain';
  private requestUrlJarfallaSnowdepth: string = GlobalConstants.requestUrl + '3333/jarfalla/snowdepth'; 
  
  public sunriseSunset: SunriseSunset;
  
  public outdoorTemp: string;
  public outdoorTempImage: string = "assets/images/temperature/temperature.png";

  public windCoolingTemp: string;
  public windCoolingTempImage: string = "assets/images/windcoolingtemperature/windcoolingtemperature.png";

  public dewPoint: string;
  public dewPointImage: string = "assets/images/dewpoint/dewpoint.png";
  
  public windMetrePerSecond: string;
  public windMaxMetrePerSecondhr: string;
  public windAvgMetrePerSecondHr: string;
  public windDirectionDegrees: string;
  public windDirectionImage: string;
  public windGust: string;
  public windGustImage: string;

  public groundTemp: string;
  public groundTempImage: string = "assets/images/temperatureground/temperatureground.png";

  public pressure: string;
  public pressureImage: string = "assets/images/pressure/pressure_n.png"

  public sunlightNow: string;
  public sunlightImage: string = "assets/images/sun/sun_0.png";
  public sunlightNowUpdated: boolean = false;
  public sunlightCurrentDay: string;
  public sunlightCurrentDayUpdated: boolean = false;
  public sunlightImageCurrentDay: string = "assets/images/sun/sun_0.png";

  public greenhouseTemp: string;
  public compost1Temp: string;
  public compost2Temp: string;
  public outdoorHumidity: string;
  public rainAmountLast24Hr: string;
  public snowDepth: string;
  public uvradiation: string;
  public risk: string;
  public mediaGraphicColor: string;
  public uvIndexImage: string;
  public daysSinceRain: string;
  public daysSinceLastRain: string;
  public solarCanAirHeaterTemp: string;

  public solarCanAirHeaterImage: string = "assets/images/solarcanairheater/solarcanairheaterinactive.png";
  public solarCanAirHeaterStatusActive: boolean = false;

  public moonPhaseVisibility: string;
  public moonPhaseImage: string = "assets/images/moonphases/0_1.png";
  public moonPhaseVisibilityUpdated: boolean = false;
  
  public outdoorTempUpdated: boolean = false;
  public windCoolingTempUpdated: boolean = false;
  public dewPointUpdated: boolean = false;
  public windMetrePerSecondUpdated: boolean = false;
  public windMaxMetrePerSecondHrUpdated: boolean = false;
  public windAvgMetrePerSecondHrUpdated: boolean = false;
  public windDirectionDegreesUpdated: boolean = false;
  public windGustUpdated: boolean = false;
  public groundTempsUpdated: boolean = false;
  public pressureUpdated: boolean = false;
  public greenhouseTempUpdated: boolean = false;
  public compost1TempUpdated: boolean = false;
  public compost2TempUpdated: boolean = false;
  public outdoorHumidityUpdated: boolean = false;
  public rainAmountLast24HrUpdated: boolean = false;
  public snowDepthUpdated: boolean = false;
  public uvradiationUpdated: boolean = false;
  public daysSinceRainUpdated: boolean = false;
  public daysSinceLastRainUpdated: boolean = false;
  public solarCanAirHeaterTempUpdated: boolean = false;

  private readonly flashBackgroundTime: number = 300;

  constructor(private router: Router, private http: HttpClient, private socketIoService: SocketIoService, private ip: IpServiceService) {

  }

  ngOnInit(): void {
    this.getIP();

    this.getSunset();
    this.getOutdoorTemp();
    this.getOutdoorWindCoolingTemp();
    this.getDewPoint();
    this.getOutdoorWindDirection();
    this.getOutdoorWindAvgSpeed();
    this.getOutdoorWindMaxSpeedHr();
    this.getOutdoorWindAvgSpeedHr();
    this.getOutdoorWindGust();
    this.getGroundTemp();
    this.getPressure();
    this.getSunlightNow();
    this.getSunlightCurrentDay()

    this.getGreenhouseTemp();
    this.getCompost1Temp();
    this.getCompost2Temp();
    this.getOutdoorHumidity();
    this.getRainAmountLast24Hr();
    this.getDaysSinceLastRain();
    this.getSnowDepth();
    this.getUVradiation();
    this.getSolarCanAirHeaterData();
    this.getMoonPhaseVisibility();

    this.socketIoService.requestStartData('JARFALLA');
  }

  getIP() {
    this.ip.getIPAddress().subscribe((res: any) => {
      this.setIPValidStatus(res.ip);
      // console.log("ipAddress: " + res.ip);
    });
  }

  setIPValidStatus(ipAddress) {
    this.ipAddressValid = false;

    if (ipAddress === GlobalConstants.validIpAdress) {
      this.ipAddressValid = true;
    }
  }

  btnHomeClick() {
    this.router.navigate(['/home']);
  }

  btnHistoryClick() {
    this.router.navigate(['/history']);
  };

  btnSolarPowerSystemClick() {
    this.router.navigate(['/solarpowersystem']);
  };


  btnHouseClick() {
    this.router.navigate(['/house']);
  };

  btnShowtemperaturedayClick() {
    this.router.navigate(['/temperature']);
  };

  btnStationRecordsClick() {
    this.router.navigate(['/stationrecords']);
  };

  btnSunriseSunsetClick() {
    this.router.navigate(['/sunrisesunset']);
  }

  btnMoonPhaseClick() {
    this.router.navigate(['/moonphase']);
  }


  btnGraphTemperatureClick(zone: string, timespan: string, formTitle: string) {
    this.http
      .get<Array<temperatureData>>(this.requestUrlJarfallaTemperature, {
        params: { timespan: timespan, zone: zone }, observe: 'response'
      })
      .subscribe(resp => {

        let temperatureData = [];

        var i: number;
        for (i = 0; i < resp.body['temperatureDay'].length; i++) {

          let date = JSON.stringify(resp.body['temperatureDay'][i][0]).substr(1, 10) + " . " + JSON.stringify(resp.body['temperatureDay'][i][0]).substr(12, 5);
          let temp = resp.body['temperatureDay'][i][1];

          temperatureData.push([date, temp]);
        }

        this.router.navigate(['/graphtemperature'], { state: { graphData: temperatureData, formTitle: formTitle } });
      });
  }



  btnGraphDewPointClick(zone: string, timespan: string, formTitle: string) {
    this.http
      .get<Array<temperatureData>>(this.requestUrlJarfallaTemperature, {
        params: { timespan: timespan, zone: zone }, observe: 'response'
      })
      .subscribe(resp => {

        let temperatureData = [];

        var i: number;
        for (i = 0; i < resp.body['temperatureDay'].length; i++) {

          let date = JSON.stringify(resp.body['temperatureDay'][i][0]).substr(1, 10) + " . " + JSON.stringify(resp.body['temperatureDay'][i][0]).substr(12, 5);
          let temp = resp.body['temperatureDay'][i][1];

          temperatureData.push([date, temp]);
        }

        this.router.navigate(['/graphtemperature'], { state: { graphData: temperatureData, formTitle: formTitle } });
      });
  }


  btnGraphHumidityClick(zone: string, timespan: string, formTitle: string) {
    this.http
      .get<Array<humidityData>>(this.requestUrlJarfallaHumidity, {
        params: { timespan: timespan, zone: zone }, observe: 'response'
      })
      .subscribe(resp => {

        let humidityData = [];

        var i: number;
        for (i = 0; i < resp.body['humidityDay'].length; i++) {

          let date = JSON.stringify(resp.body['humidityDay'][i][0]).substr(1, 10) + " . " + JSON.stringify(resp.body['humidityDay'][i][0]).substr(12, 5);
          let humidity = resp.body['humidityDay'][i][1];

          humidityData.push([date, humidity]);
        }

        this.router.navigate(['/graphhumidity'], { state: { graphData: humidityData, formTitle: formTitle } });
      });
  }


  btnGraphPressureClick(zone: string, timespan: string, formTitle: string) {
    this.http
      .get<Array<pressureData>>(this.requestUrlJarfallaPressure, {
        params: { timespan: timespan, zone: zone }, observe: 'response'
      })
      .subscribe(resp => {

        let pressureData = [];

        var i: number;
        for (i = 0; i < resp.body['pressureDay'].length; i++) {

          let date = JSON.stringify(resp.body['pressureDay'][i][0]).substr(1, 10) + " . " + JSON.stringify(resp.body['pressureDay'][i][0]).substr(12, 5);
          let pressure = resp.body['pressureDay'][i][1];

          pressureData.push([date, pressure]);
        }

        this.router.navigate(['/graphpressure'], { state: { graphData: pressureData, formTitle: formTitle } });
      });
  }


  btnGraphSunlightClick(zone: string, timespan: string, formTitle: string) {
    this.http
      .get<Array<sunlightData>>(this.requestUrlJarfallaSunlight, {
        params: { timespan: timespan, zone: zone }, observe: 'response'
      })
      .subscribe(resp => {

        let sunlightData = [];

        var i: number;
        for (i = 0; i < resp.body['sunlightDay'].length; i++) {

          let date = JSON.stringify(resp.body['sunlightDay'][i][0]).substr(1, 10) + " . " + JSON.stringify(resp.body['sunlightDay'][i][0]).substr(12, 5);
          let sundlight = resp.body['sunlightDay'][i][1];

          sunlightData.push([date, sundlight]);
        }

        this.router.navigate(['/graphsunlight'], { state: { graphData: sunlightData, formTitle: formTitle } });
      });
  }


  
  btnGraphSunlightdayClick(zone: string, timespan: string, formTitle: string) {
    this.http
      .get<Array<sunlightData>>(this.requestUrlJarfallaSunlightday, {
        params: { timespan: timespan, zone: zone }, observe: 'response'
      })
      .subscribe(resp => {

        let sunlightData = [];

        console.log("resp.body['sunlightDay'].length" + resp.body['sunlightDay'].length);

        var i: number;
        for (i = 0; i < resp.body['sunlightDay'].length; i++) {

          //let date = JSON.stringify(resp.body['sunlightDay'][i][0]).substr(1, 10) + " . " + JSON.stringify(resp.body['sunlightDay'][i][0]).substr(12, 5);
          let date = JSON.stringify(resp.body['sunlightDay'][i][0]).substr(1, 10);
          let sundlight = resp.body['sunlightDay'][i][1];

          sunlightData.push([date, sundlight]);
        }

        this.router.navigate(['/graphsunlightday'], { state: { graphData: sunlightData, formTitle: formTitle } });
      });
  }


  btnGraphUVradiationClick(zone: string, timespan: string, formTitle: string) {
    console.log("btnGraphUVradiationClick");

    this.http
      .get<Array<uvradiationData>>(this.requestUrlJarfallaUVradiation, {
        params: { timespan: timespan, zone: zone }, observe: 'response'
      })
      .subscribe(resp => {

        // console.log("btnGraphUVradiationClick");

        let uvradiationData = [];

        var i: number;
        for (i = 0; i < resp.body['uvradiationData'].length; i++) {

          let date = JSON.stringify(resp.body['uvradiationData'][i][0]).substr(1, 10) + " . " + JSON.stringify(resp.body['uvradiationData'][i][0]).substr(12, 5);
          let uvradiation = resp.body['uvradiationData'][i][1];

          uvradiationData.push([date, uvradiation]);
        }

        this.router.navigate(['/graphuvradiation'], { state: { graphData: uvradiationData, formTitle: formTitle } });
      });
  }


  btnGraphRainClick(zone: string, numberOfDays: string, formTitle: string) {
    this.http
      .get<Array<rainData>>(this.requestUrlJarfallaRain, {
        params: { numberofdays: numberOfDays, zone: zone }, observe: 'response'
      })
      .subscribe(resp => {

        let rainData = [];

        var i: number;
        for (i = 0; i < resp.body['rainDay'].length; i++) {

          let date = JSON.stringify(resp.body['rainDay'][i][0]).substr(1, 10) + " . " + JSON.stringify(resp.body['rainDay'][i][0]).substr(12, 5);
          let rain = resp.body['rainDay'][i][1];

          rainData.push([date, rain]);
        }

        this.router.navigate(['/graphrain'], { state: { graphData: rainData, formTitle: formTitle } });
      });
  }
  


  btnGraphSnowdepthClick(zone: string, timespan: string, formTitle: string) {
    this.http
      .get<Array<snowDepthData>>(this.requestUrlJarfallaSnowdepth, {
        params: { timespan: timespan, zone: zone }, observe: 'response'
      })
      .subscribe(resp => {

        let snowdepthData = [];

        var i: number;
        for (i = 0; i < resp.body['snowdepthDay'].length; i++) {

          let date = JSON.stringify(resp.body['snowdepthDay'][i][0]).substr(1, 10) + " . " + JSON.stringify(resp.body['snowdepthDay'][i][0]).substr(12, 5);
          let snowdept = resp.body['snowdepthDay'][i][1];

          console.log("snowdept: " + snowdept);

          snowdepthData.push([date, snowdept]);
        }

        this.router.navigate(['/graphsnowdepth'], { state: { graphData: snowdepthData, formTitle: formTitle } });
      });
  }


  getSunset() {
    let now = new Date();
    let yyyymmdd = now.getFullYear().toString() + ((((now.getMonth() + 1)) < 10 ? "0" : "") + (now.getMonth() + 1)).toString() + ((now.getDate() < 10 ? "0" : "") + now.getDate()).toString();
    let currentTimeZoneOffsetInHours = now.getTimezoneOffset() / 60;

    this.http
      .get<sunriseSunsetData>(this.requestUrlSunriseSunset, {
        params: { lat: "59.430391", lng: "17.826264", yyyymmdd: yyyymmdd.toString(), currentTimeZoneOffsetInHours: currentTimeZoneOffsetInHours.toString() }, observe: 'response'
      })
      .subscribe(resp => {
        this.sunriseSunset = new SunriseSunset(resp.body.sunrise, resp.body.sunset, resp.body.length);
      });
  }



  getOutdoorTemp() {
    this.socketIoService.getJarfallaOutdoorTemp().subscribe((message: string) => {
      this.outdoorTemp = message;

      // this.outdoorTempImage = "assets/images/temperature/temperature.png";

      // if (parseFloat(this.outdoorTemp) > 0.0) {
      //   this.outdoorTempImage = "assets/images/temperature/temperaturehigh.png";
      // }
      // else {
      //   this.outdoorTempImage = "assets/images/temperature/temperatureLow.png";
      // }

      // Update square background color when value change.
      this.outdoorTempUpdated = true;
      setTimeout(() => {
        this.outdoorTempUpdated = false;
      }, this.flashBackgroundTime);
    });
  }


  getOutdoorWindDirection() {
    this.socketIoService.getJarfallaOutdoorWindDirection().subscribe((windDirectionDegrees: string) => {
      if (parseInt(windDirectionDegrees) >= 337) {
        this.windDirectionImage = "assets/images/winddirection/nnw.png";
      }
      else if (parseInt(windDirectionDegrees) >= 315) {
        this.windDirectionImage = "assets/images/winddirection/nw.png";
      }
      else if (parseInt(windDirectionDegrees) >= 292) {
        this.windDirectionImage = "assets/images/winddirection/wnw.png";
      }
      else if (parseInt(windDirectionDegrees) >= 270) {
        this.windDirectionImage = "assets/images/winddirection/w.png";
      }
      else if (parseInt(windDirectionDegrees) >= 247) {
        this.windDirectionImage = "assets/images/winddirection/wsw.png";
      }
      else if (parseInt(windDirectionDegrees) >= 225) {
        this.windDirectionImage = "assets/images/winddirection/sw.png";
      }
      else if (parseInt(windDirectionDegrees) >= 202) {
        this.windDirectionImage = "assets/images/winddirection/ssw.png";
      }
      else if (parseInt(windDirectionDegrees) >= 180) {
        this.windDirectionImage = "assets/images/winddirection/s.png";
      }
      else if (parseInt(windDirectionDegrees) >= 157) {
        this.windDirectionImage = "assets/images/winddirection/sse.png";
      }
      else if (parseInt(windDirectionDegrees) >= 135) {
        this.windDirectionImage = "assets/images/winddirection/se.png";
      }
      else if (parseInt(windDirectionDegrees) >= 112) {
        this.windDirectionImage = "assets/images/winddirection/ese.png";
      }
      else if (parseInt(windDirectionDegrees) >= 90) {
        this.windDirectionImage = "assets/images/winddirection/e.png";
      }
      else if (parseInt(windDirectionDegrees) >= 67) {
        this.windDirectionImage = "assets/images/winddirection/ene.png";
      }
      else if (parseInt(windDirectionDegrees) >= 45) {
        this.windDirectionImage = "assets/images/winddirection/ne.png";
      }
      else if (parseInt(windDirectionDegrees) >= 22) {
        this.windDirectionImage = "assets/images/winddirection/nne.png";
      }
      else if (parseInt(windDirectionDegrees) >= 0) {
        this.windDirectionImage = "assets/images/winddirection/n.png";
      }

      // Update square background color when value change.
      //this.windDirectionDegreesUpdated = true;
      //setTimeout(() => {
      //  this.windDirectionDegreesUpdated = false;
      //}, this.flashBackgroundTime);
    });
  }


  getOutdoorWindAvgSpeed() {
    this.socketIoService.getJarfallaOutdoorWindAvgSpeed().subscribe((message: string) => {
      this.windMetrePerSecond = message;

      // Update square background color when value change.
      this.windMetrePerSecondUpdated = true;
      setTimeout(() => {
        this.windMetrePerSecondUpdated = false;
      }, this.flashBackgroundTime);
    });
  }

  getOutdoorWindMaxSpeedHr() {
    this.socketIoService.getJarfallaOutdoorWindMaxSpeedHr().subscribe((message: string) => {
      this.windMaxMetrePerSecondhr = message;

      //console.log('windMaxMetrePerSecondhr: ' + this.windMaxMetrePerSecondhr);
    
      // Update square background color when value change.
      this.windMaxMetrePerSecondHrUpdated = true;
      setTimeout(() => {
        this.windMaxMetrePerSecondHrUpdated = false;
      }, this.flashBackgroundTime);
    });
  }


  getOutdoorWindAvgSpeedHr() {
    this.socketIoService.getJarfallaOutdoorWindAvgSpeedHr().subscribe((message: string) => {
      this.windAvgMetrePerSecondHr = message;

      //console.log('windAvgMetrePerSecondHr: ' + this.windAvgMetrePerSecondHr);

      // Update square background color when value change.
      this.windAvgMetrePerSecondHrUpdated = true;
      setTimeout(() => {
        this.windAvgMetrePerSecondHrUpdated = false;
      }, this.flashBackgroundTime);
    });
  }

  getOutdoorWindGust() {
    this.socketIoService.getJarfallaOutdoorWindGust().subscribe((message: string) => {
      this.windGust = message;

      //console.log('windGust: ' + this.windGust);

      // Update square background color when value change.
      this.windGustUpdated = true;
      setTimeout(() => {
        this.windGustUpdated = false;
      }, this.flashBackgroundTime);
    });
  }


  
  getOutdoorWindCoolingTemp() {
    this.socketIoService.getJarfallaOutdoorWindCoolingTemp().subscribe((message: string) => {
      this.windCoolingTemp = message;


      //this.windCoolingTempImage = "assets/images/windcoolingtemperature/windcoolingtemperature.png";
      // if (parseFloat(this.windCoolingTemp) > 0.0) {
      //   this.windCoolingTempImage = "assets/images/windcoolingtemperature/windcoolingtemperaturehigh.png";
      // }
      // else {
      //   this.windCoolingTempImage = "assets/images/windcoolingtemperature/windcoolingtemperaturelow.png";
      // }

      // Update square background color when value change.
      this.windCoolingTempUpdated = true;
      setTimeout(() => {
        this.windCoolingTempUpdated = false;
      }, this.flashBackgroundTime);
    });
  }


  getDewPoint() {
    this.socketIoService.getJarfallaDewPoint().subscribe((message: string) => {
      this.dewPoint = message;

      //this.dewPointImage = "assets/images/dewpoint/dewpoint.png";

      // Update square background color when value change.
      this.dewPointUpdated = true;
      setTimeout(() => {
        this.dewPointUpdated = false;
      }, this.flashBackgroundTime);
    });
  }


  getGroundTemp() {
    this.socketIoService.getJarfallaOutdoorGroundTemp().subscribe((message: string) => {
      this.groundTemp = message;

      // this.groundTempImage = "assets/images/temperatureground/temperatureground.png";
      // if (parseFloat(this.groundTemp) > 0.0) {
      //   this.groundTempImage = "assets/images/temperatureground/temperaturegroundhigh.png";
      // }
      // else {
      //   this.groundTempImage = "assets/images/temperatureground/temperaturegroundlow.png";
      // }

      // Update square background color when value change.
      this.groundTempsUpdated = true;
      setTimeout(() => {
        this.groundTempsUpdated = false;
      }, this.flashBackgroundTime);
    });
  }


  getPressure() {
    this.socketIoService.getJarfallaOutdoorPressure().subscribe((data: any) => {
      this.pressure = data.pressure;

      if (data.highlow == 'high') {
        this.pressureImage = "assets/images/pressure/pressure_h.png";
      }
      else {
        this.pressureImage = "assets/images/pressure/pressure_l.png";
      }

      // Update square background color when value change.
      this.pressureUpdated = true;
      setTimeout(() => {
        this.pressureUpdated = false;
      }, this.flashBackgroundTime);
    });
  }


  getSunlightNow() {
    this.socketIoService.getJarfallaOutdoorSunlightNow().subscribe((message: string) => {
      this.sunlightNow = message;

      let sunliteInt = parseInt(this.sunlightNow);

      if (sunliteInt >= 90) {
        this.sunlightImage = "assets/images/sun/sun_100.png";
      }
      else if (sunliteInt >= 75) {
        this.sunlightImage = "assets/images/sun/sun_80.png";
      }
      else if (sunliteInt >= 45) {
        this.sunlightImage = "assets/images/sun/sun_50.png";
      }
      else if (sunliteInt >= 20) {
        this.sunlightImage = "assets/images/sun/sun_20.png";
      }
      else if (sunliteInt >= 10) {
        this.sunlightImage = "assets/images/sun/sun_10.png";
      }
      else {
        this.sunlightImage = "assets/images/sun/sun_0.png";
      }

      // Update square background color when value change.
      this.sunlightNowUpdated = true;
      setTimeout(() => {
        this.sunlightNowUpdated = false;
      }, this.flashBackgroundTime);
    });
  }


  getSunlightCurrentDay() {
    this.socketIoService.getJarfallaOutdoorSunlightCurrentDay().subscribe((message: string) => {
      this.sunlightCurrentDay = message;

      let sunliteInt = parseInt(this.sunlightCurrentDay);

      if (sunliteInt >= 90) {
        this.sunlightImageCurrentDay = "assets/images/sun/sun_100.png";
      }
      else if (sunliteInt >= 75) {
        this.sunlightImageCurrentDay = "assets/images/sun/sun_80.png";
      }
      else if (sunliteInt >= 45) {
        this.sunlightImageCurrentDay = "assets/images/sun/sun_50.png";
      }
      else if (sunliteInt >= 20) {
        this.sunlightImageCurrentDay = "assets/images/sun/sun_20.png";
      }
      else if (sunliteInt >= 10) {
        this.sunlightImageCurrentDay = "assets/images/sun/sun_10.png";
      }
      else {
        this.sunlightImageCurrentDay = "assets/images/sun/sun_0.png";
      }

      // Update square background color when value change.
      this.sunlightCurrentDayUpdated = true;
      setTimeout(() => {
        this.sunlightCurrentDayUpdated = false;
      }, this.flashBackgroundTime);
    });
  }


  getGreenhouseTemp() {
    this.socketIoService.getJarfallaOutdoorGreenhouseTemp().subscribe((message: string) => {
      this.greenhouseTemp = message;

      // Update square background color when value change.
      this.greenhouseTempUpdated = true;
      setTimeout(() => {
        this.greenhouseTempUpdated = false;
      }, this.flashBackgroundTime);
    });
  }


  getCompost1Temp() {
    this.socketIoService.getJarfallaOutdoorCompost1Temp().subscribe((message: string) => {
      this.compost1Temp = message;

      // Update square background color when value change.
      this.compost1TempUpdated = true;
      setTimeout(() => {
        this.compost1TempUpdated = false;
      }, this.flashBackgroundTime);
    });
  }


  getCompost2Temp() {
    this.socketIoService.getJarfallaOutdoorCompost2Temp().subscribe((message: string) => {
      this.compost2Temp = message;

      // Update square background color when value change.
      this.compost2TempUpdated = true;
      setTimeout(() => {
        this.compost2TempUpdated = false;
      }, this.flashBackgroundTime);
    });
  }


  getOutdoorHumidity() {
    this.socketIoService.getJarfallaOutdoorHumidity().subscribe((message: string) => {
      this.outdoorHumidity = message;

      // Update square background color when value change.
      this.outdoorHumidityUpdated = true;
      setTimeout(() => {
        this.outdoorHumidityUpdated = false;
      }, this.flashBackgroundTime);
    });
  }


  getRainAmountLast24Hr() {
    this.socketIoService.getJarfallaOutdoorRainAmountLast24Hr().subscribe((message: string) => {
      this.rainAmountLast24Hr = message;

      //console.log("rainAmountLast24Hr: " + this.rainAmountLast24Hr);

      // Update square background color when value change.
      this.rainAmountLast24HrUpdated = true;
      setTimeout(() => {
        this.rainAmountLast24HrUpdated = false;
      }, this.flashBackgroundTime);
    });
  }


  getDaysSinceLastRain() {
    this.socketIoService.getJarfallOutdoorDaysSinceLastRain().subscribe((message: string) => {
      this.daysSinceLastRain = message;

      // Update square background color when value change.
      this.daysSinceLastRainUpdated = true;
      setTimeout(() => {
        this.daysSinceLastRainUpdated = false;
      }, this.flashBackgroundTime);
    });
  }



  getSnowDepth() {
    this.socketIoService.getJarfallaOutdoorSnowDepth().subscribe((message: string) => {
      this.snowDepth = message;

      //console.log("snowDepth: " + this.snowDepth);

      // Update square background color when value change.
      this.snowDepthUpdated = true;
      setTimeout(() => {
        this.snowDepthUpdated = false;
      }, this.flashBackgroundTime);
    });
  }


  getUVradiation() {
    this.socketIoService.getJarfallaOutdoorUVradiation().subscribe((data: any) => {
      this.uvradiation = data.uvradiation;
      this.mediaGraphicColor = data.mediaGraphicColor;
      this.risk = data.risk;

      //console.log("uvradiation:" + this.uvradiation);
      //console.log("mediaGraphicColor:" + this.mediaGraphicColor);
      //console.log("risk:" + this.risk);

      if (parseInt(this.uvradiation) >= 11.0) {
        this.uvIndexImage = "assets/images/uvindex/11uvindex.png";
      }
      else if (parseInt(this.uvradiation) >= 10.0) {
        this.uvIndexImage = "assets/images/uvindex/10uvindex.png";
      }
      else if (parseInt(this.uvradiation) >= 9.0) {
        this.uvIndexImage = "assets/images/uvindex/9uvindex.png";
      }
      else if (parseInt(this.uvradiation) >= 8.0) {
        this.uvIndexImage = "assets/images/uvindex/8uvindex.png";
      }
      else if (parseInt(this.uvradiation) >= 7.0) {
        this.uvIndexImage = "assets/images/uvindex/7uvindex.png";
      }
      else if (parseInt(this.uvradiation) >= 6.0) {
        this.uvIndexImage = "assets/images/uvindex/6uvindex.png";
      }
      else if (parseInt(this.uvradiation) >= 5.0) {
        this.uvIndexImage = "assets/images/uvindex/5uvindex.png";
      }
      else if (parseInt(this.uvradiation) >= 4.0) {
        this.uvIndexImage = "assets/images/uvindex/4uvindex.png";
      }
      else if (parseInt(this.uvradiation) >= 3.0) {
        this.uvIndexImage = "assets/images/uvindex/3uvindex.png";
      }
      else if (parseInt(this.uvradiation) >= 2.0) {
        this.uvIndexImage = "assets/images/uvindex/2uvindex.png";
      }
      else if (parseInt(this.uvradiation) >= 1.0) {
        this.uvIndexImage = "assets/images/uvindex/1uvindex.png";
      }
      else if (parseInt(this.uvradiation) >= 0.0) {
        this.uvIndexImage = "assets/images/uvindex/0uvindex.png";
      }
      else {
        this.uvIndexImage = "assets/images/uvindex/0uvindex.png";
      }

      // Update square background color when value change.
      this.uvradiationUpdated = true;
      setTimeout(() => {
        this.uvradiationUpdated = false;
      }, this.flashBackgroundTime);
    });
  }


  getSolarCanAirHeaterData() {
    this.socketIoService.getJarfallaOutdoorCanAirHeaterData().subscribe((data: any) => {
      this.solarCanAirHeaterTemp = data.temperature;
      let solarCanAirHeaterStatus = data.status;

      //console.log("getSolarCanAirHeaterData() solarCanAirHeaterTemp: " + this.solarCanAirHeaterTemp);

      // Activated at 14 degrees.
      if (solarCanAirHeaterStatus == 'INACTIVE') {
        this.solarCanAirHeaterStatusActive = false;
        this.solarCanAirHeaterImage = "assets/images/solarcanairheater/solarcanairheaterinactive.png";
      }
      else {
        this.solarCanAirHeaterStatusActive = true;
        this.solarCanAirHeaterImage = "assets/images/solarcanairheater/solarcanairheateractive.png";
      }

      // Update square background color when value change.
      this.solarCanAirHeaterTempUpdated = true;
      setTimeout(() => {
        this.solarCanAirHeaterTempUpdated = false;
      }, this.flashBackgroundTime);
    });
  }

  
  getMoonPhaseVisibility() {
    this.socketIoService.getMoonPhaseVisibility().subscribe((message: string) => {
      this.moonPhaseVisibility = message;

      let moonPhaseVisibilityInt = parseInt(message);

      //console.log("MoonPhaseVisibility: " + this.moonPhaseVisibility);

      if (moonPhaseVisibilityInt >= 100) {
        //console.log("100");
        this.moonPhaseImage = "assets/images/moonphases/100.png";
      }
      else if (moonPhaseVisibilityInt >= 95) {
        //console.log("95_1");
        this.moonPhaseImage = "assets/images/moonphases/95_1.png";
      }
      else if (moonPhaseVisibilityInt >= 90) {
        //console.log("90_1");
        this.moonPhaseImage = "assets/images/moonphases/90_1.png";
      }
      else if (moonPhaseVisibilityInt >= 85) {
        //console.log("85_1");
        this.moonPhaseImage = "assets/images/moonphases/85_1.png";
      }
      else if (moonPhaseVisibilityInt >= 80) {
        //console.log("80_");
        this.moonPhaseImage = "assets/images/moonphases/80_1.png";
      }
      else if (moonPhaseVisibilityInt >= 75) {
        //console.log("75_1");
        this.moonPhaseImage = "assets/images/moonphases/75_1.png";
      }
      else if (moonPhaseVisibilityInt >= 70) {
        //console.log("70_1");
        this.moonPhaseImage = "assets/images/moonphases/70_1.png";
      }
      else if (moonPhaseVisibilityInt >= 65) {
        //console.log("65_1");
        this.moonPhaseImage = "assets/images/moonphases/65_1.png";
      }
      else if (moonPhaseVisibilityInt >= 60) {
        //console.log("60_1");
        this.moonPhaseImage = "assets/images/moonphases/60_1.png";
      }
      else if (moonPhaseVisibilityInt >= 55) {
        //console.log("55_1");
        this.moonPhaseImage = "assets/images/moonphases/55_1.png";
      }
      else if (moonPhaseVisibilityInt >= 50) {
        //console.log("50_1");
        this.moonPhaseImage = "assets/images/moonphases/50_1.png";
      }
      // else if (moonPhaseVisibilityInt >= 45) {
      //   this.moonPhaseImage = "assets/images/moonphases/45_1.png";
      // }  
      else if (moonPhaseVisibilityInt >= 40) {
        //console.log("35_1");
        this.moonPhaseImage = "assets/images/moonphases/35_1.png";
      } 
      else if (moonPhaseVisibilityInt >= 35) {
        //console.log("35_1");
        this.moonPhaseImage = "assets/images/moonphases/35_1.png";
      }  
      else if (moonPhaseVisibilityInt >= 25) {
        //console.log("25_1");
        this.moonPhaseImage = "assets/images/moonphases/25_1.png";
      }     
      else if (moonPhaseVisibilityInt >= 20) {
        //console.log("20_1");
        this.moonPhaseImage = "assets/images/moonphases/20_1.png";
      }
      else if (moonPhaseVisibilityInt >= 15) {
        //console.log("15_1");
        this.moonPhaseImage = "assets/images/moonphases/15_1.png";
      }
      else if (moonPhaseVisibilityInt >= 10) {
        //console.log("10_1");
        this.moonPhaseImage = "assets/images/moonphases/10_1.png";
      }      
      else if (moonPhaseVisibilityInt >= 5) {
        //console.log("5_1");
        this.moonPhaseImage = "assets/images/moonphases/5_1.png";
      }      
      else if (moonPhaseVisibilityInt >= 0) {
        //console.log("0_1");
        this.moonPhaseImage = "assets/images/moonphases/0_1.png";
      } 

      // else if (moonPhaseVisibilityInt == 1) {
      //   this.moonPhaseImage = "assets/images/moonphases/waxing-crescent-moon.png";
      // }
      // else if (moonPhaseVisibilityInt == 2) {
      //   this.moonPhaseImage = "assets/images/moonphases/first-quarter-moon.png";
      // }
      // else if (moonPhaseVisibilityInt == 3) {
      //   this.moonPhaseImage = "assets/images/moonphases/waxing-gibbous-moon.png";
      // }
      // else if (moonPhaseVisibilityInt == 4) {
      //   this.moonPhaseImage = "assets/images/moonphases/full-moon.png";
      // }
      // else if (moonPhaseVisibilityInt == 5) {
      //   this.moonPhaseImage = "assets/images/moonphases/wanning-gibbous-moon.png";
      // }
      // else if (moonPhaseVisibilityInt == 6) {
      //   this.moonPhaseImage = "assets/images/moonphases/last-quarter-moon.png";
      // }
      // else if (moonPhaseVisibilityInt == 7) {
      //   this.moonPhaseImage = "assets/images/moonphases/waning-crescent-moon";
      // }


      // if (moonPhaseVisibilityInt == 0) {
      //   this.moonPhaseImage = "assets/images/moonphases/new-moon.png";
      // }
      // else if (moonPhaseVisibilityInt == 1) {
      //   this.moonPhaseImage = "assets/images/moonphases/waxing-crescent-moon.png";
      // }
      // else if (moonPhaseVisibilityInt == 2) {
      //   this.moonPhaseImage = "assets/images/moonphases/first-quarter-moon.png";
      // }
      // else if (moonPhaseVisibilityInt == 3) {
      //   this.moonPhaseImage = "assets/images/moonphases/waxing-gibbous-moon.png";
      // }
      // else if (moonPhaseVisibilityInt == 4) {
      //   this.moonPhaseImage = "assets/images/moonphases/full-moon.png";
      // }
      // else if (moonPhaseVisibilityInt == 5) {
      //   this.moonPhaseImage = "assets/images/moonphases/wanning-gibbous-moon.png";
      // }
      // else if (moonPhaseVisibilityInt == 6) {
      //   this.moonPhaseImage = "assets/images/moonphases/last-quarter-moon.png";
      // }
      // else if (moonPhaseVisibilityInt == 7) {
      //   this.moonPhaseImage = "assets/images/moonphases/waning-crescent-moon";
      // }
      
      // Update square background color when value change.
      this.moonPhaseVisibilityUpdated = true;
      setTimeout(() => {
        this.moonPhaseVisibilityUpdated = false;
      }, this.flashBackgroundTime);
    });
  }
}
