<div id="top" style="cursor: pointer;" (click)="btnHomeClick();">
  iPrincip.nu
</div>


<!-- -------------------------------------------------------------- -->
<!-- outdoorTemp                                                    -->
<!-- Row 1                                                          -->
<!-- -------------------------------------------------------------- -->
<div id="squarerow">

  <div [className]="outdoorTempUpdated ? 'squarevaluechanged' : 'square'">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
          (click)="btnGraphTemperatureClick('Outdoor', '1D', 'Outdoor temperature during the past day');">
          <img class="imagers" src="assets/images/temperature/temperature.png">
          <br>
          <div class="texts18">
            <font color="yellow">
              <div align="center">{{ outdoorTemp }} &deg;C</div>
            </font>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="square">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
          (click)="btnGraphTemperatureClick('Outdoor', '1D', 'Outdoor temperature during the past day');">
          <img class="imagers" src="assets/images/temperature/1day.png">>
        </div>
      </div>
    </div>
  </div>



  <div class="square">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
          (click)="btnGraphTemperatureClick('Outdoor', '1W', 'Outdoor temperature in the last week');">
          <img class="imagers" src="assets/images/temperature/1week.png">
        </div>
      </div>
    </div>
  </div>


  <div class="square">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
          (click)="btnGraphTemperatureClick('Outdoor', '1M', 'Outdoor temperature during the past 30 days');">
          <img class="imagers" src="assets/images/temperature/30days.png">
        </div>
      </div>
    </div>
  </div>



  <div class="square">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
          (click)="btnGraphTemperatureClick('Outdoor', '6M', 'Outdoor temperature last six months');">
          <img class="imagers" src="assets/images/temperature/180days.png">
        </div>
      </div>
    </div>
  </div>


  <div class="square">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
          (click)="btnGraphTemperatureClick('Outdoor', '1Y', 'Outdoor temperature during the past year');">
          <img class="imagers" src="assets/images/temperature/1year.png">
        </div>
      </div>
    </div>
  </div>


  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="squarenotready">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;" onclick="window.location='showtemperature2yearscs.php';">
          <img class="imagers" src="assets/images/temperature/2year.png">
        </div>
      </div>
    </div>
  </div> -->



  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

</div>


<!-- -------------------------------------------------------------- -->
<!-- groundTemp                                                     -->
<!-- Row 2                                                          -->
<!-- -------------------------------------------------------------- -->
<div id="squarerow">

  <div [className]="groundTempUpdated ? 'squarevaluechanged' : 'square'">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
          (click)="btnGraphTemperatureClick('Ground', '1Y', 'Ground temperature (-0.5 m) during the past year');">
          <img class="imagers" src="assets/images/temperatureground/temperatureground.png">
          <br>
          <div class="texts18">
            <font color="yellow">
              <div align="center">{{ groundTemp }} &deg;C</div>
            </font>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

  <div class="square">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
          (click)="btnGraphTemperatureClick('Ground', '6M', 'Ground temperature (-0.5 m) in the last six months');">
          <img class="imagers" src="assets/images/temperatureground/180days.png">
        </div>
      </div>
    </div>
  </div>



  <div class="square">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
          (click)="btnGraphTemperatureClick('Ground', '1Y', 'Ground temperature (-0.5 m) during the past year');">
          <img class="imagers" src="assets/images/temperatureground/1year.png">
        </div>
      </div>
    </div>
  </div>



  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

</div>


<!-- -------------------------------------------------------------- -->
<!-- humidity                                                       -->
<!-- Row 3                                                          -->
<!-- -------------------------------------------------------------- -->
<div id="squarerow">

  <div [className]="outdoorHumidityUpdated ? 'squarevaluechanged' : 'square'">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
          (click)="btnGraphHumidityClick('Outdoor', '1D', 'Humidity in the past 24 hours');">
          <img class="imagers" src="assets/images/humidity/humidity.png">
          <br>
          <div class="texts18">
            <font color="lightblue">
              <div align="center">{{ outdoorHumidity }} %</div>
            </font>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="square">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
          (click)="btnGraphHumidityClick('Outdoor', '1D', 'Humidity in the past 24 hours');">
          <img class="imagers" src="assets/images/humidity/1day.png">
        </div>
      </div>
    </div>
  </div>


  <div class="square">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
          (click)="btnGraphHumidityClick('Outdoor', '1W', 'Humidity in the last week');">
          <img class="imagers" src="assets/images/humidity/1week.png">
        </div>
      </div>
    </div>
  </div>



  <div class="square">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
          (click)="btnGraphHumidityClick('Outdoor', '1M', 'Humidity in the last 30 days');">
          <img class="imagers" src="assets/images/humidity/30days.png">
        </div>
      </div>
    </div>
  </div>



  <div class="square">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
          (click)="btnGraphHumidityClick('Outdoor', '6M', 'Humidity during the last six months');">
          <img class="imagers" src="assets/images/humidity/180days.png">
        </div>
      </div>
    </div>
  </div>



  <div class="square">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
          (click)="btnGraphHumidityClick('Outdoor', '1Y', 'Outdoor humidity during the past year');">
          <img class="imagers" src="assets/images/humidity/1year.png">
        </div>
      </div>
    </div>
  </div>


  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

</div>


<!-- -------------------------------------------------------------- -->
<!-- Rain                                                           -->
<!-- Row 4                                                          -->
<!-- -------------------------------------------------------------- -->
<div id="squarerow">

  <div [className]="rainAmountLast24HrUpdated ? 'squarevaluechanged' : 'square'">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
          (click)="btnGraphRainClick('Outdoor', '96', 'Rain over the past 24 hours');">
          <img class="imagers" src="assets/images/rain/rain.png">
          <br>
          <div class="texts18">
            <font color="lightblue">
              <div align="center">{{ rainAmountLast24Hr }} mm</div>
            </font>
          </div>
          <div class="texts12">
            <font color="lightgray">
              <div align="center">(Last 24 hours)</div>
            </font>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="square">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
        (click)="btnGraphRainClick('Outdoor', '96', 'Rain over the past 24 hours');">
          <img class="imagers" src="assets/images/rain/1day.png">
        </div>
      </div>
    </div>
  </div>

  <div class="square">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
        (click)="btnGraphRainClick('Outdoor', '672', 'Rain the last week');">
          <img class="imagers" src="assets/images/rain/1week.png">
        </div>
      </div>
    </div>
  </div>


  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>


  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>


  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>


  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

</div>


<!-- -------------------------------------------------------------- -->
<!-- pressure                                                       -->
<!-- Row 5                                                          -->
<!-- -------------------------------------------------------------- -->
<div id="squarerow">

  <div [className]="pressureUpdated ? 'squarevaluechanged' : 'square'">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
          (click)="btnGraphPressureClick('Outdoor', '1D', 'Air pressure in the last 24 hours');">
          <img class="imagers" src="{{ pressureImage }}">

          <br>
          <div class="texts18">
            <font color="lightgreen">
              <div align="center">{{ pressure }} hPa</div>
            </font>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="square">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
          (click)="btnGraphPressureClick('Outdoor', '1D', 'Air pressure in the last 24 hours');">
          <img class="imagers" src="assets/images/pressure/1day.png">
        </div>
      </div>
    </div>
  </div>


  <div class="square">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
          (click)="btnGraphPressureClick('Outdoor', '1W', 'Air pressure in the last week');">
          <img class="imagers" src="assets/images/pressure/1week.png">
        </div>
      </div>
    </div>
  </div>


  <div class="square">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
          (click)="btnGraphPressureClick('Outdoor', '1M', 'Air pressure in the last 30 days');">
          <img class="imagers" src="assets/images/pressure/30days.png">
        </div>
      </div>
    </div>
  </div>


  <div class="square">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
          (click)="btnGraphPressureClick('Outdoor', '6M', 'Air pressure  during the last six months');">
          <img class="imagers" src="assets/images/pressure/180days.png">
        </div>
      </div>
    </div>
  </div>

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

</div>


<!-- -------------------------------------------------------------- -->
<!-- Sunlight.                                                      -->
<!-- Row 6                                                          -->
<!-- -------------------------------------------------------------- -->
<div id="squarerow">

  <div [className]="sunlightNowUpdated ? 'squarevaluechanged' : 'square'">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
          (click)="btnGraphSunlightClick('Outdoor', '1D', 'Sunlight over the past day');">
          <img class="imagers" src="{{ sunlightImage }}">
          <br>
          <div class="texts18">
            <font color="yellow">
              <div align="center">{{ sunlightNow }} %</div>
            </font>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="square">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
          (click)="btnGraphSunlightClick('Outdoor', '1D', 'Sunlight over the past day');">
          <img class="imagers" src="assets/images/sunlight/1day.png">
        </div>
      </div>
    </div>
  </div>


  <div class="square">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
          (click)="btnGraphSunlightClick('Outdoor', '1W', 'Sunlight over the past week');">
          <img class="imagers" src="assets/images/sunlight/1week.png">
        </div>
      </div>
    </div>
  </div>


  <div class="square">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
          (click)="btnGraphSunlightClick('Outdoor', '1M', 'Sunlight in the last 30 days');">
          <img class="imagers" src="assets/images/sunlight/30days.png">
        </div>
      </div>
    </div>
  </div>



  <div class="square">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;"
          (click)="btnGraphSunlightClick('Outdoor', '6M', 'Sunlight in the last six months');">
          <img class="imagers" src="assets/images/sunlight/180days.png">
        </div>
      </div>
    </div>
  </div>



  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

</div>


<!-- -------------------------------------------------------------- -->
<!-- UVRadiation                                                    -->
<!-- Row 7                                                          -->
<!-- -------------------------------------------------------------- -->
<div id="squarerow">

  <div [className]="uvradiationUpdated ? 'squarevaluechanged' : 'square'">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;" onclick="window.location='showuvradiationday.php';">
          <div>
            <br>
          </div>
          <img class="imagers" src="{{ uvIndexImage }}">
          <div>
            <br>
          </div>
          <div class="texts12">
            <font color="lightgray">
              <div align="center">UV-index</div>
            </font>
          </div>
          <div class="texts18">
            <font color="orange">
              <div align="center">{{ uvradiation }}</div>
            </font>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="squarenotready">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;" onclick="window.location='showuvradiationday.php';">
          <img class="imagers" src="assets/images/uv/1day.png">
        </div>
      </div>
    </div>
  </div>


  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

</div>


<!-- -------------------------------------------------------------- -->
<!-- averagetemperatures                                            -->
<!-- Row 8                                                          -->
<!-- -------------------------------------------------------------- -->
<div id="squarerow">

  <!-- echo '<div class="square">';
          echo '    <div class="content">';
          echo '        <div class="table">';
          echo '            <div class="table-cell texts12"; style="cursor: pointer;">';
          echo '            <a href="/php/weather/showaveragetemperatures.php" >';
          echo '                <img class="rs" src="/images/averagetemperatures/averagetemperatures.png"/>';
          echo '            </a>';
          echo '            </div>';
          echo '        </div>';
          echo '    </div>';
          echo '</div>'; -->

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>

  <div class="hiddensquare">
    <div class="content">
      <div class="table">
        <div class="table-cell">
        </div>
      </div>
    </div>
  </div>


  <div class="square">
    <div class="content">
      <div class="table">
        <div class="table-cell" style="cursor: pointer;" (click)="btnJarfallaClick();">
          <img class="imagers" src="assets/images/back.png">
        </div>
      </div>
    </div>
  </div>

</div>