import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-graph-uvradiation',
  templateUrl: './graph-uvradiation.component.html',
  styleUrls: ['./graph-uvradiation.component.css']
})
export class GraphUvradiationComponent implements OnInit {

  constructor(private router: Router) {
    this.myData = this.router.getCurrentNavigation().extras.state.graphData;
    this.myTitle = this.router.getCurrentNavigation().extras.state.formTitle;
    // this.minTemp = parseInt(this.router.getCurrentNavigation().extras.state.minTemp);
    // this.maxTemp = parseInt(this.router.getCurrentNavigation().extras.state.maxTemp);

    // console.log("minTemp: " + this.minTemp);
    // console.log("maxTemp: " + this.maxTemp);
  }

  ngOnInit(): void {
  }

  myTitle = "";
  // minTemp = parseInt(this.router.getCurrentNavigation().extras.state.minTemp);
  // maxTemp = parseInt(this.router.getCurrentNavigation().extras.state.maxTemp);
  width = "1200";
  height = "800";
  myType = 'LineChart';
  myData = [];

  options = {
    curveType: 'function',
    hAxis: {
      title: 'Date/Time',
      direction: 1,
      slantedText: true,
      slantedTextAngle: 90,
    },
    vAxis: {
      title: 'UV-Index',
      textStyle: {
        color: '#01579b',
        fontSize: 16,
        fontName: 'Arial',
        bold: false,
        italic: true
      },
      viewWindow:{
        max:11,
        min:0
      }
    },
    gridlines: {
      count: 10,
    },
    chartArea: {
      top: 55,
      height: '60%'
    }
  };
}
